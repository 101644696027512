import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { DeploymentLog, DeploymentStep, DeploymentStepLog } from 'types/api.types';
import type { EnvironmentApi } from '@env0/environment-service/api';
import type { AxiosResponse } from 'axios';

export default (client: HttpClient) => ({
  getStepLog: async (deploymentId: string, stepName: string, startTime?: number) =>
    client
      .get<DeploymentStepLog>(`deployments/${deploymentId}/steps/${stepName}/log`, { params: { startTime } })
      .then(returnData),
  cancel: (deploymentId: string) =>
    client.put<EnvironmentApi.Cancel.Response>(`environments/deployments/${deploymentId}/cancel`),
  batchCancel: (environmentId: string, status: EnvironmentApi.DeploymentLogStatus) =>
    client.put<EnvironmentApi.BatchCancel.Response>(
      `environments/${environmentId}/deployments/cancel?status=${status}`
    ),
  abort: (deploymentLogId: string) => client.post<void>(`environments/deployments/${deploymentLogId}/abort`),
  destroy: (
    environmentId: string,
    {
      checkoutUpdatedCode,
      skipStateRefresh,
      revision,
      comment,
      requiresApproval
    }: EnvironmentApi.Destroy.Request.DestroyParams
  ) =>
    client.post<{}, AxiosResponse<DeploymentLog>>(
      `environments/${environmentId}/destroy`,
      { comment },
      { params: { checkoutUpdatedCode, skipStateRefresh, revision, requiresApproval } }
    ),
  getResources: async (deploymentId: string) =>
    client
      .get<EnvironmentApi.EnvironmentResource[]>(`environments/deployments/${deploymentId}/resources`)
      .then(returnData),

  resume: (deploymentId: string) =>
    client.put<DeploymentLog>(`environments/deployments/${deploymentId}`).then(returnData),

  getDeploymentLogById: (deploymentLogId: string) =>
    client.get<DeploymentLog>(`environments/deployments/${deploymentLogId}`).then(returnData),

  getSubDeploymentLog: (workflowDeploymentId: string, subEnvironmentId: string) =>
    client
      .get<DeploymentLog>(
        `workflow-deployments/${workflowDeploymentId}/sub-environments/${subEnvironmentId}/deployment`,
        {
          hideNotification: true
        }
      )
      .then(returnData),

  getDeploymentStepsByDeploymentId: (deploymentId: string) =>
    client.get<DeploymentStep[]>(`deployments/${deploymentId}/steps`).then(returnData),

  rerunDeploymentPRPlan: (deploymentId: string) =>
    client.post(`environments/deployments/${deploymentId}/rerun`).then(returnData)
});
