import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import useStores from 'hooks/use-stores.hooks';
import { ErrorMessage, Label } from 'components/common/form-components';
import styled from 'types/theme.types';
import type { SshKey, UpdateSshKeyInput } from 'types/api.types';
import useForm from 'hooks/use-form.hook';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';
import ControlledModal from 'components/common/form-controlled/controlled-modal';
import type { CreateSshKeyInput } from 'types/api.types';
import type { SchemaOf } from 'yup';
import type { UseFormMethods } from 'react-hook-form/dist/types';
import { onKeySetFailure } from 'components/organizations/keys/common/key-validations';

const messagePrefix = 'organization.settings.keys';
export const SshValueTextAreaPlaceholder: string = '-----BEGIN RSA PRIVATE KEY-----';

type SshKeyInput = CreateSshKeyInput & UpdateSshKeyInput;

type SshKeyModalProps = {
  open: boolean;
  onClose: (createdKey?: any) => void;
  onSubmit: (data: SshKeyInput) => Promise<SshKey>;
  schema: SchemaOf<SshKeyInput>;
  keyName?: string;
  isLoading?: boolean;
};

const SshKeyModal: React.FC<SshKeyModalProps> = ({ open, onClose, onSubmit, schema, keyName, isLoading }) => {
  const { organizationsStore } = useStores();
  const modalType = keyName ? 'update' : 'add';

  const form = useForm({
    schema,
    onSubmit: (data: SshKeyInput, form: UseFormMethods<SshKeyInput>) => {
      onSubmit(data)
        .then((createdKey: SshKey) => closeAndClearForm(createdKey))
        .catch(onKeySetFailure(form, 'SSH'));
    },
    initialValues: {
      name: keyName ?? '',
      value: ''
    }
  });

  const closeAndClearForm = (createdKey?: SshKey) => {
    onClose(createdKey);
    form.clear();
  };

  const { isCurrentOrganizationSelfHosted } = organizationsStore;
  const isSecret = isCurrentOrganizationSelfHosted;

  const keyInput = useMemo(() => {
    return isSecret ? (
      <ControlledTextInput
        data-e2e={`${modalType}-ssh-key-value-input`}
        placeholder="secretPlaceholder"
        form={form}
        name="value"
      />
    ) : (
      <ControlledTextArea
        form={form}
        name="value"
        data-e2e={`${modalType}-ssh-key-value-textarea`}
        placeholder={SshValueTextAreaPlaceholder}
        autoSize={{ minRows: 12, maxRows: 12 }}
        errorSide="bottom"
      />
    );
  }, [isSecret, form, modalType]);

  return (
    <ControlledModal
      data-e2e={`${modalType}-ssh-key-modal`}
      saveButtonDataE2E={`${modalType}-ssh-key-button`}
      open={open}
      form={form}
      onCancel={onClose}
      titleId={`organization.settings.keys.${modalType}`}
      titleValues={{ keyType: 'SSH' }}
      saveMessageId="save.changes"
      isLoading={isLoading}>
      <>
        <Label id={`${messagePrefix}.name`} values={{ keyType: 'SSH' }} />
        <ControlledTextInput
          form={form}
          disabled={!!keyName}
          id={`${modalType}-ssh-key-name`}
          data-e2e={`${modalType}-ssh-key-name`}
          name="name"
          errorSide="bottom"
        />
        <Label id={`${messagePrefix}.${isSecret ? 'secret-value' : 'value'}`} values={{ keyType: 'SSH' }} />
        {keyInput}
      </>
    </ControlledModal>
  );
};

styled(ErrorMessage)`
  display: flex;
`;

export default observer(SshKeyModal);
