import React from 'react';
import type { DeploymentLog, User } from 'types/api.types';
import { getDeployerUserImageUrl, getDeployerUserName, getUserImageUrl, getUserName } from 'utils/user.utils';
import { ENV0_AVATAR } from 'constants/api.constants';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Space, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import styled from 'types/theme.types';
import VcsPrLink from 'components/common/vcs-pr-link';
import { observer } from 'mobx-react';
import { useGetBlueprint } from 'stores/rq/blueprints';

interface UserAvatarProps {
  user?: User;
}

interface DeploymentLogProps {
  deploymentLog: DeploymentLog;
}

interface UserAvatarGroupProps {
  users: User[];
  maxCount?: number;
}

const MAX_AVATARS_COUNT = 5;

const AvatarIcon: React.FC<UserAvatarProps> = ({ user }) => {
  const src = getUserImageUrl({ user });
  return <Avatar icon={<UserOutlined />} src={src} />;
};

export const UserAvatar: React.FC<UserAvatarProps> = ({ user }) => {
  const userName = getUserName(user);
  return (
    <Tooltip title={userName} placement="top">
      <div className="avatar-wrapper">
        <AvatarIcon user={user} />
      </div>
    </Tooltip>
  );
};

export const UserAvatarWithName: React.FC<UserAvatarProps> = ({ user, ...props }) => {
  return (
    <span {...props}>
      <AvatarIcon user={user} /> {getUserName(user)}
    </span>
  );
};

export const DeploymentUserAvatarWithInfo: React.FC<{
  userName: string;
  blueprintId?: string;
  prNumber?: string;
  avatarSrc: string;
  shape?: 'circle' | 'square';
  dataE2e?: string;
}> = observer(function DeploymentUserAvatarWithInfo({ userName, blueprintId, prNumber, avatarSrc, shape, dataE2e }) {
  const { data: blueprint } = useGetBlueprint(blueprintId);
  return (
    <Tooltip title={userName} placement="top">
      <Space data-e2e={dataE2e}>
        <Avatar shape={shape} icon={<UserOutlined />} src={avatarSrc} />
        {prNumber && blueprint ? (
          <VcsPrLink blueprint={blueprint} prNumber={prNumber} deployerName={userName} />
        ) : (
          <>{userName}</>
        )}
      </Space>
    </Tooltip>
  );
});

export const DeployerUserAvatarWithInfo: React.FC<DeploymentLogProps> = ({ deploymentLog }) => {
  const deployerUserImageUrl = getDeployerUserImageUrl(deploymentLog);

  return (
    <DeploymentUserAvatarWithInfo
      userName={getDeployerUserName(deploymentLog)}
      blueprintId={deploymentLog.blueprintId}
      prNumber={deploymentLog.prNumber}
      avatarSrc={deployerUserImageUrl}
      shape={deployerUserImageUrl === ENV0_AVATAR ? 'square' : 'circle'}
    />
  );
};

export const ReviewerUserAvatarWithInfo: React.FC<DeploymentLogProps> = ({ deploymentLog }) => (
  <DeploymentUserAvatarWithInfo
    userName={getUserName(deploymentLog.reviewedByUser)}
    blueprintId={deploymentLog.blueprintId}
    avatarSrc={getUserImageUrl({ user: deploymentLog.reviewedByUser })}
    dataE2e={`reviewed-by-${deploymentLog.reviewedBy}`}
  />
);

export const UserAvatarGroup: React.FC<UserAvatarGroupProps> = ({ users, maxCount = MAX_AVATARS_COUNT }) => {
  return isEmpty(users) ? (
    <NoMembersText>
      <FormattedMessage id="none" />
    </NoMembersText>
  ) : (
    <AvatarGroup maxCount={maxCount}>
      {users.map(user => (
        <UserAvatar key={user.user_id} user={user} />
      ))}
    </AvatarGroup>
  );
};

const NoMembersText = styled.div`
  color: ${({ theme }) => theme.primaryDisabledGray};
`;

const AvatarGroup = styled(Avatar.Group)`
  .avatar-wrapper:not(:first-of-type) {
    margin-left: -8px;
  }
`;
