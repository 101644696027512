import React from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { Section } from 'components/common/section';
import { FormattedMessage } from 'react-intl';
import ControlledScheduledActionField from 'components/environments/scheduling/controlled-scheduled-action-field';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';

const DriftDetectionSettings: React.FC<{ form: UseFormReturnType }> = ({ form }) => {
  return (
    <Section titleId="environment.drift.detection.title">
      <FormattedMessage id="environment.drift.detection.hint" />
      <ControlledScheduledActionField
        form={form}
        data-e2e="policy-field-drift-detection"
        type="DRIFT_DETECTION"
        isAuthorized={true}
        cronPropertyName="driftDetection"
        infoTooltip={
          <FormattedMessage
            id="environment.drift.detection.description"
            values={setLink(links.docs.DRIFT_DETECTION.ROOT)}
          />
        }
      />
    </Section>
  );
};

export default DriftDetectionSettings;
