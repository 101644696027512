import type { TableProps } from 'components/common/table';
import React, { useMemo, useState } from 'react';
import OpenTofuSvg from 'assets/images/open-tofu.svg';
import TerraformSvg from 'assets/images/terraform-icon.svg';
import TerragrunSvg from 'assets/images/terragrunt-icon.svg';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import Button from 'components/common/button';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import EnvironmentImportTable from 'components/projects/settings/environment-discovery/environment-import/environment-import-table';
import { useCuratedProjects } from 'stores/rq/projects';
import type { Project } from 'types/api.types';
import { getRedirectUrlForProject } from 'utils/sub-project.utils';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import type { EnvironmentImportApi } from '@env0/environment-import-service/api';
import RefreshEntities from 'components/common/refresh-entities';
import { multipleMenuFilter, sorterByStringProp } from 'components/dashboard/common/table-widget-common';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import { useHasPermission } from 'hooks/use-has-permission';
import Tooltip from 'components/common/tooltip';
import { TooltipEllipsisText } from 'components/common/tooltip-ellipsis-text';

interface Props {
  discoveredEnvironments: EnvironmentImportApi.DiscoveredUnimportedEnvironment[];
  onImportClick: (selectedDiscoveredEnvironmentIds: string[]) => void;
  onRefreshClick: () => Promise<any>;
  isLoading: boolean;
}

const iacTypeToIcon: Record<Extract<BlueprintApi.DeployableType, 'terraform' | 'terragrunt' | 'opentofu'>, string> = {
  opentofu: OpenTofuSvg,
  terraform: TerraformSvg,
  terragrunt: TerragrunSvg
};

const getColumns = (
  rows: EnvironmentImportApi.DiscoveredUnimportedEnvironment[],
  activeProjects: Record<string, Project>
) =>
  [
    {
      dataIndex: 'type',
      title: 'projects.settings.environment.discovery.discovered-environments.column.type',
      ...multipleMenuFilter(rows, row => row.type),
      sorter: sorterByStringProp(row => row.type),
      render: (type: 'terraform' | 'terragrunt' | 'opentofu') => (
        <CellWrapper>
          <IacTypeImage src={iacTypeToIcon[type]} alt={`${type} logo`} />
        </CellWrapper>
      )
    },
    {
      dataIndex: 'path',
      title: 'projects.settings.environment.discovery.discovered-environments.column.path',
      ...multipleMenuFilter(rows, row => row.path),
      sorter: sorterByStringProp(row => row.path),
      render: path => <TooltipEllipsisText text={path} />,
      width: 250,
      ellipsis: true
    },
    {
      dataIndex: 'targetProjectId',
      title: 'projects.settings.environment.discovery.discovered-environments.column.targetProjectId',
      onFilter: (value, row) => row.targetProjectId === value.toString(),
      ...multipleMenuFilter(
        rows,
        row => row.type,
        row => activeProjects[row.targetProjectId]?.name
      ),
      sorter: sorterByStringProp(row => row.targetProjectId),
      render: projectId => {
        const project = activeProjects[projectId];
        return (
          <CellWrapper>{project && <Link to={getRedirectUrlForProject(project)}>{project.name}</Link>}</CellWrapper>
        );
      },
      ellipsis: true
    },
    {
      dataIndex: 'targetEnvironmentName',
      title: 'projects.settings.environment.discovery.discovered-environments.column.targetEnvironmentName',
      ...multipleMenuFilter(rows, row => row.targetEnvironmentName),
      sorter: sorterByStringProp(row => row.targetEnvironmentName),
      render: environmentName => <TooltipEllipsisText text={environmentName} />,
      ellipsis: true
    }
  ] as TableProps<EnvironmentImportApi.DiscoveredUnimportedEnvironment>['columns'];

const DiscoveredEnvironmentsTable: React.FunctionComponent<Props> = ({
  discoveredEnvironments,
  onImportClick,
  onRefreshClick,
  isLoading
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { activeProjects } = useCuratedProjects();
  const columns = useMemo(
    () => getColumns(discoveredEnvironments, activeProjects),
    [discoveredEnvironments, activeProjects]
  );

  const projectId = useCurrentProjectId();
  const { isAuthorized: hasImportEnvironmentsPermission } = useHasPermission('IMPORT_ENVIRONMENTS', { projectId });

  return (
    <EnvironmentImportTable
      titleId={'projects.settings.environment.discovery.discovered-environments.discovered-table.title'}
      actionButton={
        <ActionWrapper>
          <RefreshEntities
            toolTipMsgId="projects.settings.environment.discovery.discovered-environments.discovered-table.refresh.title"
            onRefresh={onRefreshClick}
            spin={isLoading}
            disabled={!hasImportEnvironmentsPermission}
          />
          <Tooltip
            titleId={
              !hasImportEnvironmentsPermission
                ? 'projects.settings.environment.discovery.discovered-environments.import.unauthorized-tooltip-text'
                : ''
            }
            placement="bottomLeft">
            <Button
              type="primary"
              onClick={() => onImportClick(selectedRowKeys as string[])}
              disabled={!hasImportEnvironmentsPermission || isEmpty(selectedRowKeys)}
              data-e2e="import-discovered-environments">
              <FormattedMessage id="projects.settings.environment.discovery.discovered-environments.import.button.text" />
            </Button>
          </Tooltip>
        </ActionWrapper>
      }
      columns={columns}
      dataSource={discoveredEnvironments}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
    />
  );
};

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CellWrapper = styled.div`
  height: 25px;
`;

const IacTypeImage = styled.img`
  width: 24px;
`;

export default DiscoveredEnvironmentsTable;
