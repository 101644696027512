import React, { useDeferredValue, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from 'antd';
import { CloseCircleOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { useClickAway } from 'ahooks';
import {
  OrganizationButton,
  Panel,
  PanelGrowSection,
  PanelOrganizationSection,
  PanelSecondarySection,
  PanelSubTitle,
  PanelTopSection,
  PanelType
} from 'components/layout/navbar/navbar-panel.utils';
import ProjectsTree from 'components/layout/navbar/navbar-projects-tree';
import ProjectMenu from 'components/layout/navbar/navbar-project-menu';
import OrganizationItem from 'components/layout/navbar/navbar-organization-item';
import CreateProjectModal from 'components/projects/create-project-modal';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import { useHasPermission } from 'hooks/use-has-permission';
import type { Organization } from 'types/api.types';
import styled from 'types/theme.types';

interface Props {
  organization: Organization;
  onPanelChange: (panel: PanelType) => void;
  currentPanel: PanelType;
}

const ProjectsPanel = ({ organization, onPanelChange, currentPanel }: Props) => {
  const intl = useIntl();
  const activeProjectId = useCurrentProjectId();
  const menuOpen = useDeferredValue(Boolean(activeProjectId));
  const topSectionRef = useRef<HTMLDivElement>(null);
  const growSectionRef = useRef<HTMLDivElement>(null);
  const canCreateProject = useHasPermission('CREATE_PROJECT').isAuthorized;
  const [search, setSearch] = useState('');
  const [searchMode, setSearchMode] = useState(false);
  const [createProjectModal, setCreateProjectModal] = useState<null | { parentProjectId?: string }>(null);

  useClickAway(() => setSearchMode(false), [topSectionRef, growSectionRef]);

  return (
    <Panel visible={currentPanel === PanelType.Projects}>
      <StyledPanelTopSection
        ref={topSectionRef}
        active={searchMode}
        onClick={() => setSearchMode(true)}
        data-e2e="navbar-projects-search">
        {!searchMode ? (
          <TopSectionTitle>
            <FormattedMessage id="projects" />
          </TopSectionTitle>
        ) : (
          <>
            <SearchInput
              autoFocus
              placeholder={intl.formatMessage({ id: 'search' })}
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            {search && (
              <CloseCircleOutlined
                data-e2e="navbar-projects-search-close"
                onClick={e => {
                  e.stopPropagation();
                  setSearchMode(false);
                  setSearch('');
                }}
              />
            )}
          </>
        )}
        <SearchOutlined />
      </StyledPanelTopSection>
      <StyledPanelGrowSection ref={growSectionRef}>
        <ProjectsTree
          activeProjectId={activeProjectId}
          search={searchMode ? search : null}
          canCreateProject={canCreateProject}
          onCreateProject={parentProjectId => setCreateProjectModal({ parentProjectId })}
        />
        <CreateProjectModal
          open={Boolean(createProjectModal)}
          hideModal={() => setCreateProjectModal(null)}
          {...createProjectModal}
        />
      </StyledPanelGrowSection>
      <PanelSecondarySection data-e2e="navbar-project-menu-wrapper">
        {/* Wrapper to be removed when the tour is removed */}
        <StyledPanelSecondarySection open={menuOpen} data-e2e="navbar-project-menu">
          <PanelSubTitle>
            <FormattedMessage id="project" />
          </PanelSubTitle>
          <ProjectMenu projectId={activeProjectId} />
        </StyledPanelSecondarySection>
      </PanelSecondarySection>
      <PanelOrganizationSection
        onClick={() => onPanelChange(PanelType.Organization)}
        data-e2e="navbar-organization-button">
        <OrganizationItem
          noHover
          organization={organization}
          nameProps={{
            'data-e2e': 'navbar-organization-button-name',
            'data-org-id': organization.id
          }}
        />
        <OrganizationButton>
          <RightOutlined />
        </OrganizationButton>
      </PanelOrganizationSection>
    </Panel>
  );
};

const StyledPanelTopSection = styled(PanelTopSection)<{ active?: boolean }>`
  justify-content: space-between;
  color: color-mix(in srgb, ${({ theme }) => theme.textMetalBlue}, transparent ${props => (props.active ? 0 : 40)}%);
  transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;
  ${({ active, theme }) => active && `background-color: ${theme.menuItemBlue};`}

  &:hover {
    color: ${({ theme }) => theme.textMetalBlue};
  }
`;

const TopSectionTitle = styled.span`
  text-transform: uppercase;
  pointer-events: none;
`;

const SearchInput = styled(Input)`
  background: none;
  border: none;
  color: white;
  padding-left: 0;
  padding-right: 0;

  &:focus {
    border: none;
    box-shadow: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.textMetalBlue};
  }
`;

const StyledPanelGrowSection = styled(PanelGrowSection)`
  background-color: ${({ theme }) => theme.sideMenuBackgroundDark};
`;

const StyledPanelSecondarySection = styled(PanelSecondarySection)<{ open: boolean }>`
  --safe-max-height: calc(var(--subtitle-height) + 5 * var(--item-height));
  overflow-y: hidden;
  max-height: ${({ open }) => (open ? 'var(--safe-max-height)' : 0)};
  transition: max-height 0.15s ease-in-out;
`;

export default ProjectsPanel;
