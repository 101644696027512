import { ConfigurationScope, TokenTypes } from 'types/api.types';

export const createTokenFromNameAndValue = (name: string, value: string) => ({
  name,
  value,
  isSensitive: true,
  isReadonly: false,
  isRequired: true,
  type: TokenTypes.GIT,
  scope: ConfigurationScope.GLOBAL
});
