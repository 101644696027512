import React, { useState, useMemo, useEffect } from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { FormattedMessage } from 'react-intl';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { Section } from 'components/common/section';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { Info, Row as FormRow } from 'components/common/form-components';
import { messagePrefix } from 'components/projects/settings/policies/project-settings-policies';
import { useUpdateEffect } from 'ahooks';
import { WarningIcon as RawWarningIcon } from 'components/common/warning-icon';
import styled from 'types/theme.types';
import type { PoliciesFormSchema } from 'components/projects/settings/policies/use-policies-form.hook';

const DisableOutputsPolicyWarning = () => {
  return (
    <WarningContainer data-e2e="project-settings-policies-environment-outputs-warning">
      <WarningIcon />
      <WarningMessage>
        <FormattedMessage id={`${messagePrefix}.environment-outputs.warning`} />
      </WarningMessage>
    </WarningContainer>
  );
};

const DeploymentSettings: React.FC<{ form: UseFormReturnType; isCurrentOrganizationSelfHosted: boolean }> = ({
  form,
  isCurrentOrganizationSelfHosted
}) => {
  const [showOutputsDisabledWarning, setShowOutputsDisabledWarning] = useState(false);

  const { outputsAsInputsEnabled: isOutputsAsInputsEnabledDirty } = form.formState.dirtyFields as PoliciesFormSchema;
  const outputsAsInputsEnabledValue = form.watch('outputsAsInputsEnabled');

  useUpdateEffect(() => {
    setShowOutputsDisabledWarning(isOutputsAsInputsEnabledDirty && outputsAsInputsEnabledValue === false);
  }, [outputsAsInputsEnabledValue, isOutputsAsInputsEnabledDirty]);

  const costEstimationTooltipMessage = useMemo(() => {
    const saasOrShag = isCurrentOrganizationSelfHosted ? 'shag' : 'saas';
    const messageId = `${messagePrefix}.cost-estimation.tooltip.${saasOrShag}`;
    return <FormattedMessage id={messageId} values={setLink(links.docs.POLICIES.COST_ESTIMATION)} />;
  }, [isCurrentOrganizationSelfHosted]);

  return (
    <Section titleId={`${messagePrefix}.title.deployment`}>
      <ControlledBigCheckbox
        form={form}
        name="includeCostEstimation"
        data-e2e="project-settings-policies-cost-estimation-checkbox">
        <FormattedMessage id={`${messagePrefix}.cost-estimation.checkbox`} />
        <Info
          alignTop={false}
          data-e2e="project-settings-policies-cost-estimation-tooltip"
          information={costEstimationTooltipMessage}
        />
      </ControlledBigCheckbox>
      <Row>
        <CheckboxContainer>
          <ControlledBigCheckbox
            form={form}
            name="outputsAsInputsEnabled"
            data-e2e="project-settings-policies-environment-outputs-checkbox">
            <FormattedMessage id={`${messagePrefix}.environment-outputs.checkbox`} />
            <Info
              alignTop={false}
              data-e2e="project-settings-policies-environment-outputs-tooltip"
              information={
                <FormattedMessage
                  id={`${messagePrefix}.environment-outputs.tooltip`}
                  values={setLink(links.docs.POLICIES.ENVIRONMENT_OUTPUTS)}
                />
              }
            />
          </ControlledBigCheckbox>
        </CheckboxContainer>
        {showOutputsDisabledWarning && <DisableOutputsPolicyWarning />}
      </Row>
      <ControlledBigCheckbox
        form={form}
        name="skipApplyWhenPlanIsEmpty"
        data-e2e="project-settings-policies-skip-apply-when-plan-is-empty-checkbox">
        <FormattedMessage id={`${messagePrefix}.skip-apply-when-plan-is-empty.checkbox`} />
        <Info
          alignTop={false}
          data-e2e="project-settings-policies-skip-apply-when-plan-is-empty-tooltip"
          information={<FormattedMessage id={`${messagePrefix}.skip-apply-when-plan-is-empty.tooltip`} />}
        />
      </ControlledBigCheckbox>
      <ControlledBigCheckbox
        form={form}
        name="skipRedundantDeployments"
        data-e2e="project-settings-policies-skip-redundant-deployments-checkbox">
        <FormattedMessage id={`${messagePrefix}.skip-redundant-deployments.checkbox`} />
        <Info
          alignTop={false}
          information={
            <FormattedMessage
              id={`${messagePrefix}.skip-redundant-deployments.tooltip`}
              values={setLink(links.docs.POLICIES.SKIP_REDUNDANT_DEPLOYMENTS)}
            />
          }
        />
      </ControlledBigCheckbox>
    </Section>
  );
};

const CheckboxContainer = styled.div`
  width: auto;
`;

const WarningContainer = styled.div`
  margin-left: 1em;
  display: flex;
`;

const WarningIcon = styled(RawWarningIcon)`
  color: ${({ theme }) => theme.warningYellowDarker};
`;

const WarningMessage = styled.div`
  margin-left: 10px;
`;

const Row = styled(FormRow)`
  justify-content: flex-start;
  align-items: center;
`;

export default DeploymentSettings;
