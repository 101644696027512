import type { HttpClient } from 'services/api-client/client';
import type { CloudResourceApi } from '@env0/cloud-resource-service/api';

export default (client: HttpClient) => ({
  findCloudResources: async (
    body: CloudResourceApi.FindCloudResources.Request.Body
  ): Promise<CloudResourceApi.FindCloudResources.Response> =>
    client.post<CloudResourceApi.FindCloudResources.Response>('/cloud/resources', body).then(res => res.data),
  updateCloudResources: async (
    body: CloudResourceApi.UpdateCloudResources.Request.Body
  ): Promise<CloudResourceApi.UpdateCloudResources.Response> =>
    client.put<CloudResourceApi.UpdateCloudResources.Response>('/cloud/resources', body).then(res => res.data),

  codifyResources: async (body: CloudResourceApi.Codify.Request.Body) =>
    client.post<CloudResourceApi.Codify.Response>('/cloud/resources/codify', body).then(res => res.data)
});
