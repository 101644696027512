import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ProjectsPanel from 'components/layout/navbar/navbar-projects-panel';
import OrganizationPanel from 'components/layout/navbar/navbar-organization-panel';
import { PanelType } from 'components/layout/navbar/navbar-panel.utils';
import type { Organization } from 'types/api.types';
import styled from 'types/theme.types';

type Props = {
  organization: Organization;
};

const NavbarPanels = ({ organization }: Props) => {
  const { projectId } = useParams();
  const [panel, setPanel] = useState<PanelType>(!!projectId ? PanelType.Projects : PanelType.Organization);
  const navigate = useNavigate();
  const lastLocation = useRef<string>();

  useEffect(() => {
    if (panel === PanelType.Organization) {
      lastLocation.current = window.location.pathname;
    }
  }, [panel]);

  useEffect(() => {
    projectId && setPanel(PanelType.Projects);
  }, [projectId]);

  const handlePanelChange = (panel: PanelType) => {
    setPanel(panel);
    if (panel === PanelType.Projects && lastLocation.current) {
      navigate(lastLocation.current);
    }
  };

  return (
    <PanelsContainer panel={panel}>
      <ProjectsPanel organization={organization} onPanelChange={handlePanelChange} currentPanel={panel} />
      <OrganizationPanel organization={organization} onPanelChange={handlePanelChange} currentPanel={panel} />
    </PanelsContainer>
  );
};

export default NavbarPanels;

const PanelsContainer = styled.div<{ panel: PanelType }>`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  transition: transform 0.15s ease-in-out;
  transform: translateX(calc(100% * ${({ panel }) => -panel}));
`;
