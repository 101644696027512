import React from 'react';
import { links } from 'constants/external-links';
import Link from 'components/common/link';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import useStores from 'hooks/use-stores.hooks';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';

const prefix = 'settings.credentials.aws';

const AwsAccessKeysInputs = ({ form, usageType }: CredentialsInputProps) => {
  const { organizationsStore } = useStores();
  const { isCurrentOrganizationSelfHosted } = organizationsStore;

  if (usageType !== 'DEPLOYMENT') throw new Error('Aws Access Keys is for deployment only');

  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.AWS_ACCESS_KEY_DOCS;
  return (
    <>
      <Link messageId="settings.credentials.aws.accessKeys.explanation" url={docsLink} />
      <ControlledTextInput label={{ id: `${prefix}.accessKeyId` }} name="value.accessKeyId" form={form} />
      <ControlledTextInput
        label={{ id: `${prefix}.secretAccessKey` }}
        name="value.secretAccessKey"
        form={form}
        placeholder={isCurrentOrganizationSelfHosted ? 'secretPlaceholder' : ''}
      />
    </>
  );
};

export default AwsAccessKeysInputs;
