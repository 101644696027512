import useForm from 'hooks/use-form.hook';
import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import { useCallback } from 'react';
import defaults from 'lodash/defaults';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { useUpsertEnvironmentDiscovery } from 'stores/rq/environment-discovery';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
export const DEFAULT_WORKSPACE_NAMING = 'default';

type EnvironmentDiscoveryAutoCreateSettings = Pick<
  BlueprintApi.EnvironmentDiscoveryFields,
  'createNewEnvironmentsFromPullRequests' | 'workspaceNaming'
>;

const environmentDiscoverySettingsSchema: SchemaOf<EnvironmentDiscoveryAutoCreateSettings> = Yup.object({
  workspaceNaming: Yup.mixed<BlueprintApi.WorkspaceNaming>()
    .required()
    .oneOf(['default', 'environmentName'])
    .default(DEFAULT_WORKSPACE_NAMING),
  createNewEnvironmentsFromPullRequests: Yup.boolean().required().default(true)
});

const useEnvironmentDiscoveryAutoCreateSettingsForm = ({
  workspaceNaming,
  createNewEnvironmentsFromPullRequests
}: EnvironmentDiscoveryAutoCreateSettings) => {
  const currentProjectId = useCurrentProjectId<string>();
  const { mutate: upsertEnvironmentDiscovery } = useUpsertEnvironmentDiscovery(currentProjectId);

  const getInitialValues = useCallback<() => EnvironmentDiscoveryAutoCreateSettings>(() => {
    const schemaDefaults = environmentDiscoverySettingsSchema.getDefault() as EnvironmentDiscoveryAutoCreateSettings;
    return defaults<EnvironmentDiscoveryAutoCreateSettings, EnvironmentDiscoveryAutoCreateSettings>(
      { createNewEnvironmentsFromPullRequests, workspaceNaming },
      { ...schemaDefaults }
    );
  }, [workspaceNaming, createNewEnvironmentsFromPullRequests]);

  return useForm<EnvironmentDiscoveryAutoCreateSettings>({
    initialValues: getInitialValues(),
    schema: environmentDiscoverySettingsSchema,
    shouldUnregister: false,
    onSubmit: data => {
      upsertEnvironmentDiscovery({
        createNewEnvironmentsFromPullRequests: data.createNewEnvironmentsFromPullRequests,
        ...(data.createNewEnvironmentsFromPullRequests
          ? {
              workspaceNaming: data.workspaceNaming
            }
          : {})
      });
    }
  });
};

export default useEnvironmentDiscoveryAutoCreateSettingsForm;
