import React, { type ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Loader } from 'components/common/loader';
import LoginLayout, { LoaderOverlay } from 'components/login/login-layout';
import LoginLeftContent from 'components/login/login-left-content';
import { useLastLoginMethod, useLoginLock } from 'hooks/use-login-lock';
import LockContainer from 'components/login/lock-container';
import { useHasOrganizations } from 'hooks/use-has-organization.hook';
import { ONBOARDING_ROUTE_PREFIX } from 'components/routes/routes';
import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import Divider from 'components/common/divider';
import styled from 'types/theme.types';
import { LockFilled } from '@ant-design/icons';

export const LOGIN_PAGE_TITLE_ID = 'navigation.login';

const LoginPage: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const { isAuthenticated, redirectTo, isCallbackPage } = useLoginLock(false);
  const { usedSSO } = useLastLoginMethod();
  const { hasOrganizations, isLoadingOrganizations } = useHasOrganizations();

  if (isCallbackPage()) {
    return <Loader />;
  }

  if (isAuthenticated) {
    if (isLoadingOrganizations) {
      return <Loader />;
    }

    return <Navigate replace to={hasOrganizations ? redirectTo : `${ONBOARDING_ROUTE_PREFIX}/welcome`} />;
  }

  if (usedSSO) {
    // There are two ways to land on this page: via normal login or via redirect from Auth0Lock.
    // If it's via redirect, the /login route will have a hash with a token, so we need to redirect
    // with the hash so that Auth0Lock will be able to handle the authentication regardless of the
    // redirect.
    return <Navigate replace to={`/login/sso${window.location.hash}`} />;
  }

  return (
    <LoginLayout
      pageTitleId={LOGIN_PAGE_TITLE_ID}
      leftContent={children || <LoginLeftContent />}
      rightContent={
        <>
          <LockContainer />
          <>
            <Divider titleId="or" orientation="center" />
            <SSOButtonContainer>
              <SSOButtonInner>
                <SSOButton
                  data-e2e="sso-login-button"
                  type="primary"
                  icon={<LockFilled />}
                  onClick={() => navigate('/login/sso')}>
                  <FormattedMessage id="login.sso.login" />
                </SSOButton>
              </SSOButtonInner>
            </SSOButtonContainer>
          </>
        </>
      }
    />
  );
};

const SSOButtonContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 95vw !important;
  }

  @media (min-width: 768px) {
    width: 45vw !important;
  }
`;

const SSOButtonInner = styled.div`
  padding: 0 20px;

  @media (min-width: 768px) {
    width: 65% !important;
    max-width: 350px;
    margin: 0 auto;
  }
`;

const SSOButton = styled(Button)`
  display: block;
  width: 100%;
  height: 40px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.secondaryBlack} !important;
  transition: opacity 0.5s ease-out;

  .ant-btn-icon {
    position: absolute;
    left: 10px;
  }

  :hover {
    opacity: 0.5;
  }
`;

export default observer(LoginPage);
