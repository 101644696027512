import React, {
  type HTMLAttributes,
  type ReactElement,
  type ReactNode,
  useDeferredValue,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import styled from 'types/theme.types';

export enum PanelType {
  Projects = 0,
  Organization = 1
}

export const Panel = styled.div<{ visible: boolean }>`
  --item-height: 56px;
  --subtitle-height: 30px;
  width: var(--sidebar-width);
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  transition: opacity 0.15s ease-in-out;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const PanelTopSection = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 41px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.sideMenuBackgroundDark};
  border-bottom: ${({ theme }) => theme.sidebarSecondaryBorder} 1px solid;
  cursor: pointer;
`;

export const PanelGrowSection = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-color: ${({ theme }) => theme.sideMenuText} rgba(0, 0, 0, 0);

  ::-webkit-scrollbar {
    width: 9px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.sideMenuText};
    border-radius: 10px;
  }
`;

export const PanelSecondarySection = styled.div`
  position: relative;
`;

export const PanelOrganizationSection = styled.div`
  flex-shrink: 0;
  display: flex;
  z-index: 1;
  cursor: pointer;
  border-top: ${({ theme }) => theme.sidebarBorder} 1px solid;
  background-color: ${({ theme }) => theme.sideMenuBackground};
`;

export const OrganizationButton = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--item-height);
  height: var(--item-height);
  color: white;
`;

export const PanelSubTitle = styled.div`
  height: var(--subtitle-height);
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: ${({ theme }) => theme.textMetalBlue};
  font-size: 0.8rem;
`;

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  active?: boolean;
  noHover?: boolean;
};

export const Item = styled('div', {
  shouldForwardProp: propName => !['active', 'noHover'].includes(propName)
})<ItemProps>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding: 0 16px;
  gap: 12px;
  min-width: 0;
  height: var(--item-height);
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-size: 14px;
  transition: background 120ms ease-in-out;

  ${({ active, theme }) =>
    active &&
    `
      background: ${theme.menuItemBlue};
      box-shadow: 4px 0 0 ${theme.menuItemGreen} inset;
    `}

  ${({ noHover, theme }) =>
    !noHover &&
    `
      &:hover {
        background: ${theme.menuItemBlueActive};
      }
    `}
`;

export type HoverItemProps = Omit<ItemProps, 'children'> & {
  children: (hovered: boolean) => ReactNode;
};

export const HoverItem = ({ children, ...props }: HoverItemProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Item {...props} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {children(hovered)}
    </Item>
  );
};

type ParentHeightProps = {
  bypass?: boolean;
  fallbackHeight?: number;
  children: (availableHeight?: number) => ReactElement;
};

export const ParentHeight = ({ bypass, fallbackHeight, children }: ParentHeightProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [availableHeight, setAvailableHeight] = useState(fallbackHeight);
  const deferredHeight = useDeferredValue(availableHeight);

  useLayoutEffect(() => {
    const parent = ref.current?.parentElement;
    if (parent && !bypass) {
      const handleResize = () => setAvailableHeight(parent.clientHeight || fallbackHeight);
      handleResize();
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(parent);
      return () => resizeObserver.disconnect();
    }
  }, [bypass, fallbackHeight]);

  return bypass ? children() : <ParentHeightWrapper ref={ref}>{children(deferredHeight)}</ParentHeightWrapper>;
};

const ParentHeightWrapper = styled.div`
  max-height: 100%;
  overflow: hidden;
`;
