import React from 'react';

import type { Token } from 'types/api.types';

import { useTokenForm } from './token-form.hook';
import ControlledModal from 'components/common/form-controlled/controlled-modal';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';

export interface AddNewTokenModalProps {
  open: boolean;
  hideModal: () => void;
  onTokenCreated?: (tokenId: string, tokenName: string) => void;
}

const AddNewTokenModal: React.FunctionComponent<AddNewTokenModalProps> = ({ open, hideModal, onTokenCreated }) => {
  const onTokenCreateResult = (result: Token) => {
    if (onTokenCreated && result) {
      onTokenCreated(result.id, result.name);
    }

    hideModal();
  };

  const form = useTokenForm(onTokenCreateResult);

  return (
    <ControlledModal
      open={open}
      form={form}
      onCancel={hideModal}
      titleId="settings.tokens.add.new.title"
      saveMessageId="settings.tokens.add.new.button.text">
      <ControlledTextInput
        errorSide="bottom"
        form={form}
        label={{ id: 'settings.tokens.add.new.modal.token.name' }}
        name="name"
        placeholder="name"
        data-e2e="new-token-name"
      />
      <ControlledTextInput
        errorSide="bottom"
        form={form}
        label={{ id: 'value' }}
        name="value"
        placeholder="value"
        data-e2e="new-token-value"
      />
    </ControlledModal>
  );
};

export default AddNewTokenModal;
