import React, { forwardRef } from 'react';
import { FieldAndLabel, FieldRow, Info, Label, LabelAndIcon } from 'components/common/form-components';
import { FormattedMessage } from 'react-intl';

export type LabelProps = {
  width?: number;
  label?: { id: string; values?: Record<string, any>; optional?: boolean };
  mandatory?: boolean;
  optional?: boolean;
  info?: { id: string; values?: Record<string, any> };
};

type RowProps = { inline?: boolean };

export type WithLabelProps<T> = T & LabelProps & RowProps;

const wrapInRow = (element: JSX.Element, { inline }: RowProps) => (inline ? element : <FieldRow>{element}</FieldRow>);
const wrapWithLabel = (element: JSX.Element, { width = 100, label, mandatory, info, optional }: LabelProps) => {
  if (!label) return element;

  const baseLabel = <Label {...label} mandatory={mandatory} optional={optional} />;
  const labelComponent = info ? (
    <LabelAndIcon>
      {baseLabel}
      <Info information={<FormattedMessage {...info} />} />
    </LabelAndIcon>
  ) : (
    baseLabel
  );

  return (
    <FieldAndLabel width={width}>
      {labelComponent}
      {element}
    </FieldAndLabel>
  );
};

const withLabel = function <T>(Component: React.FC<T>) {
  const component = forwardRef<any, WithLabelProps<any>>(
    ({ width, label, mandatory, optional, info, inline, ...props }, ref) => {
      const component = <Component ref={ref} {...props} />;

      const componentWithLabel = wrapWithLabel(component, { width, label, mandatory, optional, info });
      return wrapInRow(componentWithLabel, { inline });
    }
  );
  component.displayName = `withLabel(${Component.displayName || Component.name})`;
  return component;
};

export default withLabel;
