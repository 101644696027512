import type { KeyToObjectLiteral } from './common.types';
import type { ConfigurationApi } from '@env0/configuration-service/api';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type {
  ConfigurationScope,
  ConfigurationType,
  CredentialType,
  TokenTypes
} from '@env0/configuration-service/api.enum';
import type { NotificationsApi } from '@env0/notification-service/api';
import type { EnvironmentApi } from '@env0/environment-service/api';
import type { User as Auth0User } from '@env0/common-auth0/user';
import type { OrganizationApi } from '@env0/organization-service/api';
import type { RolesApi } from '@env0/role-service/api';
import type { AuditEventApi } from '@env0/audit-service/api';
import type { CredentialsApi } from '@env0/credentials-service/api';
import type { DeploymentStepApi } from '@env0/deployment-step-service/api';
import type { AccountApi } from '@env0/account-service/api';
import type { ProviderRegistryApi } from '@env0/provider-registry-service/api';

export * from '@env0/configuration-service/api.enum';
export * from '@env0/environment-service/api.enum';

export type TtlValue = Date | number | null;
export type User = Auth0User;

export interface Blueprint extends BlueprintApi.Blueprint {
  readonly id: string;
  readonly author: User;
}

export type AssignCustomFlowBody = BlueprintApi.AssignCustomFlow.Request.Body;
export type UnassignCustomFlowBody = BlueprintApi.UnassignCustomFlow.Request.Body;

export type ConfigurationTemplateType = BlueprintApi.ApprovalPolicyType | BlueprintApi.CustomFlowType;
export type ConfigurationTemplate = BlueprintApi.ApprovalPolicyTemplate | BlueprintApi.CustomFlowTemplate;
export type ConfigurationTemplateAssignmentBody =
  | BlueprintApi.AssignCustomFlow.Request.Body
  | BlueprintApi.AssignApprovalPolicy.Request.Body;
export type ConfigurationTemplateWithoutName = Omit<ConfigurationTemplate, 'name'>;

export type ApprovalPolicyAssignment = BlueprintApi.ApprovalPolicyTemplateWithScope;

export type BlueprintSshKey = Pick<SshKey, 'id' | 'name'>;
export type CreateBlueprint = Omit<Blueprint, 'id' | 'createdAt' | 'authorId' | 'author'>;
export type UpdateBlueprint = Omit<CreateBlueprint, 'organizationId'>;
export type EnvironmentBlueprintType = 'single-use-vcs' | 'template';

export interface BlueprintRevisions {
  heads: string[];
  tags: string[];
}

export interface Blueprints {
  [key: string]: Blueprint;
}

export type Repository = {
  name: string;
  httpsUrl: string;
  github?: {
    installationId: number;
  };
};

export interface DeploymentLog
  extends Omit<
    EnvironmentApi.DeploymentLog,
    'costEstimation' | 'error' | 'output' | 'resourceCount' | 'reviewedByUser' | 'reviewersUsers'
  > {
  id: string;
  status: EnvironmentApi.DeploymentLogStatus;
  error: EnvironmentApi.DeploymentLog['error'] | null;
  costEstimation: EnvironmentApi.DeploymentLog['costEstimation'] | null;
  output: EnvironmentApi.DeploymentLog['output'] | null;
  resourceCount: EnvironmentApi.DeploymentLog['resourceCount'] | null;
  reviewersUsers: EnvironmentApi.DeploymentLog['reviewersUsers'] | null;
  reviewedByUser: EnvironmentApi.DeploymentLog['reviewedByUser'] | null;
}

export interface Environment
  extends Omit<EnvironmentApi.Environment, 'latestDeploymentLog' | 'lifespanEndAt' | 'nextScheduledDates'> {
  id: string;
  latestDeploymentLog: DeploymentLog;
  lifespanEndAt: Date | null;
  nextScheduledDates: EnvironmentNextScheduledDates | null;
}

export interface Environments {
  [environmentId: string]: Environment;
}

export type EnvironmentNextScheduledDates = EnvironmentApi.EnvironmentNextScheduledDates;

export interface CreateEnvironmentArgs
  extends Omit<EnvironmentApi.CreateEnvironmentRequest, 'ttl' | 'configurationChanges'> {
  ttl?: EnvironmentApi.TTLRequest;
  configurationChanges?: ConfigurationProperty[];
}

export interface DeployEnvironmentWithoutTemplateArgs
  extends Omit<EnvironmentApi.CreateEnvironmentWithoutTemplate.Request.Body, 'ttl' | 'configurationChanges'> {
  ttl?: EnvironmentApi.TTLRequest;
  configurationChanges?: ConfigurationProperty[];
}

export interface ConfigurationProperty {
  id?: string;
  name: string;
  value: string;
  isSensitive: boolean;
  scopeId?: string;
  scope: ConfigurationScope;
  type: ConfigurationType | TokenTypes;
  toDelete?: boolean;
  overwrites?: ConfigurationApi.FindVariablesByScope.OverridingConfigurationProperty['overwrites'];
  schema?: ConfigurationApi.ConfigurationPropertyPayload['schema'];
  description?: string;
  error?: null | ConfigurationPropertyError;
  isReadonly: boolean;
  isRequired: boolean;
  regex?: string;
}

export interface ConfigurationPropertyError {
  message: string;
  appearsUnder?: string;
}

export enum ConfigurationValueType {
  FREE_TEXT = 'FREE_TEXT',
  DROPDOWN_LIST = 'DROPDOWN_LIST',
  ENVIRONMENT_OUTPUT = 'ENVIRONMENT_OUTPUT',
  HCL_FORMAT = 'HCL_FORMAT',
  JSON_FORMAT = 'JSON_FORMAT'
}

export enum DeploymentStepStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_USER = 'WAITING_FOR_USER',
  FAIL = 'FAIL',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  TIMEOUT = 'TIMEOUT',
  SKIPPED = 'SKIPPED',
  WARNING = 'WARNING'
}

export interface DeployRequest {
  blueprintRevision: string;
  blueprintId: string;
}

export interface DeploymentStep
  extends Omit<DeploymentStepApi.DeploymentStep, 'logStreamName' | 'logSource' | 'logSourceMetadata' | 'agentKey'> {
  readonly id: string;
  status: DeploymentStepStatus;
  retryCount?: number;
}

export type DeploymentSteps = KeyToObjectLiteral<DeploymentStep[]>;

export interface LogEvent {
  timestamp: number;
  message: string;
  level: 'info' | 'error' | 'warning';
  eventId: string;
}

export interface DeploymentStepLog {
  events: LogEvent[];
  hasMoreLogs: boolean;
  nextStartTime: number;
}

export type RunTaskPayload = {
  taskPayload: string;
  comment?: string;
};

export type Provider = ProviderRegistryApi.Provider & { id: string; createdByUser: User };
export type ProviderWithVersions = ProviderRegistryApi.ProviderWithVersions & { id: string };
export type CreateProviderPayload = Pick<Provider, 'organizationId' | 'type' | 'description'>;
export type UpdateProviderPayload = Pick<Provider, 'description'>;

export type GpgKey = ProviderRegistryApi.GpgKey & { id: string };
export type CreateGpgKeyPayload = Omit<GpgKey, 'id' | 'createdByUser' | 'createdBy'>;
export type CreateGpgKeyUserPayload = Omit<CreateGpgKeyPayload, 'organizationId'>;

export type SshKey = Omit<ConfigurationApi.SshKey, 'organizationId'>;

export type CreateSshKeyInput = Pick<SshKey, 'name' | 'value'>;
export type UpdateSshKeyInput = Pick<SshKey, 'value'>;

export type KeyType = 'SSH' | 'GPG';

export interface Token extends ConfigurationProperty {
  type: TokenTypes;
  readonly id: string;
  readonly user: User;
  readonly userId: string;
}

export interface Tokens {
  [key: string]: Token;
}

export type GitHostedProviderTypes = BlueprintApi.GitHostedProviderTypes;
export type GitProviderTypes = BlueprintApi.SourceTypes;

// NOTE: Order matters here - higher index => more permissions
export enum AuthorizerRole {
  User,
  Admin
}

export type DefaultOrganizationRole = 'Admin' | 'User';

export type NewOrganization = Partial<Pick<Organization, 'description' | 'photoUrl'>> & Pick<Organization, 'name'>;

export type Organization = OrganizationApi.Organization & {
  role: DefaultOrganizationRole | RolesApi.RBACPermissionRole;
};

export interface Organizations {
  [id: string]: Organization;
}

export interface OrganizationInvitation {
  id: string;
  token: string;
  organizationId: string;
  email: string;
  createdBy: string;
  organization: {
    name: string;
    photoUrl: string;
  };
}

export enum OrganizationUserStatus {
  Active = 'Active',
  Invited = 'Invited'
}

export interface OrganizationUser {
  user: {
    email: string;
    name: string;
    user_id: string;
    created_at?: string;
    last_login?: string;
    picture?: string;
    app_metadata?: {
      isApiKey: boolean;
    };
  };
  role: RolesApi.RBACPermissionRole;
  status: OrganizationUserStatus;
  inviteId?: string;
}

export type GcpCreateCredentialsRequest = {
  tableId: string;
  secret: string;
};

export type GcpServiceAccountCreateCredentialsRequest = {
  serviceAccountKey: string;
  projectId?: string;
};

export type OidcRequest = { jwtOidcSub: string };
export type AwsCreateCredentialsRequest = { roleArn: string; externalId: string; duration?: number };
export type AwsAccessKeyCredentialsRequest = { accessKeyId: string; secretAccessKey: string };
export type AwsOIDCRequest = OidcRequest & { roleArn: string };
export type GCPOidcRequest = OidcRequest & { credentialConfigurationFileContent: string };
export type AzureCreateCredentialsRequest = {
  clientId: string;
  clientSecret: string;
  tenantId: string;
  subscriptionId: string;
};
export type AzureOIDCRequest = OidcRequest & {
  clientId: string;
  tenantId: string;
  subscriptionId: string;
};

export type K8SCreateCredentialsRequest = {
  kubeConfig: string;
};

export type VaultOIDCRequest = OidcRequest & {
  roleName: string;
  address: string;
  version: string;
  jwtAuthBackendPath: string;
  namespace?: string;
};

export type CredentialValues =
  | AwsCreateCredentialsRequest
  | AwsAccessKeyCredentialsRequest
  | AwsOIDCRequest
  | GcpCreateCredentialsRequest
  | GcpServiceAccountCreateCredentialsRequest
  | GCPOidcRequest
  | AzureCreateCredentialsRequest
  | AzureOIDCRequest
  | K8SCreateCredentialsRequest
  | VaultOIDCRequest;

export interface NewCredential<T = CredentialValues> {
  name: string;
  type: CredentialType;
  value: T;
}

export interface Credential extends NewCredential {
  id: string;
  createdByUser: User;
  updatedAt: string;
}

export interface CreateProjectPayload {
  name: string;
  description: string;
  organizationId: string;
  parentProjectId?: string;
}

export interface UpdateProjectPayload {
  name: string;
  description?: string;
}

export enum ProjectRoles {
  Admin = 'Admin',
  Deployer = 'Deployer',
  Planner = 'Planner',
  Viewer = 'Viewer'
}

export interface Project {
  id: string;
  name: string;
  organizationId: string;
  createdBy: string;
  createdByUser?: User;
  createdAt: string;
  updatedAt: string;
  description?: string;
  role: RolesApi.RBACPermissionRole;
  isArchived: boolean;
  hierarchy: string;
  parentProjectId?: string;
  children: Project[];
  isReadable: boolean;
}

export type ProjectWithoutChildren = Omit<Project, 'children'>;

export interface Projects {
  [id: string]: Project;
}

export interface ApiKey {
  id: string;
  name: string;
  apiKeyId: string;
  apiKeySecret?: string;
  createdAt: Date;
  lastUsed: Date;
  createdBy: string;
  organizationRole: OrganizationApi.OrganizationRolesString;
}

interface UserAppMetadata {
  isApiKey?: boolean;
  organizations: Organization[];
  createdBy?: string;
}
declare module 'auth0-js' {
  interface Auth0UserProfile {
    'https://env0.com/app_metadata': UserAppMetadata;
  }
}

export type EnrichedProjectNotificationSetting = NotificationsApi.ProjectNotificationSetting & {
  endpoint: NotificationsApi.NotificationEndpoint;
};

export type CredentialUsageType = 'DEPLOYMENT' | 'COSTS';

export type VcsRevisionParams = {
  repository: string;
  tokenId?: string;
  githubInstallationId?: number;
  bitbucketClientKey?: string;
  sshKeyIds?: string[];
};

export type Module = BlueprintApi.ExistingModule;

export type ModuleReadme = {
  id: string;
  content: string | null;
};

export type VariablesFromRepositoryParams = Pick<
  BlueprintApi.Blueprint,
  | 'sshKeys'
  | 'bitbucketClientKey'
  | 'githubInstallationId'
  | 'tokenId'
  | 'revision'
  | 'path'
  | 'repository'
  | 'isBitbucketServer'
  | 'isGitLabEnterprise'
  | 'isGitHubEnterprise'
>;

export type DeploymentLogForCostChart = EnvironmentApi.FindDeploymentLogsForCost.Response[number];

export type Role = RolesApi.Role;

export type AuditLog = AuditEventApi.AuditLog;

export type CreateRolePayload = RolesApi.Create.Request.Body;

export type PersonalApiKey = CredentialsApi.PersonalApiKey;

export type CreatePersonalApiKeyRequest = CredentialsApi.CreatePersonalApiKey.Request.Body;

export type CreateVcsProviderUserMapping = Omit<AccountApi.VcsProviderUserMapping, 'id' | 'userId'>;
export type UpdateVcsProviderUserMapping = Omit<AccountApi.VcsProviderUserMapping, 'userId'>;
