import React, { useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LeftOutlined } from '@ant-design/icons';
import { useClickAway } from 'ahooks';
import {
  OrganizationButton,
  Panel,
  PanelGrowSection,
  PanelOrganizationSection,
  PanelSecondarySection,
  PanelSubTitle,
  PanelTopSection,
  PanelType
} from 'components/layout/navbar/navbar-panel.utils';
import OrganizationMenu from 'components/layout/navbar/navbar-organization-menu';
import OrganizationSwitcher from 'components/layout/navbar/navbar-organization-switcher';
import OrganizationItem from 'components/layout/navbar/navbar-organization-item';
import { ReactComponent as SwitchArrows } from 'assets/images/switch-arrows.svg';
import { ReactComponent as SwitchArrowsActive } from 'assets/images/switch-arrows-active.svg';
import useStores from 'hooks/use-stores.hooks';
import type { Organization } from 'types/api.types';
import styled from 'types/theme.types';

interface Props {
  organization: Organization;
  onPanelChange: (panel: PanelType) => void;
  currentPanel: PanelType;
}

const OrganizationPanel = ({ organization, onPanelChange, currentPanel }: Props) => {
  const { organizationsStore } = useStores();
  const switcherRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [switcherOpen, setSwitcherOpen] = useState(false);

  useClickAway(() => setSwitcherOpen(false), [switcherRef, buttonRef]);

  const switchOrganization = useCallback(
    (organization: Organization) => {
      organizationsStore.switchCurrentOrganization(organization.id, undefined, '/');
      setSwitcherOpen(false);
    },
    [organizationsStore]
  );

  return (
    <Panel visible={currentPanel === PanelType.Organization}>
      <StyledPanelTopSection data-e2e="navbar-organization-back" onClick={() => onPanelChange(PanelType.Projects)}>
        <StyledLeftOutlined />
        <span>
          <FormattedMessage id="navigation.back-to-projects" />
        </span>
      </StyledPanelTopSection>
      <PanelGrowSection data-e2e="navbar-organization-menu">
        <PanelSubTitle>
          <FormattedMessage id="organization" />
        </PanelSubTitle>
        <OrganizationMenu organization={organization} />
      </PanelGrowSection>
      <PanelSecondarySection>
        <StyledOrganizationSwitcher
          innerRef={switcherRef}
          open={switcherOpen}
          onClose={() => setSwitcherOpen(false)}
          onChange={switchOrganization}
        />
      </PanelSecondarySection>
      <StyledPanelOrganizationSection data-e2e="navbar-organization-current">
        <OrganizationItem noHover organization={organization} />
        <StyledOrganizationButton
          ref={buttonRef}
          data-e2e="navbar-organization-switch-button"
          onClick={() => setSwitcherOpen(switcherOpen => !switcherOpen)}>
          {switcherOpen ? <SwitchArrowsActive /> : <SwitchArrows />}
        </StyledOrganizationButton>
      </StyledPanelOrganizationSection>
    </Panel>
  );
};

export default OrganizationPanel;

const StyledPanelTopSection = styled(PanelTopSection)`
  gap: 10px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  line-height: normal;
`;

const StyledLeftOutlined = styled(LeftOutlined)`
  font-size: 0.7rem;
`;

const StyledOrganizationSwitcher = styled(OrganizationSwitcher)<{ open: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(${({ open }) => (open ? -100 : 0)}%);
  transition: transform 0.15s ease-in-out;
`;

const StyledPanelOrganizationSection = styled(PanelOrganizationSection)`
  background-color: ${({ theme }) => theme.menuItemBlue};
`;

const StyledOrganizationButton = styled(OrganizationButton)`
  background: ${({ theme }) => theme.menuItemBlue};
  color: rgba(255, 255, 255, 0.4);
  transition: background 0.1s ease-in-out, color 0.1s ease-in-out;

  :hover {
    background: ${({ theme }) => theme.sidebarBorder};
    color: white;
  }

  svg {
    pointer-events: none;
  }
`;
