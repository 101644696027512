import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'types/theme.types';
import { Button } from 'antd';
import { Bold } from 'components/common/modal';
import CommonModal from 'components/common/modal';
import Lottie from 'lottie-react';
import CelebrationBot from 'assets/images/animations/env0-robot-celebration.json';
import { css } from '@emotion/css';
import Link from 'components/common/link';
import { links } from 'constants/external-links';
import { Col, Row } from 'components/common/grid';
import {
  BigGreenTitle,
  PurpleSubtitle
} from 'components/projects/settings/environment-discovery/environment-discovery-typography';

interface EnvironmentDiscoverySuccessModalProps {
  isOpen: boolean;
  hideModal: () => void;
  globPattern: string;
}

const EnvironmentDiscoverySuccessModal: React.FC<EnvironmentDiscoverySuccessModalProps> = ({
  isOpen,
  hideModal,
  globPattern
}) => {
  return (
    <CommonModal
      data-e2e="environment-discovery-success-modal"
      closable={false}
      open={isOpen}
      wrapClassName={ModalWrapper}
      footer={[
        <Button
          size="large"
          type="primary"
          data-e2e="environment-discovery-success-modal-ok-button"
          onClick={hideModal}>
          <FormattedMessage id="form.confirm" />
        </Button>
      ]}>
      <Container>
        <BigGreenTitle>
          <FormattedMessage id="environment.discovery.success.modal.title" />
        </BigGreenTitle>
        <PurpleSubtitle>
          <FormattedMessage id="environment.discovery.success.modal.subtitle" />
        </PurpleSubtitle>
        <Row wrap={false}>
          <Col>
            <Description>
              <FormattedMessage
                id="environment.discovery.success.modal.description"
                values={{ glob: <Bold>{globPattern}</Bold> }}
              />
            </Description>
            <Link url={links.docs.ENVIRONMENT_DISCOVERY.ROOT}>
              <FormattedMessage id="learn.more"></FormattedMessage>
            </Link>
          </Col>

          <CelebrationBotAnimation loop autoplay animationData={CelebrationBot} />
        </Row>
      </Container>
    </CommonModal>
  );
};

const ModalWrapper = css`
  & .ant-modal-content {
    min-width: 760px;
  }
  & .ant-modal-footer {
    display: flex;
    justify-content: center;
    border-top: none;
  }
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  color: ${({ theme }) => theme.primaryBlack};
  white-space: pre-line;
  padding-top: 2em;
  margin-bottom: 0.5em;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const CelebrationBotAnimation = styled(Lottie)`
  height: 200px;
`;

export default EnvironmentDiscoverySuccessModal;
