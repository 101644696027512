import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { BillingApi } from '@env0/billing-service/api';

export default (client: HttpClient) => ({
  getBillingInformation: (organizationId: string) =>
    client.get<BillingApi.BillingInfo>(`billing/organizations/${organizationId}`).then(returnData),
  getManageSubscriptionLink: (organizationId: string) =>
    client
      .get<BillingApi.GetManageSubscriptionLink.Response>(
        `billing/organizations/${organizationId}/manage-subscription-link`
      )
      .then(returnData)
});
