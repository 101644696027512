import set from 'lodash/fp/set';
import orderBy from 'lodash/fp/orderBy';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import compose from 'lodash/fp/compose';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import { action, computed, observable } from 'mobx';
import deepEqual from 'fast-deep-equal';
import StatusCode from 'http-status-codes';

import type {
  ConfigurationProperty,
  CreateEnvironmentArgs,
  DeployEnvironmentWithoutTemplateArgs,
  DeploymentLog,
  Environment,
  Environments,
  RunTaskPayload
} from 'types/api.types';
import type { EnvironmentApi } from '@env0/environment-service/api';
import BaseService from 'services/base-service';
import { DEFAULT_PAGE_SIZE_LIMIT, type DeploymentsFilterOptions } from 'services/api-client/environments';
import type { RunEnvironmentArgs } from 'utils/environment.utils';

type DownstreamEnvironment = EnvironmentApi.DownstreamEnvironment;

type QueryMetadata = {
  organizationId?: string;
  projectId?: string;
  onlyMy: boolean;
  searchText: string;
};

export const DEFAULT_DEPLOYMENT_PAGE_SIZE = 25;
export const MAX_INACTIVE_ENVIRONMENTS_TO_FETCH = 100;

export const hasMorePagesHeader = 'x-has-more-pages';

export class EnvironmentsStore extends BaseService {
  @observable environments: Environments = {};
  @observable hasDeploymentLogInProgressByEnvironment: Set<string> = new Set();
  @observable lastTriggeredDeploymentByEnvironment: Map<string, DeploymentLog> = new Map();
  @observable activeEnvironmentsLoading = true;
  @observable deploymentsByEnvironmentId: Map<string, DeploymentLog[]> = new Map();
  @observable resourcesByDeploymentLogId: Map<string, EnvironmentApi.EnvironmentResource[]> = new Map();
  @observable downstreamEnvironmentsByEnvironmentId: Map<string, DownstreamEnvironment[]> = new Map();
  @observable hasNextPageOfDeploymentsByEnvironmentId: Map<string, boolean> = new Map();
  cacheMetadata: QueryMetadata = {} as QueryMetadata;
  private cancelledFetchingEnvironments = false;

  @computed get allEnvironments() {
    return this.getFilteredEnvironments(() => true);
  }

  @computed get activeEnvironments() {
    return this.getFilteredEnvironments(environment => this.isActiveEnvironment(environment));
  }

  @computed get inactiveEnvironments() {
    return this.getFilteredEnvironments(environment => !this.isActiveEnvironment(environment));
  }

  @computed get needsAttentionEnvironments() {
    return this.getFilteredEnvironments(
      environment =>
        this.isActiveEnvironment(environment) &&
        (this.isActiveDestroyFailure(environment) ||
          this.isActiveDeployFailure(environment) ||
          environment.status === 'WAITING_FOR_USER' ||
          environment.status === 'DRIFTED' ||
          environment.driftStatus === 'ERROR' ||
          environment.driftStatus === 'DRIFTED')
    );
  }

  @computed get scheduledEnvironments() {
    return this.getFilteredEnvironments(
      ({ nextScheduledDates }) => !!(nextScheduledDates?.deploy || nextScheduledDates?.destroy)
    );
  }

  myActiveEnvironments(userId: string) {
    return this.getFilteredEnvironments(
      environment => this.isActiveEnvironment(environment) && environment.userId === userId
    );
  }

  @computed get failedToDestroyActiveEnvironments() {
    return this.getFilteredEnvironments(this.isActiveDestroyFailure);
  }

  @computed get failedToDeployActiveEnvironments() {
    return this.getFilteredEnvironments(this.isActiveDeployFailure);
  }

  @action setEnvironments(environments: Environment[]) {
    environments.forEach(environment => {
      this.environments[environment.id] = environment;
    });
  }

  @action setActiveEnvironmentsLoading(loading: boolean) {
    this.activeEnvironmentsLoading = loading;
  }

  @action clearEnvironments() {
    this.environments = {};
  }

  @action clearEnvironmentsById(environmentIds: string[]) {
    environmentIds.forEach(envId => delete this.environments[envId]);
  }

  @action updateEnvironmentAction(environment: Environment) {
    if (deepEqual(environment, this.environments[environment.id])) return;
    this.environments = set(environment.id, environment, this.environments);
  }

  @action setDeploymentLogById(deploymentLog: DeploymentLog) {
    const { environmentId } = deploymentLog;
    const deployments = this.deploymentsByEnvironmentId.get(environmentId) || [];

    const index = findIndex(deployments, ['id', deploymentLog.id]);

    if (index === -1) {
      deployments.push(deploymentLog);
    } else {
      deployments[index] = deploymentLog;
    }

    this.setDeploymentsByEnvironmentId(deployments, environmentId);
  }

  @action setDeploymentsByEnvironmentId(deployments: DeploymentLog[], environmentId: string) {
    this.deploymentsByEnvironmentId.set(environmentId, deployments);
  }

  async getLastManualDeploymentTryByEnvironmentId(environmentId: string, filterOptions?: DeploymentsFilterOptions) {
    await this.loadEnvironmentDeployments(environmentId, filterOptions);
    const envDeployments = this.deploymentsByEnvironmentId.get(environmentId);
    const deployments = envDeployments?.filter(env => env.type === 'deploy' && env.triggerName === 'user');
    return maxBy(deployments, deployment => new Date(deployment.startedAt)); // last deploy try
  }

  @action setResourcesForDeploymentLogId(deploymentLogId: string, resources: EnvironmentApi.EnvironmentResource[]) {
    this.resourcesByDeploymentLogId.set(deploymentLogId, resources);
  }

  @action setDownstreamEnvironmentsByEnvironmentId(
    environmentId: string,
    downstreamEnvironments: DownstreamEnvironment[]
  ) {
    this.downstreamEnvironmentsByEnvironmentId.set(environmentId, downstreamEnvironments);
  }

  @action setHasNextPageOfDeploymentsByEnvironmentId(
    environmentId: string,
    isTruncatedResponseHeader: string | undefined
  ) {
    this.hasNextPageOfDeploymentsByEnvironmentId.set(environmentId, isTruncatedResponseHeader === 'true');
  }

  isCacheMetadataEqual(params: QueryMetadata): boolean {
    return deepEqual(this.cacheMetadata, params);
  }

  async fetchEnvironments(params: QueryMetadata): Promise<void> {
    const isCacheDirty = !this.isCacheMetadataEqual(params);

    if (isCacheDirty) {
      this.cacheMetadata = params;
      this.setActiveEnvironmentsLoading(true);
      this.clearEnvironments();
    }

    const activeEnvironmentsLength = await this.loadChunkEnvironments({ ...params, isActive: true });

    // we can call multiple times to fetchEnvironments - we want only the last one will stop the loader
    if (this.isCacheMetadataEqual(params)) {
      this.setActiveEnvironmentsLoading(false);
    }

    this.cancelledFetchingEnvironments = false;
    await Promise.all([
      this.fetchNextEnvironmentPages({ params, isActive: true, offset: activeEnvironmentsLength }),
      this.fetchNextEnvironmentPages({ params, isActive: false, offset: 0 })
    ]);
  }

  private async fetchNextEnvironmentPages({
    offset,
    params,
    isActive
  }: {
    offset: number;
    params: QueryMetadata;
    isActive: boolean;
  }): Promise<void> {
    let lastPageSize = offset;
    const loadNextChunk = async () => {
      lastPageSize = await this.loadChunkEnvironments({
        ...params,
        isActive,
        limit: DEFAULT_PAGE_SIZE_LIMIT,
        offset
      });

      offset += lastPageSize;
    };

    // fetch first page if there was no offset
    if (offset === 0) {
      await loadNextChunk();
    }

    while (
      EnvironmentsStore.lastPageIsFull(lastPageSize) &&
      !this.cancelledFetchingEnvironments &&
      !this.shouldLimitFetchingInactiveEnvironments(isActive, offset)
    ) {
      await loadNextChunk();
    }
  }

  shouldLimitFetchingInactiveEnvironments(isActive: boolean, offset: number) {
    return !isActive && offset > MAX_INACTIVE_ENVIRONMENTS_TO_FETCH;
  }

  cancelFetchingEnvironments() {
    this.cancelledFetchingEnvironments = true;
  }

  private static lastPageIsFull(lastPageSize: number) {
    return DEFAULT_PAGE_SIZE_LIMIT === lastPageSize;
  }

  private async loadChunkEnvironments({
    projectId,
    onlyMy,
    isActive,
    limit,
    offset,
    organizationId,
    searchText
  }: {
    isActive: boolean;
    limit?: number;
    offset?: number;
  } & QueryMetadata): Promise<number> {
    const { data: environments } = await this.service.apiClient.environments.getAll({
      organizationId,
      projectId,
      onlyMy,
      isActive,
      limit,
      offset,
      searchText
    });

    const params = organizationId ? { organizationId } : { projectId };
    if (this.isCacheMetadataEqual({ onlyMy, searchText, ...params })) {
      this.setEnvironments(environments);
    }
    return environments.length;
  }

  async countEnvironmentsWithStatus(projectId: string, status?: EnvironmentApi.EnvironmentStatus) {
    const { data: activeEnvironmentsAmount } = await this.service.apiClient.environments.countActive(projectId, status);
    return activeEnvironmentsAmount;
  }

  async getEnvironment(id: string, hideNotification?: boolean) {
    const environment = await this.service.apiClient.environments.getEnvironment(id, { hideNotification });
    this.updateEnvironmentAction(environment);
    if (environment.latestDeploymentLog) {
      this.setDeploymentLogById(environment.latestDeploymentLog);
    }
    return environment;
  }

  createEnvironment = async (args: CreateEnvironmentArgs) => {
    const { data: response } = await this.service.apiClient.environments.create(args);
    this.updateEnvironmentAction(response);
    return response;
  };

  deployEnvironmentWithoutTemplate = async (args: DeployEnvironmentWithoutTemplateArgs) => {
    const { data: response } = await this.service.apiClient.environments.deployEnvironmentWithoutTemplate(args);
    this.updateEnvironmentAction(response);
    return response;
  };

  async deployToEnvironment(
    environmentId: string,
    blueprintId: string,
    blueprintRevision?: string,
    comment?: string,
    configurationChanges?: ConfigurationProperty[],
    configurationSetChanges?: EnvironmentApi.ConfigurationSetChanges,
    ttlRequest?: EnvironmentApi.TTLRequest,
    envName?: string,
    userRequiresApproval?: boolean,
    subEnvironments?: RunEnvironmentArgs['subEnvironments'],
    workflowDeploymentOptions?: EnvironmentApi.Deploy.Request.Body['workflowDeploymentOptions'],
    targets?: string[]
  ) {
    try {
      this.deploymentLogCreationInProgess(environmentId);

      const { data: deployResult } = await this.service.apiClient.environments.deployToEnvironment({
        environmentId,
        blueprintId,
        blueprintRevision,
        comment,
        configurationChanges,
        configurationSetChanges,
        ttl: ttlRequest,
        envName,
        userRequiresApproval,
        subEnvironments,
        workflowDeploymentOptions,
        targets
      });

      await this.getEnvironment(environmentId);
      return deployResult;
    } finally {
      this.deploymentLogCreationFinished(environmentId);
    }
  }

  async getDeploymentLogById(deploymentLogId: string) {
    const deployment = await this.service.apiClient.deployments.getDeploymentLogById(deploymentLogId);
    if (deployment) this.setDeploymentLogById(deployment);
  }

  async getSubDeploymentLog(workflowDeploymentId: string, subEnvironmentId: string) {
    try {
      const deployment = await this.service.apiClient.deployments.getSubDeploymentLog(
        workflowDeploymentId,
        subEnvironmentId
      );
      if (deployment) this.setDeploymentLogById(deployment);
    } catch (err) {
      const statusCode = get('response.status', err);
      if (statusCode === StatusCode.NOT_FOUND) {
        return undefined;
      } else {
        throw err;
      }
    }
  }

  async loadEnvironmentDeployments(environmentId: string, filterOptions?: DeploymentsFilterOptions) {
    const { data: deployments, headers } = await this.service.apiClient.environments.getEnvironmentDeployments(
      environmentId,
      0,
      DEFAULT_DEPLOYMENT_PAGE_SIZE,
      filterOptions
    );
    if (deployments) {
      this.setDeploymentsByEnvironmentId(deployments, environmentId);
      this.setHasNextPageOfDeploymentsByEnvironmentId(environmentId, headers![hasMorePagesHeader]);
    }
  }

  async loadNextPageOfDeployments(environmentId: string, filterOptions?: DeploymentsFilterOptions) {
    const currentDeployments = this.deploymentsByEnvironmentId.get(environmentId) || [];
    const offset = currentDeployments.length;
    const { data: nextPage, headers } = await this.service.apiClient.environments.getEnvironmentDeployments(
      environmentId,
      offset,
      DEFAULT_DEPLOYMENT_PAGE_SIZE,
      filterOptions
    );
    const allDeployments = [...currentDeployments, ...nextPage];
    this.setDeploymentsByEnvironmentId(allDeployments, environmentId);
    this.setHasNextPageOfDeploymentsByEnvironmentId(environmentId, headers![hasMorePagesHeader]);
  }

  async destroyEnvironment(environment: Environment, params: EnvironmentApi.Destroy.Request.DestroyParams) {
    const { data } = await this.service.apiClient.deployments.destroy(environment.id, params);
    await this.getEnvironment(environment.id);
    return data;
  }

  async resumeDeployment(environmentId: string, deploymentId: string) {
    const deployResult = await this.service.apiClient.deployments.resume(deploymentId);
    await this.getEnvironment(environmentId);
    return deployResult;
  }

  async cancelDeployment(environmentId: string, deploymentId: string) {
    const { data } = await this.service.apiClient.deployments.cancel(deploymentId);
    await this.getEnvironment(environmentId);
    return data;
  }

  batchCancelQueuedDeployments(environmentId: string) {
    return this.service.apiClient.deployments.batchCancel(environmentId, 'QUEUED');
  }

  async abortDeployment(deploymentLog: DeploymentLog) {
    await this.service.apiClient.deployments.abort(deploymentLog.id);
    await this.getEnvironment(deploymentLog.environmentId);
  }

  async saveAsTemplate(environmentId: string, newName: string) {
    const response = await this.service.apiClient.environments.saveAsTemplate(environmentId, newName);
    this.updateEnvironmentAction(response);
  }

  async setEnvironmentTtl(environmentId: string, ttl?: EnvironmentApi.TTLRequest) {
    const environment = await this.service.apiClient.environments.setEnvironmentTtl(environmentId, ttl);

    if (environment) this.updateEnvironmentAction(environment);
  }

  async updateEnvironmentArchived(environmentId: string, isArchived: boolean) {
    return this.updateEnvironment(environmentId, { isArchived });
  }

  rerunPrPlan(deploymentId: string) {
    return this.service.apiClient.deployments.rerunDeploymentPRPlan(deploymentId);
  }

  async runTask(environmentId: string, taskPayload: RunTaskPayload) {
    return await this.service.apiClient.environments.runTask(environmentId, taskPayload);
  }

  async updateEnvironmentCdAndPrPlan(
    environmentId: string,
    {
      continuousDeployment,
      pullRequestPlanDeployments,
      autoDeployOnPathChangesOnly,
      autoDeployByCustomGlob,
      vcsCommandsAlias,
      vcsPrCommentsEnabled
    }: Pick<
      EnvironmentApi.Update.Request.Body,
      | 'continuousDeployment'
      | 'pullRequestPlanDeployments'
      | 'autoDeployOnPathChangesOnly'
      | 'autoDeployByCustomGlob'
      | 'vcsCommandsAlias'
      | 'vcsPrCommentsEnabled'
    >
  ) {
    return this.updateEnvironment(environmentId, {
      continuousDeployment,
      pullRequestPlanDeployments,
      autoDeployOnPathChangesOnly,
      autoDeployByCustomGlob,
      vcsCommandsAlias,
      vcsPrCommentsEnabled
    });
  }

  async updateEnvironmentGeneralSettings(
    environmentId: string,
    {
      requiresApproval,
      isRemoteBackend,
      isRemoteApplyEnabled
    }: Pick<EnvironmentApi.Update.Request.Body, 'requiresApproval' | 'isRemoteBackend' | 'isRemoteApplyEnabled'>
  ) {
    return this.updateEnvironment(environmentId, {
      isRemoteBackend,
      requiresApproval,
      isRemoteApplyEnabled
    });
  }

  async updateEnvironmentName(environmentId: string, name: string) {
    return this.updateEnvironment(environmentId, { name });
  }

  private async updateEnvironment(environmentId: string, updateData: Partial<Environment>) {
    const environment = this.environments[environmentId];
    try {
      this.updateEnvironmentAction({ ...environment, ...updateData });
      await this.service.apiClient.environments.update(environmentId, updateData);
    } catch (error) {
      this.updateEnvironmentAction(environment); // restore previous state
    }
  }

  async loadResources(deploymentLogId: string) {
    const resources = await this.service.apiClient.deployments.getResources(deploymentLogId);
    const sorted = sortBy(resources, ['provider', 'type', 'name']);
    this.setResourcesForDeploymentLogId(deploymentLogId, sorted);
  }

  isActiveEnvironment(environment: Environment) {
    return !environment.isArchived && environment.status !== 'INACTIVE';
  }

  isArchivedEnvironment({ isArchived }: Environment) {
    return isArchived;
  }

  private getFilteredEnvironments(predicate: (e: Environment) => boolean) {
    const withoutSubEnvironmentsPredicate: (e: Environment) => boolean = ({ workflowEnvironmentId }) =>
      !workflowEnvironmentId;

    return compose(
      filter(predicate),
      filter(withoutSubEnvironmentsPredicate),
      orderBy(
        (environment: Environment) =>
          get('latestDeploymentLog.createdAt', environment) || get('createdAt', environment),
        'desc'
      )
    )(this.environments);
  }

  private isActiveDestroyFailure = (environment: Environment) =>
    this.isActiveFailure(environment) && environment.latestDeploymentLog?.type === 'destroy';

  private isActiveDeployFailure = (environment: Environment) =>
    this.isActiveFailure(environment) && environment.latestDeploymentLog?.type === 'deploy';

  private isActiveFailure = (environment: Environment) =>
    this.isActiveEnvironment(environment) && (environment.status === 'FAILED' || environment.status === 'TIMEOUT');

  async loadDownstreamEnvironments(environmentId: string) {
    const downstreamEnvironments = await this.service.apiClient.environments.findDownstreamEnvironments(environmentId);

    this.setDownstreamEnvironmentsByEnvironmentId(environmentId, downstreamEnvironments);
  }

  async updateDownstreamEnvironments(environmentId: string, downstreamEnvironments: DownstreamEnvironment[]) {
    const updatedDownstreamEnvironments = await this.service.apiClient.environments.updateDownstreamEnvironments(
      environmentId,
      { downstreamEnvironmentIds: map(downstreamEnvironments, 'id') }
    );

    this.setDownstreamEnvironmentsByEnvironmentId(environmentId, updatedDownstreamEnvironments);
  }

  async updateEnvironmentLock(environmentId: string, payload: EnvironmentApi.UpdateEnvironmentLock.Request.Body) {
    const lockStatus = await this.service.apiClient.environments.updateEnvironmentLock(environmentId, payload);

    const environment = this.environments[environmentId];

    this.setEnvironments([{ ...environment, lockStatus, isLocked: !!lockStatus }]);
  }

  private deploymentLogCreationInProgess(environmentId: string) {
    this.hasDeploymentLogInProgressByEnvironment.add(environmentId);
  }

  private deploymentLogCreationFinished(environmentId: string) {
    this.hasDeploymentLogInProgressByEnvironment.delete(environmentId);
  }

  isDeploymentLogBeingCreated(environmentId: string) {
    return this.hasDeploymentLogInProgressByEnvironment.has(environmentId);
  }

  storeLastTriggeredDeployment(environmentId: string, deploymentLog: DeploymentLog) {
    this.lastTriggeredDeploymentByEnvironment.set(environmentId, deploymentLog);
  }

  getLastTriggeredDeployment(environmentId: string) {
    return this.lastTriggeredDeploymentByEnvironment.get(environmentId);
  }

  removeLastTriggeredDeployment(environmentId: string) {
    this.lastTriggeredDeploymentByEnvironment.delete(environmentId);
  }
}
