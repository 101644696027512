import React, { useCallback } from 'react';
import Tooltip from 'components/common/tooltip';
import { SearchOutlined } from '@ant-design/icons';
import { useKBar } from 'kbar';
import styled from 'types/theme.types';

export const KbarSearchButton = () => {
  const { query } = useKBar();

  const toggleKbar = useCallback(() => query?.toggle(), [query]);

  return (
    <Tooltip placement="left" titleId="search">
      <Wrapper color="black" onClick={toggleKbar} data-e2e="search-button">
        <SearchOutlined className="search" />
      </Wrapper>
    </Tooltip>
  );
};

const Wrapper = styled.div`
  display: grid;
  place-items: center;

  .search {
    font-size: 24px;
  }
`;
