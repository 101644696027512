import React, { type ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, matchPath, useLocation } from 'react-router-dom';
import compact from 'lodash/compact';
import type { RolesApi } from '@env0/role-service/api';
import { HoverItem } from 'components/layout/navbar/navbar-panel.utils';
import { useCloseOnMobile } from 'components/layout/navbar/navbar-panel.hooks';
import { useHasPermissionCallback } from 'hooks/use-has-permission';
import UseIsMobile from 'hooks/use-is-mobile';
import { castPath } from 'utils/router.utils';
import type { Organization } from 'types/api.types';
import { ReactComponent as ProjectsIcon } from 'assets/images/org-projects.svg';
import { ReactComponent as ProjectsIconActive } from 'assets/images/org-projects-active.svg';
import { ReactComponent as TemplatesIcon } from 'assets/images/templates.svg';
import { ReactComponent as TemplatesIconActive } from 'assets/images/templates-active.svg';
import { ReactComponent as RegistryIcon } from 'assets/images/org-registry.svg';
import { ReactComponent as RegistryIconActive } from 'assets/images/org-registry-active.svg';
import { ReactComponent as VariablesIcon } from 'assets/images/variables.svg';
import { ReactComponent as VariablesIconActive } from 'assets/images/variables-active.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/org-dashboard.svg';
import { ReactComponent as CloudInsightsIcon } from 'assets/images/cloud-insights.svg';
import { ReactComponent as DashboardIconActive } from 'assets/images/org-dashboard-active.svg';
import { ReactComponent as CloudInsightsIconActive } from 'assets/images/cloud-insights-active.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/settings.svg';
import { ReactComponent as SettingsIconActive } from 'assets/images/settings-active.svg';

interface Props {
  organization: Organization;
}

const OrganizationMenu = ({ organization }: Props) => {
  const location = useLocation();
  const { isMobile } = UseIsMobile();
  const closeOnMobile = useCloseOnMobile();
  const hasPermission = useHasPermissionCallback();

  const items = useMemo<OrgMenuItem[]>(() => {
    const can = (permission: RolesApi.RBACPermission) => hasPermission(permission).isAuthorized;
    return compact([
      {
        key: 'org-menu-projects',
        to: '/',
        exact: true,
        title: <FormattedMessage id="projects" />,
        icon: <ProjectsIcon />,
        activeIcon: <ProjectsIconActive />
      },
      {
        key: 'org-menu-templates',
        to: '/templates',
        title: <FormattedMessage id="templates" />,
        icon: <TemplatesIcon />,
        activeIcon: <TemplatesIconActive />
      },
      (can('VIEW_MODULES') || can('VIEW_PROVIDERS')) && {
        key: 'org-menu-registry',
        to: `/registry`,
        title: <FormattedMessage id="registry" />,
        icon: <RegistryIcon />,
        activeIcon: <RegistryIconActive />
      },
      !isMobile && {
        key: 'org-menu-variables',
        to: '/variables',
        title: <FormattedMessage id="variables" />,
        icon: <VariablesIcon />,
        activeIcon: <VariablesIconActive />
      },
      can('VIEW_DASHBOARD') && {
        key: 'cloud',
        to: `/cloud`,
        title: <FormattedMessage id="cloud" />,
        icon: <CloudInsightsIcon />,
        activeIcon: <CloudInsightsIconActive />
      },
      can('VIEW_DASHBOARD') && {
        key: 'org-menu-dashboards',
        to: `/dashboards`,
        title: <FormattedMessage id="dashboards" />,
        icon: <DashboardIcon />,
        activeIcon: <DashboardIconActive />
      },
      can('EDIT_ORGANIZATION_SETTINGS') && {
        key: 'org-menu-settings',
        to: `/organizations/${organization.id}`,
        title: <FormattedMessage id="navigation.settings" />,
        icon: <SettingsIcon />,
        activeIcon: <SettingsIconActive />
      }
    ]);
  }, [organization.id, isMobile, hasPermission]);

  return (
    <>
      {items.map(item => {
        const active = Boolean(matchPath(castPath(item.to, item.exact), location.pathname));
        return (
          <Link key={item.key} to={item.to} onClick={closeOnMobile}>
            <HoverItem active={active} data-e2e={item.key}>
              {hovered => (
                <>
                  {active || hovered ? item.activeIcon : item.icon}
                  <span>{item.title}</span>
                </>
              )}
            </HoverItem>
          </Link>
        );
      })}
    </>
  );
};

export default OrganizationMenu;

interface OrgMenuItem {
  key: string;
  to: string;
  exact?: boolean;
  title: ReactNode;
  icon: ReactNode;
  activeIcon: ReactNode;
}
