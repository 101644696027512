import React, { lazy } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes } from 'react-router-dom';
import AppRoute from 'components/routes/app-route';
import SSOLoginPage from 'components/pages/sso-login-page';
import WebsiteLogin from 'components/pages/website-login';
import LoginPage from 'components/pages/login-page';
import { invitationRoutes, onboardingRoutes, routes } from 'components/routes/routes';
import MainLayout from 'components/layout/main-layout';
import InnerLayout from 'components/layout/inner-layout';
import FaviconSwitcher from 'components/common/favicon-switcher';

const LazyComponent = (pagePath: string) => lazy(() => import(`components/pages/${pagePath}`));
export const AppSwitch = () => {
  const intl = useIntl();

  // login pages should not be lazy loaded
  return (
    <Routes>
      <Route path="/login/sso" element={<AppRoute LazyComponent={SSOLoginPage} isPublic />} />
      <Route path="/login/website" element={<AppRoute LazyComponent={WebsiteLogin} isPublic />} />
      <Route path="/login" element={<AppRoute LazyComponent={LoginPage} isPublic />} />
      {invitationRoutes.map(({ layout: Layout, ...route }) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AppRoute LazyComponent={LazyComponent(route.page)} isPublic>
              {content => <Layout>{content}</Layout>}
            </AppRoute>
          }
        />
      ))}
      {onboardingRoutes.map(({ layout: Layout, ...route }) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AppRoute LazyComponent={LazyComponent(route.page)}>{content => <Layout>{content}</Layout>}</AppRoute>
          }
        />
      ))}
      <Route
        path="*"
        element={
          <MainLayout>
            <Routes>
              {routes.map(route => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <AppRoute
                      LazyComponent={LazyComponent(route.page)}
                      rbacPermissions={route.requiredPermissions}
                      permissionOperator={route.permissionOperator}>
                      {content => (
                        <InnerLayout
                          title={intl.formatMessage({ id: route.title })}
                          breadcrumbs={route.breadcrumbs}
                          page={route.page}
                          container={route.container}>
                          <FaviconSwitcher key={route.path} />
                          {content}
                        </InnerLayout>
                      )}
                    </AppRoute>
                  }
                />
              ))}
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          </MainLayout>
        }
      />
    </Routes>
  );
};
