import gitUrlParse from 'git-url-parse';

const getUrlProtocol = (url: string) => {
  let protocol = '';

  try {
    ({ protocol } = gitUrlParse(url));
  } catch {}

  return protocol;
};

export const validateGitUrl = (url: any) => ['http', 'https', 'ssh'].includes(getUrlProtocol(url));
export const validateHelmRepoUrl = (url: any) => ['s3', 'http', 'https'].includes(getUrlProtocol(url));
export const isSsh = (url: string) => getUrlProtocol(url) === 'ssh';
