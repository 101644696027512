import useForm from 'hooks/use-form.hook';
import noop from 'lodash/noop';
import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import type { Optional } from 'utility-types';
import { useCallback } from 'react';
import defaults from 'lodash/defaults';
import isGlob from 'is-glob';
import type { UpsertEnvironmentDiscoveryMapping } from 'components/projects/settings/environment-discovery/project-settings-environment-discovery';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import isEmpty from 'lodash/isEmpty';

export const DEFAULT_TG_GLOB_PATTERN = '**/!(_envcommon)/terragrunt.hcl';
export const DEFAULT_TF_GLOB_PATTERN = '**/!(modules)/main.tf';
export const DEFAULT_WF_GLOB_PATTERN = '**/env0.workflow.{yaml,yml}';
// eslint-disable-next-line no-template-curly-in-string
export const DEFAULT_TG_FILE_FILTER_GLOB_PATTERN = '+((_envcommon/**)|(${env0_template_dir_ancestors}))';
// eslint-disable-next-line no-template-curly-in-string
export const DEFAULT_TF_FILE_FILTER_GLOB_PATTERN = '+((modules/**)|(${env0_template_dir_path}/**))';
// eslint-disable-next-line no-template-curly-in-string
export const DEFAULT_WF_FILE_FILTER_GLOB_PATTERN = '${env0_template_dir_path}/**';
export const DEFAULT_ENVIRONMENT_PLACEMENT = 'topProject';
export const DEFAULT_WORKSPACE_NAMING = 'default';

export type EnvironmentDiscoveryMappingFormReturnType = ReturnType<typeof useEnvironmentDiscoveryMappingForm>;

export type CustomGlobSwitch = 'templateFolder' | 'customGlob';

type EnvironmentDiscoveryMappingFormProps = Optional<BlueprintApi.EnvironmentDiscoveryFields> & {
  autoDeployByCustomGlob?: string | null;
  customGlobSwitch?: CustomGlobSwitch;
};

const environmentDiscoveryMappingSchema: SchemaOf<UpsertEnvironmentDiscoveryMapping> = Yup.object({
  globPattern: Yup.string()
    .default('')
    .test('is-glob', 'glob.validation.error', text => isGlob(text)),
  environmentPlacement: Yup.mixed<BlueprintApi.EnvironmentPlacementStrategy>()
    .required()
    .oneOf(['existingSubProject', 'topProject'])
    .default(DEFAULT_ENVIRONMENT_PLACEMENT),
  workspaceNaming: Yup.mixed<BlueprintApi.WorkspaceNaming>()
    .required()
    .oneOf(['default', 'environmentName'])
    .default(DEFAULT_WORKSPACE_NAMING),
  customGlobSwitch: Yup.mixed<CustomGlobSwitch>()
    .required()
    .oneOf(['templateFolder', 'customGlob'])
    .default('templateFolder'),
  autoDeployByCustomGlob: Yup.string().when('customGlobSwitch', {
    is: 'customGlob',
    then: Yup.string().test('is-glob', 'environment.auto-deploy.settings.redeploy-by-custom-glob.error', text =>
      isGlob(text)
    ),
    otherwise: Yup.string().default('')
  }),
  rootPath: Yup.string().optional()
});

const useEnvironmentDiscoveryMappingForm = ({
  globPattern,
  environmentPlacement,
  workspaceNaming,
  autoDeployByCustomGlob,
  rootPath
}: EnvironmentDiscoveryMappingFormProps) => {
  const getInitialValues = useCallback<() => UpsertEnvironmentDiscoveryMapping>(() => {
    const schemaDefaults = environmentDiscoveryMappingSchema.getDefault() as UpsertEnvironmentDiscoveryMapping;
    const customGlobSwitch = isEmpty(autoDeployByCustomGlob) ? 'templateFolder' : 'customGlob';
    return defaults<EnvironmentDiscoveryMappingFormProps, UpsertEnvironmentDiscoveryMapping>(
      {
        globPattern,
        rootPath,
        environmentPlacement,
        workspaceNaming,
        customGlobSwitch,
        autoDeployByCustomGlob
      },
      { ...schemaDefaults, customGlobSwitch }
    );
  }, [autoDeployByCustomGlob, globPattern, environmentPlacement, workspaceNaming, rootPath]);

  return useForm<UpsertEnvironmentDiscoveryMapping>({
    initialValues: getInitialValues(),
    schema: environmentDiscoveryMappingSchema,
    shouldUnregister: false,
    onSubmit: noop
  });
};

export default useEnvironmentDiscoveryMappingForm;
