import React, { useEffect, useState } from 'react';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import type { PoliciesFormSchema } from 'components/projects/settings/policies/use-policies-form.hook';
import type { EnvironmentApi } from '@env0/environment-service/api';
import { Section } from 'components/common/section';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { FormattedMessage } from 'react-intl';
import { Info } from 'components/common/form-components';
import BigCheckbox from 'components/common/input-components/big-checkbox';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { messagePrefix } from 'components/projects/settings/policies/project-settings-policies';
import styled from 'types/theme.types';
import ControlledNumberInput from 'components/common/form-controlled/controlled-number-input';

const MIN_NUM_OF_ENVIRONMENTS = 1;

const PolicySettings: React.FC<{
  form: UseFormReturnType<PoliciesFormSchema>;
  policies: EnvironmentApi.Policy;
}> = ({ form, policies }) => {
  const { setValue } = form;
  const [isNumOfEnvsUserLimitEnabled, setIsNumOfEnvsUserLimitEnabled] = useState(!!policies?.numberOfEnvironments);
  const [isNumOfEnvsTotalLimitEnabled, setIsNumOfEnvsTotalLimitEnabled] = useState(
    !!policies?.numberOfEnvironmentsTotal
  );

  useEffect(() => {
    if (!isNumOfEnvsUserLimitEnabled && policies?.numberOfEnvironments) {
      setValue('numberOfEnvironments', null);
    }
  }, [isNumOfEnvsUserLimitEnabled, policies?.numberOfEnvironments, setValue]);
  useEffect(() => {
    if (!isNumOfEnvsTotalLimitEnabled && policies?.numberOfEnvironmentsTotal) {
      setValue('numberOfEnvironmentsTotal', null);
    }
  }, [isNumOfEnvsTotalLimitEnabled, policies?.numberOfEnvironmentsTotal, setValue]);

  return (
    <Section titleId={`${messagePrefix}.title.policies`}>
      <ControlledBigCheckbox
        form={form}
        name="disableDestroyEnvironments"
        data-e2e="project-settings-policies-disable-destroy-checkbox">
        <FormattedMessage id={`${messagePrefix}.disable-destroy.checkbox`} />
        <Info alignTop={false} information={<FormattedMessage id={`${messagePrefix}.disable-destroy.tooltip`} />} />
      </ControlledBigCheckbox>
      <NumOfEnvironmentsLimitContainer>
        <BigCheckbox
          checked={isNumOfEnvsUserLimitEnabled}
          disabled={form.formState.isSubmitting}
          onChange={e => setIsNumOfEnvsUserLimitEnabled(e.target.checked)}
          data-e2e="project-settings-policies-num-of-envs-checkbox">
          <FormattedMessage id={`${messagePrefix}.num-env-user-limit.checkbox`} />
          <Info
            alignTop={false}
            information={
              <FormattedMessage
                id={`${messagePrefix}.num-env-user-limit.tooltip`}
                values={setLink(links.docs.POLICIES.ENVIRONMENT_LIMITS)}
              />
            }
          />
        </BigCheckbox>
        <StyledControlledNumberInput
          form={form}
          inline={true}
          name="numberOfEnvironments"
          min={MIN_NUM_OF_ENVIRONMENTS}
          data-e2e="project-settings-policies-num-of-envs-input"
          disabled={!isNumOfEnvsUserLimitEnabled}
        />
      </NumOfEnvironmentsLimitContainer>
      <NumOfEnvironmentsLimitContainer>
        <BigCheckbox
          checked={isNumOfEnvsTotalLimitEnabled}
          disabled={form.formState.isSubmitting}
          onChange={e => setIsNumOfEnvsTotalLimitEnabled(e.target.checked)}
          data-e2e="project-settings-policies-num-of-envs-total-checkbox">
          <FormattedMessage id={`${messagePrefix}.num-env-total-limit.checkbox`} />
          <Info
            alignTop={false}
            information={
              <FormattedMessage
                id={`${messagePrefix}.num-env-total-limit.tooltip`}
                values={setLink(links.docs.POLICIES.ENVIRONMENT_LIMITS)}
              />
            }
          />
        </BigCheckbox>
        <StyledControlledNumberInput
          form={form}
          inline={true}
          name="numberOfEnvironmentsTotal"
          min={MIN_NUM_OF_ENVIRONMENTS}
          data-e2e="project-settings-policies-num-of-envs-total-input"
          disabled={!isNumOfEnvsTotalLimitEnabled}
        />
      </NumOfEnvironmentsLimitContainer>
    </Section>
  );
};

const NumOfEnvironmentsLimitContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledControlledNumberInput = styled(ControlledNumberInput)`
  margin-left: 10px;
  border-color: ${({ theme }) => theme.primaryDisabledGray} !important;
`;

export default PolicySettings;
