import { useMemo } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import type { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import words from 'lodash/words';
import type { Role } from 'types/api.types';
import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import useApiClient from 'hooks/use-api-client';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';
import type { RolesApi } from '@env0/role-service/api';
import { schema } from 'components/roles/role-form/role-form.consts';

const formatPayload = ({ name, permissions }: RoleUpdate, organizationId: string): RolesApi.Create.Request.Body => ({
  name,
  organizationId,
  permissions: (Object.keys(permissions) as Array<keyof typeof permissions>).filter(key => permissions[key])
});

export const formatResponse = (role: RolesApi.Role): UIRole => {
  const permissions = role.permissions.reduce((prev, curr) => {
    prev[curr] = true;
    return prev;
  }, {} as Record<RolesApi.RBACPermission, boolean>);

  const defaultPermissions = schema.getDefault().permissions as UnpackNestedValue<DeepPartial<UIRole>>;

  return {
    id: role.id!,
    name: role.name,
    permissions: {
      ...defaultPermissions,
      ...permissions
    }
  };
};

export interface UIRole {
  id: string;
  name: string;
  permissions: Record<Exclude<RolesApi.RBACPermission, 'VIEW_ENVIRONMENT' | 'ASSIGN_ROLE_ON_ENVIRONMENT'>, boolean>;
}

export type RoleUpdate = Omit<UIRole, 'id'>;

const useCacheKeys = () => {
  const orgId = useCurrentOrganizationId();

  const baseKey = [orgId, 'roles'] as const;
  const cacheKeys = {
    all: [...baseKey, 'all'],
    single: (id: string) => [...baseKey, 'single', { id }] as const
  } as const;

  return cacheKeys;
};

export const useGetRoles = () => {
  const apiClient = useApiClient();
  const { all } = useCacheKeys();

  return useQuery({
    queryKey: all,
    queryFn: ({ queryKey: [orgId] }) => apiClient.roles.getRoles(orgId),
    select: data => data.map(assignDefaultId)
  });
};

export const useGetRole = (id: string) => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all, single } = useCacheKeys();

  return useQuery({
    queryKey: single(id),
    queryFn: () => apiClient.roles.getRole(id),
    placeholderData: () => {
      const roles = queryClient.getQueryData<Role[]>(all);
      return roles?.find(role => role.id === id);
    }
  });
};

export const useCreateRole = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all, single } = useCacheKeys();
  const [orgId] = all;

  return useMutation({
    mutationFn: async (role: RoleUpdate) => {
      const payload = formatPayload(role, orgId);
      return apiClient.roles.createRole(payload);
    },
    onSuccess: role => {
      const newRole = assignDefaultId(role);
      const singleKey = single(newRole.id);
      queryClient.refetchQueries({ queryKey: all });
      queryClient.setQueryData(singleKey, newRole);
    }
  });
};

export const useUpdateRole = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all, single } = useCacheKeys();
  const [orgId] = all;

  return useMutation({
    mutationFn: async (role: UIRole) => {
      const payload = formatPayload(role, orgId);
      return apiClient.roles.updateRole(role.id, payload);
    },
    onSuccess: role => {
      const updatedRole = assignDefaultId(role);
      const singleKey = single(updatedRole.id);
      queryClient.refetchQueries({ queryKey: all });
      queryClient.refetchQueries({ queryKey: singleKey });
    }
  });
};

export const useRemoveRole = () => {
  const apiClient = useApiClient();
  const queryClient = getQueryClientInstance();
  const { all, single } = useCacheKeys();

  return useMutation({
    mutationFn: (id: string) => apiClient.roles.deleteRole(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: single(id) });
      queryClient.refetchQueries({ queryKey: all });
    }
  });
};

export const useCuratedRoles = () => {
  const { data = [], ...rest } = useGetRoles();
  const curatedRoles = useMemo(() => {
    const roles: RolesApi.Role[] = data.filter(role => role.name !== 'Organization TerraformDeployer');
    const allDefaultRoles: RolesApi.Role[] = roles
      .filter(role => role.isDefaultRole)
      .map(role => ({ ...role, id: words(role.name)[1] }));
    const allCustomRoles = roles.filter(role => !role.isDefaultRole);
    const defaultEnvironmentRoles = allDefaultRoles.filter(role => role.name.startsWith('Environment'));
    const defaultProjectRoles = allDefaultRoles.filter(role => role.name.startsWith('Project'));
    const defaultOrganizationRoles = allDefaultRoles.filter(role => role.name.startsWith('Organization'));
    return {
      roles,
      allDefaultRoles,
      allCustomRoles,
      defaultEnvironmentRoles,
      defaultProjectRoles,
      defaultOrganizationRoles
    };
  }, [data]);
  return { ...rest, ...curatedRoles };
};

const assignDefaultId = (role: Role) => ({ id: role.id || role.name, ...role });
