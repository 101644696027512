import React from 'react';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import Card, { CardDescription } from 'components/common/card';
import ErrorContainer from 'components/common/error-container';
import Loader from 'components/common/loader';
import useStores from 'hooks/use-stores.hooks';
import type { Project } from 'types/api.types';
import styled from 'types/theme.types';
import { Info } from 'components/common/form-components';
import { links } from 'constants/external-links';
import { setLink } from 'components/common/link';
import type { EnvironmentApi } from '@env0/environment-service/api';
import { usePoliciesForm } from 'components/projects/settings/policies/use-policies-form.hook';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import ControlledSaveDiscardButtons from 'components/common/form-controlled/controlled-save-discard-buttons';
import { useGetProjectPolicies } from 'stores/rq/project-policies';
import Stack from 'components/common/stack';
import { TtlPolicySection } from 'components/policies/ttl-policy-section';
import { ConfigurationTemplateSettings } from 'components/projects/settings/policies/common/configuration-template-settings';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import SubProjectWontBeAffectedAlert from 'components/projects/sub-projects-not-affected-alert';
import { useCurrentProjectId } from 'hooks/path-params-extraction.hooks';
import Tooltip from 'components/common/tooltip';
import { useCuratedProjects } from 'stores/rq/projects';
import isEmpty from 'lodash/isEmpty';
import { useGetCustomFlowAssignments } from 'stores/rq/custom-flow-assignments';
import DriftDetectionSettings from 'components/projects/settings/policies/sections/drift-detection-settings';
import RemoteStateSettings from 'components/projects/settings/policies/sections/remote-state-settings';
import DeploymentSettings from 'components/projects/settings/policies/sections/deployment-settings';
import PolicySettings from 'components/projects/settings/policies/sections/policy-settings';
import ContinuousDeploymentSettings from 'components/projects/settings/policies/sections/cd-settings';
import { useGetAllApprovalPolicyAssignments } from 'stores/rq/approval-policies-assignments';

type ProjectSettingsPoliciesProps = {
  project: Project;
};

type ProjectSettingsPoliciesFormProps = {
  policies: EnvironmentApi.Policy;
  projectId: string;
  approvalPolicyAssignments: BlueprintApi.ApprovalPolicyTemplateWithScope[];
  customFlowAssignments: BlueprintApi.RichCustomFlowAssigment[];
  projects: Record<string, Project>;
};

export const messagePrefix = 'projects.settings.policies';

const CardHeader: React.FC = () => (
  <>
    <FormattedMessage id={`${messagePrefix}.title`} />
    <CardDescription>
      <FormattedMessage id={`${messagePrefix}.explanation`} values={setLink(links.docs.POLICIES.ROOT)} />
    </CardDescription>
  </>
);

const ProjectPoliciesForm: React.FC<ProjectSettingsPoliciesFormProps> = ({
  policies,
  approvalPolicyAssignments,
  customFlowAssignments,
  projectId,
  projects
}) => {
  const directlyAssignedApprovalPolicyAssignments = approvalPolicyAssignments.filter(
    assignment => assignment.scopeId === projectId
  );

  const inheritedApprovalPolicyAssignments = approvalPolicyAssignments
    .filter(assignment => assignment.scopeId !== projectId)
    .map(assignment => ({ ...assignment, inheritedFrom: projects[assignment.scopeId].name }));

  const directlyAssignedCustomFlowAssignments = customFlowAssignments.filter(
    assignment => assignment.scopeId === projectId
  );
  const inheritedCustomFlowAssignments = customFlowAssignments
    .filter(assignment => assignment.scopeId !== projectId)
    .map(assignment => ({ ...assignment, inheritedFrom: projects[assignment.scopeId].name }));

  const { organizationsStore } = useStores();
  const { form } = usePoliciesForm({
    policies,
    approvalPolicyAssignments: directlyAssignedApprovalPolicyAssignments,
    customFlowAssignments: directlyAssignedCustomFlowAssignments
  });

  const isApprovalPolicyEnabled = () =>
    !isEmpty([...form.watch('approvalPolicyAssignments'), ...inheritedApprovalPolicyAssignments]);

  return (
    <Card title={<CardHeader />}>
      <PoliciesContainer>
        <PolicySettings form={form} policies={policies} />
        <ConfigurationTemplateSettings
          type={'custom-flow'}
          form={form}
          projectId={projectId}
          inheritedAssignments={inheritedCustomFlowAssignments}
          assignmentsFieldName="customFlowAssignments"
          blueprintFieldName="customFlowTemplate"
          reorder
        />
        <ConfigurationTemplateSettings
          type={'approval-policy'}
          form={form}
          projectId={projectId}
          inheritedAssignments={inheritedApprovalPolicyAssignments}
          assignmentsFieldName="approvalPolicyAssignments"
          blueprintFieldName="blueprint">
          <Tooltip
            placement={'topLeft'}
            titleId={
              (isApprovalPolicyEnabled() && 'before.run.settings.approval.automatically.opa.disabled.tooltip') || ''
            }>
            <ControlledBigCheckbox
              form={form}
              name="autoApproveDefault"
              data-e2e="project-settings-policies-auto-approve-checkbox"
              disabled={isApprovalPolicyEnabled()}>
              <FormattedMessage id={`${messagePrefix}.auto-approve.checkbox`} />
              <Info alignTop={false} information={<FormattedMessage id={`${messagePrefix}.auto-approve.tooltip`} />} />
            </ControlledBigCheckbox>
          </Tooltip>
        </ConfigurationTemplateSettings>
        <TtlPolicySection form={form} canInherit={true} />
        <DeploymentSettings
          form={form}
          isCurrentOrganizationSelfHosted={organizationsStore.isCurrentOrganizationSelfHosted}
        />
        <ContinuousDeploymentSettings form={form} />
        <RemoteStateSettings form={form} />
        <DriftDetectionSettings form={form} />
      </PoliciesContainer>
      <StyledControlledSaveDiscardButtons
        data-e2e="project-settings-policies-save-discard"
        form={form}
        cancelMessageId={'discard.changes'}
        inContainer={true}
      />
    </Card>
  );
};

const ProjectSettingsPolicies: React.FC<ProjectSettingsPoliciesProps> = () => {
  const { data: policies, isLoading: isLoadingPolicies, error: policiesError } = useGetProjectPolicies();
  const projectId = useCurrentProjectId();

  const {
    isPending: cfPending,
    error: cfError,
    data: customFlowAssignments
  } = useGetCustomFlowAssignments({ scope: 'PROJECT', scopeId: projectId! });

  const {
    isPending: isPendingApprovalPolicies,
    error: approvalPolicyError,
    data: allApprovalPolicyAssignments
  } = useGetAllApprovalPolicyAssignments({ scope: 'PROJECT', scopeId: projectId! });

  const { projects, isLoading: isLoadingProjects, error: getProjectsError } = useCuratedProjects();

  const isLoading = isLoadingPolicies || isPendingApprovalPolicies || isLoadingProjects || cfPending;
  const error = policiesError || approvalPolicyError || getProjectsError || cfError;

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorContainer data-e2e="project-settings-policies-error" errorToReport={error} />;
  }

  return (
    <Stack>
      <SubProjectWontBeAffectedAlert pageType={'configuration'} />
      <ProjectPoliciesForm
        policies={policies!}
        projectId={projectId!}
        approvalPolicyAssignments={allApprovalPolicyAssignments!}
        customFlowAssignments={customFlowAssignments!}
        projects={projects}
      />
    </Stack>
  );
};

const PoliciesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledControlledSaveDiscardButtons = styled(ControlledSaveDiscardButtons)`
  position: sticky;
  bottom: 16px;
`;

export default observer(ProjectSettingsPolicies);
