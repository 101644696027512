import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Card, { CardHeader } from 'components/common/card';
import { links } from 'constants/external-links';
import WizardContent from 'components/common/wizard-content';
import useTemplateWizardNavigation from 'components/templates/templates-wizard/single-use-template-wizard-navigation.hook';
import styled from 'types/theme.types';
import TemplateWizardStepSettings from 'components/templates/templates-wizard/settings/template-wizard-step-settings';
import TemplateWizardStepVCS from 'components/templates/templates-wizard/vcs/template-wizard-step-vcs';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { TemplateWizardStep } from 'components/templates/templates-wizard/common/template-wizard.types';
import type { CustomGlobSwitch } from 'components/projects/settings/environment-discovery/use-environment-discovery-form';
import {
  DEFAULT_TF_FILE_FILTER_GLOB_PATTERN,
  DEFAULT_TF_GLOB_PATTERN,
  DEFAULT_TG_FILE_FILTER_GLOB_PATTERN,
  DEFAULT_TG_GLOB_PATTERN,
  DEFAULT_WF_FILE_FILTER_GLOB_PATTERN,
  DEFAULT_WF_GLOB_PATTERN
} from 'components/projects/settings/environment-discovery/use-environment-discovery-form';
import { useDeleteEnvironmentDiscovery, useUpsertEnvironmentDiscovery } from 'stores/rq/environment-discovery';
import type { Blueprint } from 'types/api.types';
import TemplateWizardStepMapping from 'components/templates/templates-wizard/template-wizard-step-mapping';
import { useModal } from 'hooks/modal.hooks';
import EnvironmentDiscoverySuccessModal from 'components/projects/settings/environment-discovery/environment-discovery-success-modal';
import EnvironmentDiscoveryWelcome from 'components/projects/settings/environment-discovery/environment-discovery-welcome';
import useEnvironmentDiscoveryWizardStepForms from 'components/projects/settings/environment-discovery/use-environment-discovery-wizard-step-form';
import { canonizeFormValues } from 'components/templates/templates-wizard/common/template-wizard-utils';
import { showHiddenFeatures } from 'constants/config';
import DiscoveredEnvironments from 'components/projects/settings/environment-discovery/environment-import/discovered-environments';
import ProjectSettingsEnvironmentDiscoveryAutoCreateSettings from 'components/projects/settings/environment-discovery/project-settings-environment-discovery-auto-create-settings';
import Button from 'components/common/button';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/common/modal';

export type UpsertEnvironmentDiscoveryMapping = {
  globPattern: string;
  environmentPlacement: BlueprintApi.EnvironmentPlacementStrategy;
  workspaceNaming: BlueprintApi.WorkspaceNaming;
  autoDeployByCustomGlob?: string;
  customGlobSwitch?: CustomGlobSwitch;
  rootPath?: string;
};

type SupportedTemplatesTypes = Extract<
  BlueprintApi.BlueprintType,
  'opentofu' | 'terraform' | 'terragrunt' | 'workflow'
>;
const supportedTemplates: SupportedTemplatesTypes[] = ['opentofu', 'terraform', 'terragrunt', 'workflow'];

const globPatternsPerIaC: Record<SupportedTemplatesTypes, string> = {
  opentofu: DEFAULT_TF_GLOB_PATTERN,
  terraform: DEFAULT_TF_GLOB_PATTERN,
  terragrunt: DEFAULT_TG_GLOB_PATTERN,
  workflow: DEFAULT_WF_GLOB_PATTERN
};

const autoDeployByCustomGlobPerIac: Record<SupportedTemplatesTypes, string> = {
  opentofu: DEFAULT_TF_FILE_FILTER_GLOB_PATTERN,
  terraform: DEFAULT_TF_FILE_FILTER_GLOB_PATTERN,
  terragrunt: DEFAULT_TG_FILE_FILTER_GLOB_PATTERN,
  workflow: DEFAULT_WF_FILE_FILTER_GLOB_PATTERN
};

type ProjectSettingsEnvironmentDiscoveryProps = {
  environmentDiscovery?: BlueprintApi.EnvironmentDiscovery | null;
};

const steps: TemplateWizardStep[] = ['settings', 'vcs', 'mapping'];

const ProjectSettingsEnvironmentDiscovery: React.FC<ProjectSettingsEnvironmentDiscoveryProps> = ({
  environmentDiscovery
}) => {
  const { mutateAsync: upsertEnvironmentDiscovery } = useUpsertEnvironmentDiscovery();
  const { showModal: showSuccessModal, hideModal: hideSuccessModal, isModalOpen: isSuccessModalOpen } = useModal(false);
  const [isSaving, setIsSaving] = useState(false);
  const { mutateAsync: deleteEnvironmentDiscovery } = useDeleteEnvironmentDiscovery();
  const isEdit = useMemo(() => Boolean(environmentDiscovery), [environmentDiscovery]);
  const [showWelcome, setShowWelcome] = React.useState(true);
  const environmentDiscoveryConfigId = environmentDiscovery?.id;
  const {
    isModalOpen: isEditDiscoveryModalOpen,
    showModal: showEditDiscoveryModal,
    hideModal: hideEditDiscoveryModal
  } = useModal();
  const { settingsStepForm, vcsStepForm, mappingForm } = useEnvironmentDiscoveryWizardStepForms({
    environmentDiscovery
  });

  const onSubmit = useCallback(async () => {
    try {
      setIsSaving(true);
      await upsertEnvironmentDiscovery({
        ...canonizeFormValues({
          ...settingsStepForm.getValues(),
          ...vcsStepForm.getValues()
        }),
        ...mappingForm.getValues(),
        autoDeployByCustomGlob:
          mappingForm.getValues('customGlobSwitch') === 'customGlob'
            ? mappingForm.getValues('autoDeployByCustomGlob')
            : null
      });

      if (!isEdit && !isSaving) {
        showSuccessModal();
      }
      // No-op submits to clean up the form state:
      settingsStepForm.submit();
      vcsStepForm.submit();
      mappingForm.submit();
    } catch (e) {
    } finally {
      setIsSaving(false);
      hideEditDiscoveryModal();
    }
  }, [
    upsertEnvironmentDiscovery,
    settingsStepForm,
    vcsStepForm,
    mappingForm,
    isEdit,
    isSaving,
    showSuccessModal,
    hideEditDiscoveryModal
  ]);

  const wizardData = useTemplateWizardNavigation({
    template: environmentDiscovery as unknown as Blueprint,
    steps: steps as TemplateWizardStep[],
    mappingStepForm: mappingForm,
    settingsStepForm,
    vcsStepForm,
    onSubmit
  });

  const [, setGlobPattern] = mappingForm.useManualField('globPattern');
  const [, setAutoDeployByCustomGlob] = mappingForm.useManualField('autoDeployByCustomGlob');

  const templateType = settingsStepForm.watch('type') as SupportedTemplatesTypes;

  useEffect(() => {
    if (!isEdit) {
      setGlobPattern(globPatternsPerIaC[templateType]);
      setAutoDeployByCustomGlob(autoDeployByCustomGlobPerIac[templateType]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateType, isEdit]);

  const resetWizard = useCallback(async () => {
    setShowWelcome(true);
    hideEditDiscoveryModal();
    wizardData.setCurrentStep(steps[0]); // covering edge case where the user delete and then immediately creates again and the steps are not reset.
  }, [hideEditDiscoveryModal, wizardData]);

  const onDeleteEnvironmentDiscovery = useCallback(async () => {
    await deleteEnvironmentDiscovery();
    resetWizard();
  }, [resetWizard, deleteEnvironmentDiscovery]);

  const stepsContent: Partial<Record<TemplateWizardStep, React.ReactNode>> = {
    settings: (
      <TemplateWizardStepSettings
        form={settingsStepForm}
        isEdit={isEdit}
        isSingleUse={true}
        templateTypes={supportedTemplates}
      />
    ),
    vcs: (
      <TemplateWizardStepVCS
        vcsForm={vcsStepForm}
        templateType={templateType}
        isNewTemplate={!isEdit}
        showLocationSection={false}
        excludedProviders={['Other']}
        CustomBlockingBackground={BlockingBackground}
      />
    ),
    mapping: <TemplateWizardStepMapping form={mappingForm} isEdit={isEdit} />
  };

  const discoverySettingsTitle = (isEdit || !showWelcome) && (
    <CardHeader
      titleId={'projects.settings.environment.discovery.title'}
      descriptionId={'projects.settings.environment.discovery.description'}
      link={links.docs.ENVIRONMENT_DISCOVERY.ROOT}></CardHeader>
  );

  const discoverySettingsEditWizard = (
    <WizardWrapper>
      <WizardContent
        isEditMode={!!(environmentDiscovery as unknown as Blueprint)}
        wizardData={wizardData}
        isSaving={isSaving}
        steps={steps}
        showCancelButton={!isEdit}
        deletePopupProps={
          isEdit
            ? {
                onDelete: onDeleteEnvironmentDiscovery,
                messagePrefix: 'projects.settings.environment.discovery.delete'
              }
            : null
        }>
        {Object.values(stepsContent).map((stepContent, index) => {
          return (
            <div key={index} data-e2e={`template-wizard-step-${index}`}>
              {stepContent}
            </div>
          );
        })}
      </WizardContent>
    </WizardWrapper>
  );

  return (
    <>
      {showHiddenFeatures() && environmentDiscoveryConfigId && (
        <>
          <Card
            title={
              <CardHeader
                titleId={'projects.settings.environment.discovery.discovered-environments.title'}
                descriptionId={'projects.settings.environment.discovery.discovered-environments.description'}
                link={links.docs.ENVIRONMENT_DISCOVERY.ROOT}
                headerAction={
                  <Button onClick={showEditDiscoveryModal} data-e2e="edit-discovery-settings-btn">
                    <FormattedMessage id="environment.discovery.edit.settings.btn" />
                  </Button>
                }
              />
            }>
            <DiscoveredEnvironments environmentDiscoveryConfigId={environmentDiscoveryConfigId} />
          </Card>
          {isEdit && (
            <Card
              data-e2e="project-settings-environment-discovery-settings-card"
              title={
                <CardHeader
                  titleId={'projects.settings.environment.discovery.discovered-environments.settings.title'}
                  descriptionId={'projects.settings.environment.discovery.discovered-environments.settings.description'}
                  link={links.docs.ENVIRONMENT_DISCOVERY.ROOT}></CardHeader>
              }>
              <ProjectSettingsEnvironmentDiscoveryAutoCreateSettings environmentDiscovery={environmentDiscovery} />
            </Card>
          )}
        </>
      )}
      <StyledModal
        open={isEditDiscoveryModalOpen}
        centered
        onCancel={hideEditDiscoveryModal}
        title={discoverySettingsTitle}
        footer={null}>
        {discoverySettingsEditWizard}
      </StyledModal>
      {(!showHiddenFeatures() || !isEdit) && (
        <Card title={discoverySettingsTitle}>
          {
            <div>
              {showWelcome && !isEdit ? (
                <EnvironmentDiscoveryWelcome onClickGetStarted={() => setShowWelcome(false)} />
              ) : (
                discoverySettingsEditWizard
              )}
            </div>
          }
        </Card>
      )}
      <EnvironmentDiscoverySuccessModal
        isOpen={isSuccessModalOpen}
        hideModal={hideSuccessModal}
        globPattern={mappingForm.getValues('globPattern')}></EnvironmentDiscoverySuccessModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  width: 900px !important;
  height: 580px;
  overflow-y: scroll;
`;

const WizardWrapper = styled.div`
  margin-top: 1em;
`;

export const BlockingBackground = styled.div`
  opacity: 0;
`;

export default ProjectSettingsEnvironmentDiscovery;
