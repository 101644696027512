import React, { type ReactNode, useEffect } from 'react';
import LoginLayout from 'components/login/login-layout';
import LoginLeftContent from 'components/login/login-left-content';
import styled from 'types/theme.types';
import { Navigate, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useLastLoginMethod, useLoginLock } from 'hooks/use-login-lock';
import LockContainer from 'components/login/lock-container';
import Link from 'components/common/link';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/common/loader';

export const SSO_LOGIN_PAGE_TITLE_ID = 'login.sso.login';

const SSOLoginPage: React.FunctionComponent = () => {
  const { redirectTo, isAuthenticated, isCallbackPage } = useLoginLock(true);

  if (isCallbackPage()) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Navigate replace to={redirectTo} />;
  }

  return <Content />;
};

const Content: React.FC = () => {
  const navigate = useNavigate();
  const { setUsedSSO, clearUsedSSO } = useLastLoginMethod();

  useEffect(() => {
    setUsedSSO();
  }, [setUsedSSO]);

  const backToLoginMessage = (
    <BackToLoginMessage
      data-e2e="sso-login-go-back"
      id="login.sso.go.back"
      values={{
        a: (text: string | ReactNode) => (
          <Link
            messageId={text}
            plainText
            onClick={() => {
              clearUsedSSO();
              navigate('/login');
            }}
          />
        )
      }}
    />
  );

  return (
    <LoginLayout
      pageTitleId={SSO_LOGIN_PAGE_TITLE_ID}
      leftContent={<LoginLeftContent />}
      rightContent={
        <>
          <LockContainer />
          {backToLoginMessage}
        </>
      }
    />
  );
};

const BackToLoginMessage = styled(FormattedMessage)`
  font-size: 17px;
`;

export default observer(SSOLoginPage);
