import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import type { EnvironmentDiscoveryMappingFormReturnType } from 'components/projects/settings/environment-discovery/use-environment-discovery-form';
import styled from 'types/theme.types';
import { Col, Row } from 'components/common/grid';
import { Section } from 'components/common/section';
import ControlledRadioGroup from 'components/common/form-controlled/controlled-radio-group';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { Bold } from 'components/common/modal';
import { Text } from 'components/common/form-components';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import RadioCustomGlob from 'components/environments/triggers/environment-cd-settings/radio-custom-glob';
import ControlledTextInput from 'components/common/form-controlled/controlled-text-input';
import WorkspaceNamingRadioButtons from 'components/templates/templates-wizard/settings/workspace-naming';
import { showHiddenFeatures } from 'constants/config';

const environmentPlacementValuesMap = {
  topProject: {
    environmentName: <Bold>{'us-east-1/RDS/AwesomeDB'}</Bold>,
    projectName: <Bold>{'prod'}</Bold>,
    projectType: 'Project'
  },
  existingSubProject: {
    environmentName: <Bold>{'RDS/AwesomeDB'}</Bold>,
    projectName: <Bold>{'us-east-1'}</Bold>,
    projectType: 'Sub-Project'
  }
};

const TemplateWizardStepMapping: React.FunctionComponent<{
  form: EnvironmentDiscoveryMappingFormReturnType;
  isEdit?: boolean;
}> = ({ form, isEdit }) => {
  const environmentPlacementValues: BlueprintApi.EnvironmentPlacementStrategy[] = ['topProject', 'existingSubProject'];

  const environmentPlacementRadioButtons = environmentPlacementValues.map(option => ({
    content: <FormattedMessage id={`project.settings.environment.discovery.environment.placement.${option}.value`} />,
    value: option
  }));

  const currentExplanation = useMemo(() => environmentPlacementValuesMap[form.watch('environmentPlacement')], [form]);
  return (
    <Container>
      <Row>
        <Col span={9}>
          <ControlledTextInput
            label={{ id: 'environment.discovery.glob.label' }}
            info={{ id: 'environment.discovery.glob.info' }}
            errorSide={'bottom'}
            form={form}
            name={'globPattern'}
            autoSize={{ minRows: 1, maxRows: 1 }}
            data-e2e={'glob-pattern-text-input'}
            value={form.getValues('globPattern')}
          />
        </Col>
        <Col span={9} offset={1}>
          <ControlledTextInput
            label={{ id: 'environment.discovery.rootpath.label' }}
            info={{ id: 'environment.discovery.rootpath.info' }}
            form={form}
            name={'rootPath'}
            autoSize={{ minRows: 1, maxRows: 1 }}
            data-e2e={'root-path-text-input'}
          />
        </Col>
      </Row>
      <Row>
        <NoPaddingSection titleId={'project.settings.environment.discovery.mapping.runOn.title'}>
          <Col span={24}>
            <RadioGroupContainer
              form={form as unknown as UseFormReturnType}
              name={'customGlobSwitch'}
              items={[
                {
                  content: <Text id={'environment.auto-deploy.settings.redeploy-by-template-folder'} />,
                  value: 'templateFolder'
                },
                {
                  content: (
                    <RadioCustomGlob
                      form={form}
                      showTextInput={form.getValues('customGlobSwitch') === 'customGlob'}
                      value={form.getValues('autoDeployByCustomGlob')}
                    />
                  ),
                  value: 'customGlob'
                }
              ]}
            />
          </Col>
        </NoPaddingSection>
      </Row>
      <NoPaddingSection titleId={'project.settings.environment.discovery.environment.placement.title'}>
        <HighRow>
          <Col span={10}>
            <RadioGroupContainer
              form={form as unknown as UseFormReturnType}
              name={'environmentPlacement'}
              items={environmentPlacementRadioButtons}
            />
          </Col>
          <Col span={10}>
            <Subtitle>
              <FormattedMessage
                id={`project.settings.environment.discovery.environment.placement.subtitle`}
                values={{
                  match: <Bold>{'prod/us-east-1/RDS/AwesomeDB'}</Bold>,
                  structure: <Bold>{'prod/us-east-1'}</Bold>,
                  environmentName: currentExplanation.environmentName,
                  projectName: currentExplanation.projectName,
                  projectType: currentExplanation.projectType
                }}
              />
            </Subtitle>
          </Col>
        </HighRow>
      </NoPaddingSection>
      {!showHiddenFeatures() && (
        <NoPaddingSection titleId={'project.settings.environment.discovery.workspace.naming.title'}>
          <HighRow>
            <Col span={10}>
              <StyledWorkspaceNamingRadioButtons form={form as unknown as UseFormReturnType} />
            </Col>
          </HighRow>
        </NoPaddingSection>
      )}
    </Container>
  );
};

const HighRow = styled(Row)`
  min-height: 2em;
`;

const Container = styled.div`
  min-height: 24em;
`;

const RadioGroupContainer = styled(ControlledRadioGroup)`
  margin-top: 3em;
`;

const StyledWorkspaceNamingRadioButtons = styled(WorkspaceNamingRadioButtons)`
  margin-top: 3em;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.subtitleGray};
`;

const NoPaddingSection = styled(Section)`
  margin-top: 1em;
`;

export default TemplateWizardStepMapping;
