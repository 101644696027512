import React from 'react';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import useEnvironmentDiscoveryAutoCreateSettingsForm from 'components/projects/settings/environment-discovery/use-environment-discovery-auto-create-settings-form';
import WorkspaceNamingRadioButtons from 'components/templates/templates-wizard/settings/workspace-naming';
import styled from 'types/theme.types';
import { Section } from 'components/common/section';
import ControlledBigCheckbox from 'components/common/form-controlled/controlled-big-checkbox';
import { Text } from 'components/common/form-components';
import SaveDiscardButtons from 'components/common/save-discard-buttons';

type ProjectSettingsEnvironmentDiscoveryProps = {
  environmentDiscovery: BlueprintApi.EnvironmentDiscovery;
};
const ProjectSettingsEnvironmentDiscoveryAutoCreateSettings: React.FC<ProjectSettingsEnvironmentDiscoveryProps> = ({
  environmentDiscovery
}) => {
  const form = useEnvironmentDiscoveryAutoCreateSettingsForm({
    workspaceNaming: environmentDiscovery?.workspaceNaming,
    createNewEnvironmentsFromPullRequests: environmentDiscovery?.createNewEnvironmentsFromPullRequests
  });

  return (
    <>
      <SectionWithMargin titleId={'project.settings.environment.discovery.create.from.pull.requests.title'}>
        <ControlledBigCheckbox
          form={form}
          name={'createNewEnvironmentsFromPullRequests'}
          data-e2e={'createNewEnvironmentsFromPullRequests-checkbox'}>
          <Text id={'project.settings.environment.discovery.create.from.pull.requests.checkbox'} />
        </ControlledBigCheckbox>
      </SectionWithMargin>
      <SectionWithMargin titleId={'project.settings.environment.discovery.workspace.naming.title'}>
        <WorkspaceNamingRadioButtons
          form={form}
          disabled={!form.getValues()['createNewEnvironmentsFromPullRequests']}
        />
      </SectionWithMargin>
      <SaveDiscardButtons
        discardButton={{
          disabled: !form.isDirty || form.isSubmitting,
          onClick: form.discardChanges,
          'data-e2e': 'discard-changes-environment-discovery-settings'
        }}
        saveButton={{
          isLoading: form.isSubmitting,
          disabled: !form.isDirty || !form.canSave || form.isSubmitting,
          onClick: form.submit,
          'data-e2e': 'save-environment-discovery-settings'
        }}
      />
    </>
  );
};

const SectionWithMargin = styled(Section)`
  margin-top: 1em;
`;
export default ProjectSettingsEnvironmentDiscoveryAutoCreateSettings;
