import type { GitProviderTypes } from 'types/api.types';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import { FormattedMessage } from 'react-intl';
import React, { type ReactNode } from 'react';
import styled from 'types/theme.types';

interface Props {
  gitProvider: GitProviderTypes;
  onClick: () => void;
}

export const VcsIntegrationMessage: React.FC<Props> = ({ gitProvider, onClick }) => {
  const providerText: Lowercase<GitProviderTypes> = gitProvider.toLowerCase() as Lowercase<GitProviderTypes>;

  const startVcsIntegrationLink = (text: string | ReactNode) => <LinkSpan onClick={onClick}>{text}</LinkSpan>;
  const vcsSpecificValues =
    providerText === 'azuredevops'
      ? {
          // setLink returns a value override for <a> - reassigning it to docs
          docs: setLink(links.docs.TEMPLATES.AZURE_DEVOPS).a
        }
      : {};

  return (
    <StyledFormattedMessage>
      <FormattedMessage
        data-e2e="new-vcs-integration-message"
        id={`templates.add.new.${providerText}.app.explanation`}
        values={{
          a: startVcsIntegrationLink,
          ...vcsSpecificValues
        }}
      />
    </StyledFormattedMessage>
  );
};

const StyledFormattedMessage = styled.div`
  width: 49%;
  text-align: justify;
`;

const LinkSpan = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.primaryGreen};
`;
