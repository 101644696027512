import React, { useCallback, useRef, useState } from 'react';
import styled from 'types/theme.types';
import dayjs from 'types/dayjs.types';
import Link from 'components/common/link';
import Tooltip from 'components/common/tooltip';
import sortedUniqBy from 'lodash/sortedUniqBy';
import type { TableColumnType } from 'antd';

export function sorterByStringProp<T>(propSelector: (item: T) => string | undefined) {
  return (row1: T, row2: T) => {
    const record1 = propSelector(row1) ?? '';
    const record2 = propSelector(row2) ?? '';
    return record1.localeCompare(record2);
  };
}

export function sorterByDateProp<T>(propSelector: (item: T) => string | Date) {
  return (row1: T, row2: T) => {
    let record1 = dayjs(propSelector(row1));
    record1 = record1.isValid() ? record1 : dayjs(new Date(0));
    let record2 = dayjs(propSelector(row2));
    record2 = record2.isValid() ? record2 : dayjs(new Date(0));
    return record1.diff(record2);
  };
}

export function sorterByNumberProp<T>(propSelector: (item: T) => number) {
  return (row1: T, row2: T) => {
    const record1 = propSelector(row1) ?? -Number.MAX_VALUE;
    const record2 = propSelector(row2) ?? -Number.MAX_VALUE;
    return record1 - record2;
  };
}

export function multipleMenuFilter<T>(
  items: T[],
  propSelector: (item: T) => T[keyof T],
  displayText?: (item: T) => string
): Partial<TableColumnType<T>> {
  return {
    filterSearch: true,
    filterMultiple: true,
    filterMode: 'menu',
    filters: sortedUniqBy(items, propSelector).map(row => {
      const propValue = `${propSelector(row)}`;
      return { text: displayText?.(row) ?? propValue, value: propValue };
    }),
    onFilter: (value, row) => propSelector(row) === value.toString()
  };
}

export const Field: React.FC<{
  url: string;
  text: string;
  fullTextOnHover?: boolean;
}> = ({ url, text, fullTextOnHover }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const onOpenChange = useCallback((open: boolean) => {
    // Only open the tooltip if the text is truncated
    setTooltipOpen(open && !!ref.current && ref.current.offsetWidth < ref.current.scrollWidth);
  }, []);

  const field = (
    <EllipsisContainer ref={ref}>
      <Link url={url} plainText messageId={text} />
    </EllipsisContainer>
  );

  return fullTextOnHover ? (
    <Tooltip placement="topLeft" title={text} open={tooltipOpen} onOpenChange={onOpenChange}>
      {field}
    </Tooltip>
  ) : (
    field
  );
};

const EllipsisContainer = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
