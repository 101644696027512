import styled from '@emotion/styled';
import Button from 'components/common/button';
import {
  BigGreenTitle,
  PurpleSubtitle
} from 'components/projects/settings/environment-discovery/environment-discovery-typography';
import { FormattedMessage } from 'react-intl';
import EnvironmentDiscoveryWelcomeChartOld from 'components/projects/settings/environment-discovery/environment-discovery-welcome-chart-old';
import { showHiddenFeatures } from 'constants/config';
import EnvironmentDiscoveryWelcomeChart from 'components/projects/settings/environment-discovery/environment-discovery-welcome-chart';

interface EnvironmentDiscoveryWelcomeProps {
  onClickGetStarted: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const EnvironmentDiscoveryWelcome: React.FC<EnvironmentDiscoveryWelcomeProps> = ({ onClickGetStarted }) => {
  return (
    <Container>
      <BigGreenTitle>
        <FormattedMessage
          id={showHiddenFeatures() ? 'environment.discovery.welcome.title' : 'environment.discovery.welcome.title-old'}
        />
      </BigGreenTitle>
      <PurpleSubtitle>
        <FormattedMessage
          id={
            showHiddenFeatures()
              ? 'environment.discovery.welcome.subtitle'
              : 'environment.discovery.welcome.subtitle-old'
          }
        />
      </PurpleSubtitle>
      {showHiddenFeatures() ? <EnvironmentDiscoveryWelcomeChart /> : <EnvironmentDiscoveryWelcomeChartOld />}
      <StyledButton type="primary" data-e2e="get-started-button" onClick={onClickGetStarted} id="get-started-button">
        <FormattedMessage id="onboarding.welcome.get-started" />
      </StyledButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
`;

const StyledButton = styled(Button)`
  width: 170px;
  height: 30px;
  font-style: normal;
  font-weight: 600;
`;

export default EnvironmentDiscoveryWelcome;
