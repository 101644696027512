import useStores from 'hooks/use-stores.hooks';
import { useCallback, useEffect, useMemo } from 'react';
import BrowserStorage from 'services/BrowserStorage';

export const useLoginLock = (isSSO: boolean) => {
  const { authStore } = useStores();

  useEffect(() => {
    authStore.isSSO = isSSO;
    authStore.show();
    return () => {
      authStore.hide();
      authStore.isSSO = false;
    };
  }, [isSSO, authStore]);

  const redirectTo = authStore.getRedirectToUrl();

  return {
    isAuthenticated: authStore.isAuthenticated,
    redirectTo,
    isCallbackPage: authStore.isCallbackPage
  };
};

const usedSSOKey = 'usedSSO';

export const useLastLoginMethod = () => {
  const usedSSO = useMemo(() => BrowserStorage.getItem(usedSSOKey), []);

  const setUsedSSO = useCallback(() => {
    BrowserStorage.setItem(usedSSOKey, true);
  }, []);

  const clearUsedSSO = useCallback(() => {
    BrowserStorage.removeItem(usedSSOKey);
  }, []);

  return { usedSSO, setUsedSSO, clearUsedSSO };
};
