import type { HttpClient } from 'services/api-client/client';
import { returnData } from 'services/api-client/client';
import type { AgentSettingsApi } from '@env0/agent-settings-service/api';

export default (client: HttpClient) => ({
  getValuesFile: (agentKey: string) => {
    return client.get<AgentSettingsApi.GetAgentValues.Response>(`agents/${agentKey}/values`).then(returnData);
  },
  listAgents: (organizationId: string) => {
    const searchParams = new URLSearchParams({ organizationId });
    return client.get<AgentSettingsApi.ListAgents.Response>(`agents?${searchParams.toString()}`).then(returnData);
  },
  getProjectsAssignments: (organizationId: string) => {
    const searchParams = new URLSearchParams({ organizationId });
    return client
      .get<AgentSettingsApi.GetAgentAssignments.Response>(`agents/projects-assignments?${searchParams.toString()}`, {
        hideNotification: true
      })
      .then(returnData);
  },
  assignAgentsToProjects: (organizationId: string, payload: AgentSettingsApi.AssignAgents.Request.Body) => {
    const searchParams = new URLSearchParams({ organizationId });
    return client
      .post<AgentSettingsApi.AssignAgents.Response>(`agents/projects-assignments?${searchParams.toString()}`, payload)
      .then(returnData);
  },
  getDockerAgentDotEnv: (agentKey: string): Promise<string> => {
    return client.get<AgentSettingsApi.GetAgentValues.Response>(`agents/${agentKey}/dotenv`).then(returnData);
  }
});
