import { messagePrefix } from 'components/projects/settings/policies/project-settings-policies';
import React, { type ReactNode, useState } from 'react';
import { Section } from 'components/common/section';
import { FormattedMessage } from 'react-intl';
import { setLink } from 'components/common/link';
import { links } from 'constants/external-links';
import styled from 'types/theme.types';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import type { PoliciesFormSchema } from 'components/projects/settings/policies/use-policies-form.hook';
import { useModal } from 'hooks/modal.hooks';
import type { VcsTemplateModalProps } from 'components/templates/vcs-template-modal';
import { VcsTemplateModal } from 'components/templates/vcs-template-modal';
import type { ConfigurationTemplateWithoutName, ConfigurationTemplateType, Project } from 'types/api.types';
import type { BlueprintApi } from '@env0/blueprint-service/api';

import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

import { Warning } from 'components/common/warning';
import ConfigurationTemplatesTable, {
  type ConfigurationTemplateAssignment
} from 'components/policies/configuration-templates-table';

interface Props {
  form: UseFormReturnType<PoliciesFormSchema>;
  type: ConfigurationTemplateType;
  children?: ReactNode;
  projectId: string;
  inheritedAssignments?: (BlueprintApi.ApprovalPolicyTemplateWithScope | BlueprintApi.RichCustomFlowAssigment)[];
  assignmentsFieldName: 'approvalPolicyAssignments' | 'customFlowAssignments';
  /*
  Approval policies assignments has the blueprint in a field called blueprint.
  Custom flows assignments has the blueprint in a field called customFlowTemplate
  */
  blueprintFieldName: BlueprintApi.ConfigurationTemplateBlueprintField;
  reorder?: boolean;
}

const linksMap: Record<ConfigurationTemplateType, string> = {
  'custom-flow': links.docs.TEMPLATES.PROJECT_LEVEL_CUSTOM_FLOWS,
  'approval-policy': links.docs.POLICIES.APPROVAL_POLICIES
};

export const ConfigurationTemplateSettings: React.FC<Props> = ({
  form,
  type,
  children,
  projectId,
  inheritedAssignments,
  assignmentsFieldName,
  blueprintFieldName,
  reorder
}) => {
  const { hideModal, isModalOpen, showModal } = useModal();

  const [directAssignments, setDirectAssignments] =
    form.useManualField<ConfigurationTemplateAssignment[]>(assignmentsFieldName);

  const [currentConfigurationTemplateIndex, setCurrentConfigurationTemplateIndex] = useState<number | null>(null);

  const onVcsModalSave = (configurationTemplate: ConfigurationTemplateWithoutName) => {
    if (isNull(currentConfigurationTemplateIndex)) {
      setDirectAssignments([
        ...directAssignments,
        {
          [blueprintFieldName]: configurationTemplate,
          scope: 'PROJECT',
          scopeId: projectId
        } as ConfigurationTemplateAssignment
      ]);
    } else if (
      !isEqual(
        configurationTemplate,
        (directAssignments[currentConfigurationTemplateIndex] as any)?.[blueprintFieldName]
      )
    ) {
      setDirectAssignments([
        ...directAssignments.map((assignment: ConfigurationTemplateAssignment, index: number) => {
          if (index !== currentConfigurationTemplateIndex) return assignment;
          return {
            ...assignment,
            [blueprintFieldName]: { ...(assignment as any)[blueprintFieldName], ...configurationTemplate }
          };
        })
      ]);
      setCurrentConfigurationTemplateIndex(null);
    }
  };

  const onVcsModalHideOrCancel = () => {
    setCurrentConfigurationTemplateIndex(null);
    hideModal();
  };

  const onModifyConfigurationTemplate = (templateToModifyFromIndex: number) => {
    setCurrentConfigurationTemplateIndex(templateToModifyFromIndex);
    showModal();
  };

  const getVcsModalBlueprint = (): VcsTemplateModalProps['blueprint'] | undefined => {
    if (isNull(currentConfigurationTemplateIndex)) return;
    return (directAssignments[currentConfigurationTemplateIndex] as any)[blueprintFieldName];
  };

  return (
    <Section
      title={
        <span>
          <FormattedMessage id={`${messagePrefix}.title.${type}`} />
          <>
            &nbsp;
            <Warning
              content={
                <FormattedMessage
                  id={`${messagePrefix}.title.${type}.warning.tooltip`}
                  values={setLink(linksMap[type])}
                />
              }
            />
          </>
        </span>
      }>
      <ConfigurationTemplatesTable
        dataE2ePrefix={type}
        blueprintFieldName={blueprintFieldName}
        assignments={directAssignments}
        inheritedAssignments={inheritedAssignments}
        onReorder={reorder ? setDirectAssignments : undefined}
        onCreate={showModal}
        onUpdate={({ index: assignmentFormIndex }) => onModifyConfigurationTemplate(assignmentFormIndex)}
        onDelete={({ index: assignmentFormIndex }) => {
          setDirectAssignments(directAssignments.filter((_: any, index: number) => index !== assignmentFormIndex));
        }}
      />

      {isModalOpen && (
        <VcsTemplateModal
          data-e2e={`${type}-vcs-modal`}
          type={type}
          hideModal={onVcsModalHideOrCancel}
          open={isModalOpen}
          onSave={onVcsModalSave}
          blueprint={getVcsModalBlueprint()}
        />
      )}
      <Space />
      {children}
    </Section>
  );
};

const Space = styled.div`
  margin-bottom: 1em;
`;
