import { useCallback } from 'react';
import useVcsStepForm from 'components/templates/templates-wizard/step-forms/vcs-step-form.hook';
import useSettingsStepForm from 'components/templates/templates-wizard/step-forms/settings-step-form.hook';
import useEnvironmentDiscoveryMappingForm from 'components/projects/settings/environment-discovery/use-environment-discovery-form';
import { commonSettingsStepSchema } from 'components/templates/templates-wizard/common/template-wizard-schemas';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import type { Blueprint } from 'types/api.types';
import type { TemplateSettingsStepCommonValues } from 'components/templates/templates-wizard/common/template-wizard.types';
import { getFormInitialValues } from 'components/templates/templates-wizard/common/template-wizard-utils';
import type { AnyObjectSchema } from 'yup';

type UseEnvironmentDiscoveryWizardStepFormProps = {
  environmentDiscovery?: BlueprintApi.EnvironmentDiscovery | null;
};

const useEnvironmentDiscoveryWizardStepForms = ({
  environmentDiscovery
}: UseEnvironmentDiscoveryWizardStepFormProps) => {
  const readonly = false;

  const getInitialValues = useCallback(
    (schema: AnyObjectSchema) => getFormInitialValues(schema, environmentDiscovery as unknown as Blueprint),
    [environmentDiscovery]
  );

  const settingsStepForm = useSettingsStepForm<TemplateSettingsStepCommonValues>({
    readonly,
    template: environmentDiscovery as unknown as Blueprint,
    settingsSchema: commonSettingsStepSchema,
    getInitialValues
  });

  const iacType = settingsStepForm.watch('type');

  const vcsStepForm = useVcsStepForm({ iacType, readonly, template: environmentDiscovery as unknown as Blueprint });
  const mappingForm = useEnvironmentDiscoveryMappingForm({
    globPattern: environmentDiscovery?.globPattern,
    environmentPlacement: environmentDiscovery?.environmentPlacement,
    workspaceNaming: environmentDiscovery?.workspaceNaming,
    autoDeployByCustomGlob: environmentDiscovery?.autoDeployByCustomGlob,
    rootPath: environmentDiscovery?.rootPath
  });

  return { settingsStepForm, vcsStepForm, mappingForm };
};

export default useEnvironmentDiscoveryWizardStepForms;
