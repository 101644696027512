import React from 'react';
import styled from '@emotion/styled';
import { Popover, Steps, type StepsProps } from 'antd';
import type { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import Link from 'components/common/link';
import { DownOutlined, LoadingOutlined, SyncOutlined } from '@ant-design/icons';
import type { EnvironmentImportApi } from '@env0/environment-import-service/api';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'components/common/tag';
import type { Entries } from 'type-fest';

type StatusTagProps = {
  status: Exclude<EnvironmentImportApi.ImportStatus, 'discovered'>;
  discoveredEnvironment: EnvironmentImportApi.DiscoveredEnvironment;
};

type StepConfig = {
  title: EmotionJSX.Element;
  message: string;
};

const StyledSyncOutlined = styled(SyncOutlined)`
  padding-right: 5px;
`;

const stepsConfig: Record<keyof EnvironmentImportApi.ImportSteps, StepConfig> = {
  'empty-plan': {
    title: (
      <>
        <StyledSyncOutlined />
        <FormattedMessage id={`environment.import.step.empty-plan.title`} />
      </>
    ),
    message: 'environment.import.step.empty-plan.description'
  }
};

export const ImportStatusTag: React.FunctionComponent<StatusTagProps> = ({ status, discoveredEnvironment }) => {
  const { createdByPullRequest, targetProjectId, id, environmentId, steps } = discoveredEnvironment;
  const importDeploymentUrl = `/p/${targetProjectId}/environments/${environmentId}/deployments/${steps?.['empty-plan']?.deploymentLogId}`;
  const tagColor = createdByPullRequest
    ? 'gray'
    : status === 'in-progress'
    ? 'blue'
    : status === 'imported'
    ? 'cyan'
    : 'red';
  const stepsStatus: StepsProps['status'] =
    steps?.['empty-plan']?.status === 'success'
      ? 'finish'
      : steps?.['empty-plan']?.status === 'error'
      ? 'error'
      : 'process';

  const content = (
    <div>
      <StyledSteps
        data-e2e="import-steps-status"
        direction="vertical"
        status={stepsStatus}
        items={(Object.entries(discoveredEnvironment.steps!) as Entries<EnvironmentImportApi.ImportSteps>).map(
          ([stepName, { status }]) => ({
            title: stepsConfig[stepName].title,
            icon: status === 'in-progress' && <LoadingOutlined data-e2e="import-in-progress-icon" />,
            description: (
              <>
                <FormattedMessage id={stepsConfig[stepName].message} />
                <Link url={importDeploymentUrl} data-e2e="empty-plan-deployment-link">
                  <FormattedMessage id={`environment.import.step.${stepName}.link`} />
                </Link>
              </>
            )
          })
        )}
      />
    </div>
  );

  return (
    <StyledContent>
      <Popover content={content} placement="bottomLeft">
        <StyledTag data-e2e="import-status-tag" color={tagColor}>
          <StatusContentWrapper>
            <FormattedMessage
              id={`projects.settings.environment.discovery.discovered-environments.status.${
                createdByPullRequest ? 'created-by-pull-request' : status
              }`}
            />
            {!createdByPullRequest && <DownOutlined />}
          </StatusContentWrapper>
        </StyledTag>
      </Popover>
    </StyledContent>
  );
};

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const StyledSteps = styled(Steps)`
  width: 400px;
`;

const StatusContentWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledTag = styled(Tag)`
  margin-inline-end: 0;
`;
