import React from 'react';
import { links } from 'constants/external-links';
import Link from 'components/common/link';
import useStores from 'hooks/use-stores.hooks';
import type { CredentialsInputProps } from 'components/organizations/credentials/credential-modal';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';
import { useIntl } from 'react-intl';

const KubeconfigCredentialInputs = ({ form }: CredentialsInputProps) => {
  const { organizationsStore } = useStores();
  const { isCurrentOrganizationSelfHosted } = organizationsStore;
  const intl = useIntl();

  const docsLink = links.docs.DEPLOYMENT_CREDENTIALS.K8S_KUBECONFIG_AUTH;

  return (
    <>
      <Link messageId="settings.credentials.k8s.credentials.explanation" url={docsLink} />
      <ControlledTextArea
        key={'kubeConfig'}
        label={{ id: 'settings.credentials.k8s.kubeConfig' }}
        name={`value.kubeConfig`}
        form={form}
        placeholder={isCurrentOrganizationSelfHosted ? intl.formatMessage({ id: 'secretPlaceholder' }) : ''}
        autoSize={{ minRows: 3, maxRows: 10 }}
      />
    </>
  );
};

export default KubeconfigCredentialInputs;
