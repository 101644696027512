import React from 'react';
import styled from 'types/theme.types';
import { FormattedMessage } from 'react-intl';

import StyledInfo from 'components/common/info';
import useIsMobile from 'hooks/use-is-mobile';

const LABEL_MARGIN_BOTTOM = '10px';
const DEFAULT_FIELD_MARGIN_RIGHT = 30;

const Title = styled.div`
  h1 {
    color: ${({ theme }) => theme.primaryBlue};
    font-size: 24px;
    font-weight: 500;
  }
`;

const Explanation = styled.div`
  color: ${({ theme }) => theme.primaryBlack};
  font-size: 13px;
  font-weight: 400;
`;

const FieldRow = styled.div<{ addMargin?: boolean; zeroTopMargin?: boolean; zeroBottomMargin?: boolean }>`
  display: flex;
  width: 100%;
  ${({ addMargin }) => addMargin && 'margin-right: ' + DEFAULT_FIELD_MARGIN_RIGHT + 'px'}
  ${({ zeroTopMargin }) => zeroTopMargin && 'margin-top: 0px;'}
  ${({ zeroBottomMargin }) => zeroBottomMargin && 'margin-bottom: 0px;'}
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const FieldAndLabel = styled.div<{ width?: number; space?: number; height?: number }>`
  width: ${({ width }) => (width ? width + '%' : '100%')};
  height: ${({ height }) => (height ? height + '%' : 'auto')};
  display: flex;
  flex-direction: column;

  textarea {
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &:not(:first-of-type) {
    margin-left: ${props => (props.hasOwnProperty('space') ? props.space : DEFAULT_FIELD_MARGIN_RIGHT)}px;
  }
`;

const StyledLabel = styled.label`
  color: ${({ theme }) => theme.primaryBlue};
  font-weight: 600;
  display: block;
  margin-bottom: ${LABEL_MARGIN_BOTTOM};
`;

const OptionalLabel = styled.span`
  color: ${({ theme }) => theme.textMetalBlue};
`;

const Label = ({
  id,
  values,
  optional,
  mandatory,
  size,
  ...props
}: {
  id: string;
  values?: Record<string, any>;
  optional?: boolean;
  mandatory?: boolean;
  size?: number;
}) => (
  <StyledLabel {...props}>
    <div style={{ display: 'flex', fontSize: size }}>
      <FormattedMessage id={id} values={values} />
      {mandatory && <div data-e2e={`${id}.mandatory`}>&nbsp;*</div>}
    </div>
    {optional && (
      <OptionalLabel>
        <FormattedMessage id="templates.add.new.optional" />
      </OptionalLabel>
    )}
  </StyledLabel>
);

export interface TextProps {
  id: string;
  values?: Record<string, any>;
  mandatory?: boolean;
  size?: number;
  info?: InfoProps;
  'data-e2e'?: string;
}

export interface InfoProps {
  id?: string;
  values?: Record<string, any>;
}

const Text = ({ id, values, mandatory, size, info, ...otherProps }: TextProps) => (
  <StyledText fontSize={size} data-e2e={otherProps['data-e2e'] ?? undefined}>
    <FormattedMessage id={id} values={values} />
    {mandatory && <div>&nbsp;*</div>}
    {info && (
      <Info
        information={<FormattedMessage {...info} />}
        onClick={e => {
          e.preventDefault();
        }}
      />
    )}
  </StyledText>
);

const DesktopText = (props: TextProps) => {
  const { isDesktop } = useIsMobile();
  const { info, ...restProps } = props;
  return <Text {...restProps} info={isDesktop ? info : undefined} />;
};

const StyledText = styled.span<{ fontSize?: number }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize + 'px' : undefined)};
`;

const LabelAndIcon = styled.div`
  display: flex;
  align-items: center;
`;

const Info = ({
  information,
  withoutStyle,
  alignTop = true,
  className,
  onClick
}: {
  information: string | React.ReactNode;
  withoutStyle?: boolean;
  alignTop?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const iconStyle = {
    marginLeft: '5px',
    lineHeight: '10px',
    ...(alignTop && { marginBottom: 'auto' })
  };

  return (
    <StyledInfo
      onClick={onClick}
      className={className}
      tooltip={<TooltipMessage>{information}</TooltipMessage>}
      style={withoutStyle ? undefined : iconStyle}
    />
  );
};

const TooltipMessage = styled.div`
  white-space: pre-line;
`;

const ErrorMessage = styled.div<{ errorShouldNotTakeSpace?: boolean }>`
  margin: 2px;
  color: ${({ theme }) => theme.errorRed};
  font-size: 10px;
  height: 15px;

  ${({ errorShouldNotTakeSpace }) => errorShouldNotTakeSpace && 'position: absolute; top: 100%;'}
`;

const CommonErrorMessage = styled.div`
  height: 21px;
  font-size: 14px;
  color: ${({ theme }) => theme.errorRed};
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: repeat(1fr, 3);
  grid-row-gap: 9px;
  padding: 0 8px 0 8px;
`;

const Field = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  grid-row-gap: 2px;
  color: ${({ theme }) => theme.primaryBlue};
  font-size: 13px;
  font-weight: 500;
`;

const EllipsisContainer = styled.div<{ maxWidth?: number }>`
  max-width: ${({ maxWidth }) => maxWidth + 'px'};
  width: max-content;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export {
  Title,
  Explanation,
  ErrorMessage,
  CommonErrorMessage,
  Info,
  LabelAndIcon,
  Label,
  OptionalLabel,
  StyledLabel,
  FieldAndLabel,
  Row,
  FieldRow,
  Container,
  Field,
  EllipsisContainer,
  Text,
  DesktopText
};
