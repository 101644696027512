export const DEFAULT_SHADOW = '-1px 6px 30px 0 rgba(204, 204, 204, 0.5)';

export const TOP_BAR_HEIGHT = '60px';
export const BREADCRUMB_BAR_HEIGHT = '40px';

const theme = {
  primaryWhite: '#FFFFFF',
  offWhite: '#FDFDFD',
  primaryBlack: '#142C48',
  secondaryBlack: '#0E2133',
  primaryGray: '#EBEFF2',
  secondaryGray: '#B7CBD6',
  lightGray: '#CED8E0',
  subtitleGray: '#4D4D4D',
  subtitleDarkBlue: '#290A89',
  sidebarHoverGray: '#DCE5EC',
  backgroundGray: '#FAFAFA',
  lighterBackgroundGray: '#F9FAFB',
  modalMaskGray: '#000000A6',
  textBaseGray: '#000000D9',
  textGray: '#666666',
  textMetalBlue: '#618DA5',
  plainGray: '#F3F5F7',
  darkGray: '#234D70',
  nonEditableBorderGray: '#D5D5D5',
  nonEditableBackgroundGray: '#ECECEC',
  separatorGray: '#E6E6E6',
  secondaryHover: '#F7F7F7',
  tableHeaderLineGray: '#F0F4F7',
  secondaryDisabledGray: '#727272',
  primaryDisabledGray: '#a0a0a0',
  lableBackgroundGray: '#CFD9EC',
  lableColorGray: '#6D89BE',
  menuGray: '#DFE5F2',
  menuGrayLight: '#F5F8FF',
  logoutGray: '#85A6B9',
  primaryGreen: '#00D0A2',
  secondaryGreen: '#00AC86',
  menuItemGreen: '#17edbe',
  widgetGreen: '#50CD89',
  lightGreen: '#E6FFF9',
  cyan: '#00edb9',
  green: '#2eb039',
  primaryBlue: '#3636D8',
  secondaryBlue: '#343BCD',
  darkBlue: '#25256F',
  lightBlue: '#DFE5F2',
  lighterBlue: '#4285F4',
  littleBlue: '#343BCD',
  lightBackgroundBlue: '#E7F2FB',
  projectBarBlue: '#8C85FF',
  menuItemBlue: '#19375a',
  menuItemBlueActive: '#194270',
  skyBlue: '#B3E2FF',
  lighterSkyBlue: '#E6F6FF',
  errorRed: '#FF3939',
  errorRedHover: '#EB3534',
  red: '#DB3131',
  lightRed: '#FFECF0',
  deleteHoverRed: '#EB4A40',
  lightPurple: '#F3F3FF',
  warningYellow: '#FFFBBA',
  warningYellowDarker: '#FAAD14', // as antd warning yellow
  sideMenuBackground: '#152F4D',
  sideMenuBackgroundDark: '#122940',
  sideMenuBorder: '#203955',
  sideMenuText: '#ffffffb3',
  sidebarBorder: '#234d70',
  sidebarSecondaryBorder: '#203955',
  loginPageBottomPart: '#161352',
  alertYellow: '#fdf3cd',
  alertYellowText: '#866709',
  onboardingBackground: '#203955',
  workflowBg: '#0e0f38',
  workflowBgLighter: '#12144a'
};

export const messageLevelToColorMap = {
  info: theme.primaryWhite,
  error: theme.errorRed,
  warning: theme.warningYellow
};

export type Env0Theme = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends Env0Theme {}
}

export default theme;
