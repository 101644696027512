import BaseService from 'services/base-service';
import { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';
import { action, computed, observable, reaction } from 'mobx';
import some from 'lodash/some';
import { ConfigurationScope } from '@env0/configuration-service/api.enum';
import type { EnvironmentApi } from '@env0/environment-service/api';
import { ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import type { ConfigurationProperty } from 'types/api.types';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';
import type ServiceContainer from 'services/service-container';

export const entireWorkflowAlias = 'entireWorkflow';

export class WorkflowVariablesStore extends BaseService {
  @observable
  private workflowConfigurationPropertiesStores: Record<string, ConfigurationPropertiesStore> = {};
  private entireWorkflowConfigurationPropertiesStore: ConfigurationPropertiesStore;
  private disposeChanges: (() => void) | undefined;

  constructor(service: ServiceContainer) {
    super(service);
    this.entireWorkflowConfigurationPropertiesStore = new ConfigurationPropertiesStore(this.service);
  }

  public init(subEnvironments: Record<string, EnvironmentApi.WorkflowEnvironments.WorkflowSubEnvironment>) {
    this.workflowConfigurationPropertiesStores = Object.fromEntries(
      Object.keys(subEnvironments).map(alias => [alias, new ConfigurationPropertiesStore(this.service)])
    );
  }

  @action
  public registerToChanges() {
    this.disposeChanges = reaction<ConfigurationPropertyStore[]>(
      () => this.entireWorkflowConfigurationPropertiesStore?.environmentTypeAndScopeConfigurationProperties,
      (changedConfigurationProperties: ConfigurationPropertyStore[], _) => {
        changedConfigurationProperties?.forEach(changedConfigurationProperty => {
          this.propagateWorkflowPropertiesChangesToSubEnvironment(changedConfigurationProperty);
        });
      }
    );
  }

  public propagateWorkflowVariablesToSubEnvironment() {
    this.getConfigurationPropertiesStoreByAlias(entireWorkflowAlias)
      .environmentVariables.filter(property => property.scope === ConfigurationScope.BLUEPRINT)
      .forEach(workflowConfigurationProperty => {
        this.propagateWorkflowPropertiesChangesToSubEnvironment(workflowConfigurationProperty);
      });
  }

  private propagateWorkflowPropertiesChangesToSubEnvironment(changedConfigurationProperty: ConfigurationPropertyStore) {
    function findPropertyFromSubEnvStore(
      subEnvironmentStore: ConfigurationPropertiesStore,
      changedConfigurationProperty: ConfigurationPropertyStore
    ) {
      return subEnvironmentStore.environmentVariables.find(env => {
        // if the variable was created in the UI, the ID is reflected from the workflow to subEnv
        // otherwise, it was created in server, so the matching is based on name
        const compareByName = env.data.name === changedConfigurationProperty.data.name;
        const compareById = env.id === changedConfigurationProperty.id;
        return changedConfigurationProperty.isExist ? compareByName : compareById;
      });
    }

    Object.entries(this.workflowConfigurationPropertiesStores).forEach(([, subEnvironmentStore]) => {
      const existingSubEnvProperty = findPropertyFromSubEnvStore(subEnvironmentStore, changedConfigurationProperty);

      const variableWasOverride =
        existingSubEnvProperty && existingSubEnvProperty.scope === ConfigurationScope.ENVIRONMENT;

      if (variableWasOverride) return;

      const variableWasCreated = !existingSubEnvProperty && changedConfigurationProperty.isShow;
      const variableWasDeleted = existingSubEnvProperty && !changedConfigurationProperty.isShow;
      const variableWasUpdated = existingSubEnvProperty && changedConfigurationProperty.isShow;

      if (variableWasCreated) {
        const newData: ConfigurationProperty = {
          ...changedConfigurationProperty.data,
          scope: ConfigurationScope.WORKFLOW,
          id: changedConfigurationProperty.id
        };
        subEnvironmentStore.addConfigurationProperty(new ConfigurationPropertyStore(subEnvironmentStore, newData));
      } else if (variableWasDeleted) {
        existingSubEnvProperty?.delete();
      } else if (variableWasUpdated) {
        existingSubEnvProperty?.propagatePropertyValue({
          ...changedConfigurationProperty.data
        });
      }
    });
  }

  public async load(
    subEnvironments: Record<string, EnvironmentApi.WorkflowEnvironments.WorkflowSubEnvironment>,
    projectId: string | undefined,
    workflowEnvironmentId: string | undefined
  ) {
    await Promise.allSettled(
      Object.keys(subEnvironments).map(alias => {
        const subEnvironment = subEnvironments[alias];
        const subEnvironmentConfigurationStore = this.getConfigurationPropertiesStoreByAlias(alias);
        subEnvironmentConfigurationStore.resetConfigurationSetChanges();
        return subEnvironmentConfigurationStore.loadProperties(
          {
            projectId,
            blueprintId: subEnvironment.templateId,
            workflowEnvironmentId: workflowEnvironmentId,
            scope: alias !== entireWorkflowAlias ? ConfigurationScope.ENVIRONMENT : ConfigurationScope.WORKFLOW,
            scopeId: subEnvironment.environmentId,
            environmentId: subEnvironment.environmentId
          },
          true
        );
      })
    );
  }

  @action
  public cleanup() {
    this.workflowConfigurationPropertiesStores = {};
    this.disposeChanges?.();
  }

  public getConfigurationPropertiesStores() {
    return this.workflowConfigurationPropertiesStores;
  }

  public getConfigurationPropertiesStoreByAlias(environment: string) {
    return environment === entireWorkflowAlias
      ? this.entireWorkflowConfigurationPropertiesStore
      : this.workflowConfigurationPropertiesStores[environment];
  }

  public getConfigurationChanges(environment: string) {
    return this.getConfigurationPropertiesStoreByAlias(environment).getConfigurationPropertiesChangesForDeployment();
  }

  @computed get environmentErrors() {
    return map(
      pickBy(this.workflowConfigurationPropertiesStores, store => store.hasError),
      (_, alias) => alias
    );
  }

  @computed get hasError() {
    return (
      this.entireWorkflowConfigurationPropertiesStore.hasError ||
      some(this.workflowConfigurationPropertiesStores, store => store.hasError)
    );
  }
}
