import { Dropdown } from 'antd';
import type { Auth0UserProfile } from 'auth0-js';
import UserSwitcher from 'components/layout/topbar/switcher/user-switcher';
import { links } from 'constants/external-links';
import theme, { BREADCRUMB_BAR_HEIGHT, DEFAULT_SHADOW, TOP_BAR_HEIGHT } from 'constants/themes.constants';
import { observer } from 'mobx-react';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'types/theme.types';
import type { TrackingData } from 'utils/analytics.utils';
import { EventNames } from 'utils/analytics.utils';
import TrackableLink from 'components/common/trackable-link';
import TrackbleButton from 'components/common/trackble-button';
import { isSAMLUser } from 'utils/user.utils';
import { AppNotifications } from 'components/app-notifications/app-notifications';
import { KbarSearchButton } from 'components/kbar/kbar-search-button';
import TrialCountdown from 'components/trial-countdown/trial-countdown';
import useStores from 'hooks/use-stores.hooks';
import useIsMobile from 'hooks/use-is-mobile';
import { MenuOutlined } from '@ant-design/icons';
import type { BreadcrumbSelector } from 'components/breadcrumbs/use-breadcrumbs';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';

interface Props {
  profile?: Auth0UserProfile;
  title?: string;
  breadcrumbs?: BreadcrumbSelector;
}

const scheduleDemoTracking: TrackingData = {
  eventName: EventNames.TOP_BAR_SCHEDULE_DEMO,
  eventProperties: {}
};

const docsLinkTracking: TrackingData = {
  eventName: EventNames.TOP_BAR_DOCS_CLICK,
  eventProperties: {}
};

const TopBarView: React.FunctionComponent<Props> = ({ profile, title, breadcrumbs }) => {
  const {
    organizationsStore: { currentOrganization },
    navbarStore
  } = useStores();

  const { isMobile } = useIsMobile();

  const openSideBar = useCallback(() => {
    if (isMobile) {
      navbarStore.toggleOpen();
    }
  }, [isMobile, navbarStore]);

  const showDemoButton = !isSAMLUser(profile);
  return (
    <Container>
      <TitleRow>
        {isMobile && (
          <IconWrapper onClick={openSideBar}>
            <MenuOutlined />
          </IconWrapper>
        )}
        <SubContainer>
          <LeftContainer>
            <Title data-e2e="topbar-title" isMobile={isMobile}>
              {title}
            </Title>
          </LeftContainer>
          {profile && (
            <RightContainer>
              {!isMobile && (
                <>
                  {currentOrganization?.mode === 'trial' && <TrialCountdown />}
                  <KbarSearchButton />
                  <AppNotifications />
                  {showDemoButton && (
                    <TrackbleButton
                      data-e2e="demo-button"
                      type={'primary'}
                      href={links.DEMO.SCHEDULE_DEMO}
                      isExternalLink={true}
                      trackingData={scheduleDemoTracking}>
                      <FormattedMessage id="schedule.demo.text" />
                    </TrackbleButton>
                  )}
                </>
              )}

              <DocContainer>
                <TrackableLink
                  data-e2e="docs-link"
                  messageId={'docs.link.text'}
                  url={links.docs.ROOT}
                  trackingData={docsLinkTracking}
                  color={theme.secondaryBlack}
                />
              </DocContainer>
              <Dropdown
                dropdownRender={originNode => (
                  <>
                    <UserSwitcher />
                    {originNode}
                  </>
                )}
                placement="bottomRight"
                trigger={['click']}>
                <UserContainer data-e2e="user-menu-dropdown">
                  <UserAvatar src={profile.picture} />
                  <ArrowDown />
                </UserContainer>
              </Dropdown>
            </RightContainer>
          )}
        </SubContainer>
      </TitleRow>
      <BreadcrumbRow>
        <Breadcrumbs selector={breadcrumbs} />
      </BreadcrumbRow>
    </Container>
  );
};

const Container = styled.div`
  background: white;
  box-shadow: ${DEFAULT_SHADOW};
  align-self: stretch;
  z-index: 1;
`;

const TitleRow = styled.div`
  min-height: ${TOP_BAR_HEIGHT};
  padding-right: 20px;
  padding-left: 5%;
  display: flex;
  align-items: center;
  z-index: 100;
  flex: none;
`;

const BreadcrumbRow = styled(TitleRow)`
  min-height: ${BREADCRUMB_BAR_HEIGHT};
  border-top: ${({ theme }) => `1px solid ${theme.separatorGray}`};
  align-items: stretch;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 16px;
`;

const Title = styled.span<{ isMobile: boolean }>`
  color: #0e2133;
  font-size: ${({ isMobile }) => (isMobile ? '1.3em' : '1.5em')};
  ${({ isMobile }) => (isMobile ? 'padding-left: 1em' : '')};
  font-weight: 600;
  letter-spacing: -0.15px;
`;

const ArrowDown = styled.div`
  margin-left: 5px;
  margin-bottom: 2px;
  position: relative;
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid black;
`;

const DocContainer = styled.div`
  padding-top: 3px;
`;

const UserContainer = styled.div`
  cursor: pointer;
`;

const UserAvatar = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  margin-right: 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.8em;
  flex: none;
`;

export default observer(TopBarView);
