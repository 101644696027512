import React, { useMemo } from 'react';
import { CREDENTIALS_TYPE_MAPPING } from 'types/api.types';
import { ProjectSettingsCredentialsSelector } from 'components/projects/settings/credentials/project-settings-credentials-selector';
import { Container } from 'components/common/form-components';
import type { CredentialUsageType } from 'types/api.types';
import uniq from 'lodash/uniq';
import { getCredentialsTypeByUsageType } from 'utils/credential.utils';
import type { ProjectCredentialsForm } from 'components/projects/settings/credentials/project-settings-credentials-form.hook';

type ProjectCredentialsCardContentProps = {
  usageType: CredentialUsageType;
  disabled?: boolean;
  form: ProjectCredentialsForm;
};

const ProjectCredentialsCardContent: React.FunctionComponent<ProjectCredentialsCardContentProps> = ({
  usageType,
  disabled,
  form
}) => {
  const credentialsProviders = useMemo(() => {
    return uniq(getCredentialsTypeByUsageType(usageType).map(cred => CREDENTIALS_TYPE_MAPPING[cred]));
  }, [usageType]);

  return (
    <Container>
      {credentialsProviders.map(provider => (
        <ProjectSettingsCredentialsSelector
          disabled={disabled}
          key={provider}
          form={form}
          usageType={usageType}
          credentialProvider={provider}
        />
      ))}
    </Container>
  );
};

export default ProjectCredentialsCardContent;
