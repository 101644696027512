import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import type { NestedValue } from 'react-hook-form';

import useStores from 'hooks/use-stores.hooks';
import useForm from 'hooks/use-form.hook';
import type { Credential, CredentialUsageType, CredentialValues, NewCredential } from 'types/api.types';
import { CredentialType } from 'types/api.types';
import { useCreateCredential, useCuratedCredentials, useUpdateCredential } from 'stores/rq/credentials';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import { EventNames, track } from 'utils/analytics.utils';
import { isSecretReference } from '@env0/common-secret/secret-reference';

const TABLE_ID_FORMAT = /^.+\..+\..+$/;

export type CredentialFormValues = {
  name: string;
  type: CredentialType;
  value: NestedValue<CredentialValues>;
};

type GetSchemaArgs = {
  existingCredentials: Credential[];
  isCurrentOrganizationSelfHosted?: boolean;
  credentialId?: string;
  usageType: CredentialUsageType;
};

const errorPrefix = 'settings.credentials.error.';
const requiredString = () =>
  Yup.string()
    .required(errorPrefix + 'required')
    .default('');

const isJsonString = (str: any) => {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
};

const getSchema = ({ existingCredentials, isCurrentOrganizationSelfHosted, credentialId }: GetSchemaArgs) => {
  const existingCredentialNames = existingCredentials.map(c => c.name);
  const jwtOidcSub = Yup.string().required('settings.credentials.error.missingJwtOidcSub');

  return Yup.object()
    .shape({
      // this breaks type safety - TODO: improve typeof schema in useForm
      name: credentialId
        ? requiredString()
        : requiredString().notOneOf(existingCredentialNames, errorPrefix + 'existing-credential'),
      type: Yup.mixed<CredentialType>().oneOf(Object.values(CredentialType)).required(),
      value: Yup.mixed<CredentialValues>()
        .when('type', {
          is: CredentialType.AWS_ACCESS_KEYS_FOR_DEPLOYMENT,
          then: Yup.object({
            accessKeyId: requiredString(),
            secretAccessKey: requiredString()
          })
        })
        .when('type', {
          is: CredentialType.AWS_ASSUMED_ROLE_FOR_DEPLOYMENT,
          then: Yup.object({
            roleArn: requiredString()
          })
        })
        .when('type', {
          is: CredentialType.AWS_ASSUMED_ROLE,
          then: Yup.object({
            roleArn: requiredString()
          })
        })
        .when('type', {
          is: CredentialType.AWS_OIDC,
          then: Yup.object({
            roleArn: requiredString(),
            jwtOidcSub
          })
        })
        .when('type', {
          is: CredentialType.GCP_SERVICE_ACCOUNT_FOR_DEPLOYMENT,
          then: Yup.object({
            serviceAccountKey: isCurrentOrganizationSelfHosted
              ? requiredString().test('is-secret-reference', errorPrefix + 'aws.secret', isSecretReference)
              : requiredString().test('is-json', errorPrefix + 'gcp.json', isJsonString),
            projectId: Yup.string()
          })
        })
        .when('type', {
          is: CredentialType.GCP_CREDENTIALS,
          then: Yup.object({
            tableId: requiredString().matches(TABLE_ID_FORMAT, errorPrefix + 'gcp.tableId'),
            secret: requiredString().test('is-json', errorPrefix + 'gcp.json', isJsonString)
          })
        })
        .when('type', {
          is: CredentialType.GCP_OIDC,
          then: Yup.object({
            credentialConfigurationFileContent: requiredString().test(
              'is-json',
              errorPrefix + 'gcp.json',
              isJsonString
            ),
            jwtOidcSub
          })
        })
        .when('type', {
          is: (val: CredentialType) =>
            [CredentialType.AZURE_SERVICE_PRINCIPAL_FOR_DEPLOYMENT, CredentialType.AZURE_CREDENTIALS].includes(val),
          then: Yup.object({
            clientId: requiredString(),
            clientSecret: requiredString(),
            tenantId: requiredString(),
            subscriptionId: requiredString()
          })
        })
        .when('type', {
          is: CredentialType.AZURE_OIDC,
          then: Yup.object({
            clientId: requiredString(),
            tenantId: requiredString(),
            subscriptionId: requiredString(),
            jwtOidcSub
          })
        })
        .when('type', {
          is: CredentialType.K8S_KUBECONFIG_FILE,
          then: Yup.object({
            kubeConfig: requiredString()
          })
        })
        .when('type', {
          is: CredentialType.K8S_EKS_AUTH,
          then: Yup.object({
            clusterName: requiredString(),
            clusterRegion: requiredString()
          })
        })
        .when('type', {
          is: CredentialType.K8S_AZURE_AKS_AUTH,
          then: Yup.object({
            clusterName: requiredString(),
            resourceGroup: requiredString()
          })
        })
        .when('type', {
          is: CredentialType.K8S_GCP_GKE_AUTH,
          then: Yup.object({
            clusterName: requiredString(),
            computeRegion: requiredString()
          })
        })
        .when('type', {
          is: CredentialType.VAULT_OIDC,
          then: Yup.object({
            roleName: requiredString(),
            namespace: Yup.string(),
            address: requiredString().url(errorPrefix + 'url'),
            version: requiredString(),
            jwtAuthBackendPath: requiredString(),
            jwtOidcSub
          })
        })
    })
    .defined();
};

export type UseCredentialsModalFormProps = {
  initialValues?: Partial<CredentialFormValues>;
  onCreated?: (credential: Credential) => void;
  credentialId?: string;
  usageType: CredentialUsageType;
};

const useCredentialModalForm = ({
  initialValues,
  onCreated = noop,
  credentialId,
  usageType
}: UseCredentialsModalFormProps) => {
  const { organizationsStore } = useStores();
  const { allCredentials } = useCuratedCredentials();
  const { mutateAsync: createCredential } = useCreateCredential();
  const { mutateAsync: updateCredential } = useUpdateCredential();
  const { isCurrentOrganizationSelfHosted } = organizationsStore;
  const schema = useMemo(
    () =>
      getSchema({
        existingCredentials: allCredentials,
        isCurrentOrganizationSelfHosted,
        credentialId,
        usageType
      }),
    [allCredentials, isCurrentOrganizationSelfHosted, credentialId, usageType]
  );

  const onSubmit = useCallback(
    async (formValues: NewCredential | Partial<Credential>) => {
      const parsedFormValues = omit(formValues, 'value.jwtOidcSub');
      let credential;

      if (credentialId) {
        credential = await updateCredential({
          id: credentialId,
          data: omit(parsedFormValues, 'name') as Partial<Credential>
        });
        track(EventNames.CREDENTIAL_UPDATED);
      } else {
        credential = await createCredential(parsedFormValues as NewCredential);
      }
      onCreated(credential);
    },
    [createCredential, onCreated, credentialId, updateCredential]
  );

  return useForm<CredentialFormValues>({
    schema,
    onSubmit,
    initialValues,
    shouldClearAfterSubmit: true
  });
};

export default useCredentialModalForm;
