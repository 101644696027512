import React from 'react';
import ProjectSettingsEnvironmentDiscovery from 'components/projects/settings/environment-discovery/project-settings-environment-discovery';
import Loader from 'components/common/loader';
import Stack from 'components/common/stack';
import { useGetEnvironmentDiscovery } from 'stores/rq/environment-discovery';
import ErrorContainer from 'components/common/error-container';
import type { AxiosError } from 'axios';

/**
 * This component is required because of current behavior of useForm.
 * This behavior makes the form's initial values to not cause a render, thus not populating the fields as expected after the data has been fetched.
 * using this wrapper component, we can make sure the form is re-rendered when the data is fetched
 */

const ProjectSettingsEnvironmentDiscoveryContainer: React.FC = () => {
  const { data: environmentDiscovery, isLoading, error } = useGetEnvironmentDiscovery();
  const content = isLoading ? (
    <Loader></Loader>
  ) : error && (error as AxiosError).response?.status !== 404 ? (
    <ErrorContainer errorToReport={error}></ErrorContainer>
  ) : (
    <ProjectSettingsEnvironmentDiscovery environmentDiscovery={environmentDiscovery} />
  );
  return <Stack>{content}</Stack>;
};

export default ProjectSettingsEnvironmentDiscoveryContainer;
