import { useCurrentOrganizationId } from 'hooks/use-current-organization-id';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type {
  ConfigurationApi,
  ConfigurationSet,
  ConfigurationSetAssignmentScope
} from '@env0/configuration-service/api';
import { getQueryClientInstance } from 'stores/rq/common/query-client-provider';
import type { SetCreationScope } from '@env0/configuration-service/types';
import {
  useCurrentEnvironmentId,
  useCurrentModuleId,
  useCurrentProjectId,
  useCurrentTemplateId,
  useNewEnvironmentProjectAndTemplateIds
} from 'hooks/path-params-extraction.hooks';
import useApiClient from 'hooks/use-api-client';
import uniqBy from 'lodash/uniqBy';

const useCacheKeys = () => {
  const baseKey = ['sets'] as const;
  return {
    single: (id: string) => [...baseKey, 'single', { id }] as const,
    creationScope: (scope: SetCreationScope, scopeId: string, includeHigherScopes = false) =>
      [...baseKey, ['creation'], { includeHigherScopes }, { scope }, { scopeId }] as const,
    assignmentScope: (
      scope: ConfigurationSetAssignmentScope,
      scopeId: string,
      isCreatingNewEnvironmentFromTemplate: boolean,
      _projectId?: string,
      _templateId?: string
    ) => [...baseKey, ['assignment'], { scope }, { scopeId }, { isCreatingNewEnvironmentFromTemplate }] as const
  } as const;
};

const useCommonHookSetup = () => {
  return {
    apiClient: useApiClient(),
    queryClient: getQueryClientInstance(),
    ...useCacheKeys()
  };
};

const useGetCreationScopeAndScopeId = () => {
  const organizationId = useCurrentOrganizationId();
  const projectScopeId = useCurrentProjectId();

  if (projectScopeId) return { scope: 'project', scopeId: projectScopeId };
  else return { scope: 'organization', scopeId: organizationId };
};

export const useGetAssignmentScopeAndScopeId = (): { scope: ConfigurationSetAssignmentScope; scopeId: string } => {
  const organizationId = useCurrentOrganizationId();
  const projectScopeId = useCurrentProjectId();
  const environmentId = useCurrentEnvironmentId();
  const templateId = useCurrentTemplateId();
  const moduleId = useCurrentModuleId();

  if (templateId) return { scope: 'template', scopeId: templateId };
  else if (moduleId) return { scope: 'module', scopeId: moduleId };
  else if (environmentId) return { scope: 'environment', scopeId: environmentId };
  else if (projectScopeId) return { scope: 'project', scopeId: projectScopeId };
  else return { scope: 'organization', scopeId: organizationId };
};

export const useGetConfigurationSetById = (setId: string) => {
  const { apiClient, single } = useCommonHookSetup();

  return useQuery({
    queryKey: single(setId),
    queryFn: () => apiClient.configurations.getConfigurationSetById(setId)
  });
};

export const useGetConfigurationSetsForCreationScope = (includeHigherScopes = false) => {
  const { apiClient, creationScope } = useCommonHookSetup();
  const { scope, scopeId } = useGetCreationScopeAndScopeId();

  return useQuery<ConfigurationSet[], any, ConfigurationSet[], ReturnType<typeof creationScope>>({
    queryKey: creationScope(scope as SetCreationScope, scopeId, includeHigherScopes),
    queryFn: ({ queryKey: [, , { includeHigherScopes }, { scope }, { scopeId }] }) =>
      apiClient.configurations.getConfigurationSetsByCreationScope({
        scope,
        scopeId,
        includeHigherScopes: includeHigherScopes ? 'true' : 'false'
      }),
    initialData: []
  });
};

export const useDeleteConfigurationSets = (setId: string) => {
  const { apiClient, single, creationScope } = useCommonHookSetup();

  const queryClient = useQueryClient();

  const { scope, scopeId } = useGetCreationScopeAndScopeId();
  const creationScopeQueryKey = creationScope(scope as SetCreationScope, scopeId);
  return useMutation({
    mutationKey: single(setId),
    mutationFn: () => apiClient.configurations.deleteSet(setId),
    onSuccess: () => {
      queryClient.setQueryData<ConfigurationSet[]>(creationScopeQueryKey, prev =>
        prev?.filter(set => set.id !== setId)
      );
      queryClient.refetchQueries({ queryKey: creationScopeQueryKey });
    }
  });
};

export const useCreateConfigurationSet = () => {
  const { apiClient, creationScope, queryClient } = useCommonHookSetup();
  const { scope, scopeId } = useGetCreationScopeAndScopeId();
  return useMutation({
    mutationFn: (createSetBody: ConfigurationApi.CreateConfigurationSet.Request.Body) =>
      apiClient.configurations.createSet(createSetBody),
    onSuccess: newSet => {
      const creationScopeQueryKey = creationScope(scope as SetCreationScope, scopeId);
      queryClient.setQueryData<ConfigurationSet[]>(creationScopeQueryKey, prev =>
        prev ? [...prev, newSet] : [newSet]
      );
      queryClient.refetchQueries({ queryKey: creationScopeQueryKey });
    }
  });
};

export const useUpdateConfigurationSet = (setId: string) => {
  const { apiClient, single } = useCommonHookSetup();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateSetBody: ConfigurationApi.UpdateConfigurationSet.Request.Body) =>
      apiClient.configurations.updateSet(setId, updateSetBody),
    onSuccess: updatedSet => {
      queryClient.setQueryData<ConfigurationSet[]>(single(updatedSet.id!), prev =>
        prev ? [...prev, updatedSet] : [updatedSet]
      );
    }
  });
};

export const useGetConfigurationSetsAssignmentsForAssignmentScope = (overrides?: {
  scopeId?: string;
  scope?: ConfigurationSetAssignmentScope;
}) => {
  const { apiClient, assignmentScope } = useCommonHookSetup();
  const { scope, scopeId } = useGetAssignmentScopeAndScopeId();
  const { projectId, templateId, isCreatingNewEnvironmentFromTemplate } =
    useNewEnvironmentProjectAndTemplateIds() ?? {};

  return useQuery<ConfigurationSet[], any, ConfigurationSet[], ReturnType<typeof assignmentScope>>({
    queryKey: assignmentScope(
      overrides?.scope ?? scope,
      overrides?.scopeId ?? scopeId,
      isCreatingNewEnvironmentFromTemplate,
      projectId,
      templateId
    ),
    queryFn: ({ queryKey: [, , { scope }, { scopeId }] }) =>
      isCreatingNewEnvironmentFromTemplate
        ? Promise.all([
            apiClient.configurations.getConfigurationSetsByAssignmentScope('project', projectId!),
            apiClient.configurations.getConfigurationSetsByAssignmentScope('template', templateId!)
          ]).then(([projectSets, templateSets]) => uniqBy([...projectSets, ...templateSets], 'id'))
        : apiClient.configurations.getConfigurationSetsByAssignmentScope(scope, scopeId),
    initialData: []
  });
};
