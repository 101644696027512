import useForm, { type UseFormProps } from 'hooks/use-form.hook';
import type { SchemaOf } from 'yup';
import * as yup from 'yup';
import { useCallback, useMemo } from 'react';
import useStores from 'hooks/use-stores.hooks';
import mapValues from 'lodash/mapValues';
import type { Token } from 'types/api.types';
import { createTokenFromNameAndValue } from 'utils/tokens.utils';

export type FormValues = { name: string; value: string };
const initialValues = { name: '', value: '' };

export const useTokenForm = (onCreateResult: (result: Token) => void) => {
  const { configurationStore } = useStores();

  const onSubmit = useCallback<UseFormProps<FormValues>['onSubmit']>(
    async ({ name, value }) => {
      const result = await configurationStore.createToken(createTokenFromNameAndValue(name, value));
      onCreateResult(result);
    },
    [configurationStore, onCreateResult]
  );

  const tokens = configurationStore.tokens;
  const schema = useMemo(() => {
    const existingNames = Object.values(tokens ?? {}).map(t => t.name);
    return getSchema(existingNames);
  }, [tokens]);

  return useForm({
    schema,
    onSubmit,
    initialValues,
    shouldClearAfterSubmit: true
  });
};

const errorIds = mapValues(
  {
    nameEmpty: 'token.required',
    nameExists: 'token.already.exist',
    valueEmpty: 'value.required'
  },
  v => `settings.credentials.${v}`
);

const getSchema = (existingNames: string[]): SchemaOf<FormValues> => {
  return yup.object({
    name: yup.string().required(errorIds.nameEmpty).notOneOf(existingNames, errorIds.nameExists),
    value: yup.string().required(errorIds.valueEmpty)
  });
};
