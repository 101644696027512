import React, { useEffect, useMemo } from 'react';
import { Alert } from 'antd';
import { FormattedMessage } from 'react-intl';
import type { GitProviderTypes } from 'types/api.types';
import styled from 'types/theme.types';
import { Popup } from 'utils/popup.utils';
import { generateVcsRedirectLink } from 'utils/vcs.utils';
import useStores from 'hooks/use-stores.hooks';

type Props = {
  gitProvider: GitProviderTypes;
  onSuccess: () => void;
  onBlocked: () => void;
};

const VcsIntegrationWindow: React.FC<Props> = ({ gitProvider, onSuccess, onBlocked }) => {
  const { organizationsStore, authStore } = useStores();
  const organizationId = organizationsStore.currentOrganizationId!;
  const accessToken = authStore.getAccessToken()!;
  const providerText = useMemo(() => gitProvider.toLowerCase(), [gitProvider]);

  useEffect(() => {
    Popup.open({
      url: generateVcsRedirectLink({ gitProvider, organizationId, accessToken }),
      onBlock: onBlocked,
      onClosed: onSuccess,
      features: { height: 700, width: 700 }
    });
  }, [gitProvider, onBlocked, onSuccess, organizationId, accessToken]);

  return (
    <>
      <VcsAlertContainer data-e2e={`new-${providerText}-app-alert`}>
        <Alert
          message={<FormattedMessage id={'templates.add.new.vcs.title'} values={{ gitProvider }} />}
          description={<FormattedMessage id={`templates.add.new.vcs.message`} values={{ gitProvider }} />}
          type="info"
          showIcon
        />
      </VcsAlertContainer>
    </>
  );
};

const VcsAlertContainer = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: 600px;
  height: 100px;
  z-index: 9999;
`;

export default VcsIntegrationWindow;
