import React, { type ReactNode } from 'react';
import styled from 'types/theme.types';
import { ReactComponent as env0Logo } from 'assets/images/logo-white.svg';

export const OnboardingLayout: React.FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <OuterContainer>
      <MainLayoutContainer>
        <Env0Logo />
        <WhiteContainer>{children}</WhiteContainer>
      </MainLayoutContainer>
    </OuterContainer>
  );
};

const OuterContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

const MainLayoutContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background: ${({ theme }) => theme.onboardingBackground};
  background-size: cover;
`;

const Env0Logo = styled(env0Logo)`
  margin: 50px;
  transform: scale(1.3);
  flex: none;
`;

const cardWidth = '970px' as const;
const WhiteContainer = styled.div`
  display: flex;
  overflow: auto;
  width: ${cardWidth};
  height: 80%;
  min-height: 750px;
  border-radius: 4px;
  background: ${({ theme }) => theme.primaryWhite};
  box-shadow: 0 9px 28px 8px rgba(0, 0, 0, 0.05), 0 6px 16px rgba(0, 0, 0, 0.08),
    0 3px 6px -4px ${({ theme }) => theme.primaryDisabledGray};

  @media (max-width: ${cardWidth}) {
    width: 100%;
    height: min-content;
    border-radius: 0;
    flex: 1;
  }
`;

export default OnboardingLayout;
