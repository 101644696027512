import type { SchemaOf } from 'yup';
import * as Yup from 'yup';
import type { EnvironmentApi } from '@env0/environment-service/api';
import { useCallback } from 'react';
import useForm, { type UseFormProps } from 'hooks/use-form.hook';
import { useUpdateProjectPolicies } from 'stores/rq/project-policies';
import { useSubmitCustomFlow } from 'components/projects/settings/policies/common/use-custom-flow-in-form';
import type { BlueprintApi } from '@env0/blueprint-service/api';
import { useSubmitApprovalPolicy } from 'components/projects/settings/policies/common/use-approval-policy-form';
import { CRON_MISSING_ERROR, validateCron } from 'components/environments/helpers/cronValidationHelper';

export type PoliciesFormSchema = {
  numberOfEnvironments: number | null;
  numberOfEnvironmentsTotal: number | null;
  maxTtl: string | null;
  defaultTtl: string | null;
  autoApproveDefault: boolean;
  includeCostEstimation: boolean;
  skipApplyWhenPlanIsEmpty: boolean;
  disableDestroyEnvironments: boolean;
  skipRedundantDeployments: boolean;
  continuousDeploymentDefault: boolean;
  runPullRequestPlanDefault: boolean;
  vcsPrCommentsEnabledDefault: boolean;
  forceRemoteBackend: boolean;
  approvalPolicyAssignments: any[];
  customFlowAssignments: any[];
  outputsAsInputsEnabled: boolean;
  driftDetection: {
    enabled: boolean;
    cron?: string;
  };
};

type Props = {
  policies: EnvironmentApi.Policy;
  approvalPolicyAssignments: Omit<BlueprintApi.ApprovalPolicyTemplateWithScope[], 'id'>;
  customFlowAssignments: BlueprintApi.RichCustomFlowAssigment[];
};

const schemaShape: Record<keyof PoliciesFormSchema, Yup.AnySchema> = {
  numberOfEnvironments: Yup.number().nullable().default(null),
  numberOfEnvironmentsTotal: Yup.number().nullable().default(null),
  maxTtl: Yup.string().nullable().default(null),
  defaultTtl: Yup.string().nullable().default(null),
  autoApproveDefault: Yup.boolean().required(),
  includeCostEstimation: Yup.boolean().required(),
  skipApplyWhenPlanIsEmpty: Yup.boolean().required(),
  disableDestroyEnvironments: Yup.boolean().required(),
  skipRedundantDeployments: Yup.boolean().required().default(false),
  continuousDeploymentDefault: Yup.boolean().required(),
  runPullRequestPlanDefault: Yup.boolean().required(),
  vcsPrCommentsEnabledDefault: Yup.boolean().required().default(false),
  forceRemoteBackend: Yup.boolean().required().default(false),
  approvalPolicyAssignments: Yup.array().of(Yup.object()),
  customFlowAssignments: Yup.array().of(Yup.object()),
  driftDetection: Yup.object({
    enabled: Yup.boolean().default(false),
    cron: Yup.string()
      .default('')
      .when('enabled', {
        is: true,
        then: Yup.string().required(CRON_MISSING_ERROR).test(validateCron(true))
      })
  }),
  outputsAsInputsEnabled: Yup.boolean().required().default(false)
};

const schema = Yup.object().shape(schemaShape) as SchemaOf<PoliciesFormSchema>;

export const usePoliciesForm = ({ policies, approvalPolicyAssignments, customFlowAssignments }: Props) => {
  const { mutateAsync: updatePolicies } = useUpdateProjectPolicies();

  const submitCustomFlow = useSubmitCustomFlow();
  const submitApprovalPolicy = useSubmitApprovalPolicy();

  const onSubmit = useCallback<UseFormProps<PoliciesFormSchema>['onSubmit']>(
    async (data, form, initialFormValues) => {
      const {
        numberOfEnvironments,
        numberOfEnvironmentsTotal,
        maxTtl,
        defaultTtl,
        autoApproveDefault,
        includeCostEstimation,
        skipApplyWhenPlanIsEmpty,
        disableDestroyEnvironments,
        skipRedundantDeployments,
        continuousDeploymentDefault,
        runPullRequestPlanDefault,
        vcsPrCommentsEnabledDefault,
        forceRemoteBackend,
        driftDetection,
        outputsAsInputsEnabled
      } = form.getValues();

      // Saving custom flow run validation
      // if it is a new update API with validation we should run the validation before all other updates
      await submitCustomFlow(data, form, initialFormValues);
      await submitApprovalPolicy(data, form, initialFormValues);

      await updatePolicies({
        projectId: policies.projectId,
        numberOfEnvironments,
        numberOfEnvironmentsTotal,
        maxTtl,
        defaultTtl,
        requiresApprovalDefault: !autoApproveDefault,
        includeCostEstimation,
        skipApplyWhenPlanIsEmpty,
        disableDestroyEnvironments,
        skipRedundantDeployments,
        continuousDeploymentDefault,
        runPullRequestPlanDefault,
        vcsPrCommentsEnabledDefault,
        forceRemoteBackend,
        driftDetectionEnabled: driftDetection.enabled,
        driftDetectionCron: driftDetection.cron,
        outputsAsInputsEnabled
      });
    },
    [policies.projectId, submitCustomFlow, submitApprovalPolicy, updatePolicies]
  );

  const form = useForm<PoliciesFormSchema>({
    schema,
    onSubmit,
    initialValues: {
      numberOfEnvironments: policies.numberOfEnvironments ?? null,
      numberOfEnvironmentsTotal: policies.numberOfEnvironmentsTotal ?? null,
      maxTtl: policies.maxTtl ?? null,
      defaultTtl: policies.defaultTtl ?? null,
      autoApproveDefault: !policies.requiresApprovalDefault,
      includeCostEstimation: policies.includeCostEstimation,
      skipApplyWhenPlanIsEmpty: policies.skipApplyWhenPlanIsEmpty,
      disableDestroyEnvironments: policies.disableDestroyEnvironments,
      skipRedundantDeployments: policies.skipRedundantDeployments,
      continuousDeploymentDefault: policies.continuousDeploymentDefault,
      runPullRequestPlanDefault: policies.runPullRequestPlanDefault,
      vcsPrCommentsEnabledDefault: policies.vcsPrCommentsEnabledDefault,
      forceRemoteBackend: policies.forceRemoteBackend,
      outputsAsInputsEnabled: policies.outputsAsInputsEnabled,
      approvalPolicyAssignments: approvalPolicyAssignments,
      customFlowAssignments: customFlowAssignments,
      driftDetection: {
        enabled: policies.driftDetectionEnabled ?? false,
        cron: policies.driftDetectionCron
      }
    }
  });

  return { form };
};
