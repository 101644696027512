import BaseService from 'services/base-service';
import { ConfigurationPropertiesStore } from 'stores/mobx/configuration-properties.store';
import { action, computed, observable, reaction } from 'mobx';
import some from 'lodash/some';
import { ConfigurationScope } from '@env0/configuration-service/api.enum';
import { ConfigurationPropertyStore } from 'stores/mobx/configuration-property.store';
import type { ConfigurationProperty } from 'types/api.types';
import pickBy from 'lodash/pickBy';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import type { EnvironmentImportApi } from '@env0/environment-import-service/api';

export type ConfigurationVariableScopes = Record<
  string,
  { scope: ConfigurationScope; scopeId?: string; projectId: string; environmentId?: string }
>;

export class EnvironmentImportVariablesStore extends BaseService {
  @observable
  private environmentImportConfigurationPropertiesStores: Record<string, ConfigurationPropertiesStore> = {};
  private configurationPropertiesHierarchy: Record<string, string[]> = {};
  private disposeChanges: (() => void) | undefined;

  public init(storeScopes: ConfigurationVariableScopes, configurationHierarchy: Record<string, string[]>) {
    this.environmentImportConfigurationPropertiesStores = Object.fromEntries(
      Object.keys(storeScopes).map(scopeKey => [scopeKey, new ConfigurationPropertiesStore(this.service)])
    );
    this.configurationPropertiesHierarchy = configurationHierarchy;
  }

  @action
  public registerToChanges() {
    this.disposeChanges = reaction<ConfigurationPropertyStore[]>(
      () => this.allImportStoresProjectVariables,
      //TODO: This runs for all project variables, regardless of the ones changed, we can improve this
      // By using mobx 6 added previous values parameter to filter out the ones that changed
      (changedConfigurationProperties: ConfigurationPropertyStore[]) => {
        this.propagateVariablesToChildScopes();
      }
    );
  }

  public propagateVariablesToChildScopes() {
    this.allImportStoresProjectVariables.forEach(changedEnvironmentConfig => {
      this.propagatePropertiesChangesToChildScopes(changedEnvironmentConfig);
    });
  }

  private findPropertyFromChildStore(
    childStore: ConfigurationPropertiesStore,
    changedConfigurationProperty: ConfigurationPropertyStore
  ) {
    return childStore.allVariablesIncludingDeleted.find(env => {
      // if the variable was created in the UI, the ID is reflected from the parent store to the child stores
      // otherwise, it was created in server, so the matching is based on name
      const compareByName = env.data.name === changedConfigurationProperty.data.name;
      const compareById = env.id === changedConfigurationProperty.id;
      return changedConfigurationProperty.isExist ? compareByName : compareById;
    });
  }

  private addCreatedVariableToChildStore(
    childStore: ConfigurationPropertiesStore,
    changedConfigurationProperty: ConfigurationPropertyStore
  ) {
    const newData: ConfigurationProperty = {
      ...changedConfigurationProperty.data,
      scopeId: childStore.configurationsScope.scopeId,
      scope: ConfigurationScope.PROJECT,
      id: changedConfigurationProperty.id
    };
    const newProperty = new ConfigurationPropertyStore(childStore, newData);
    newProperty.initialData = changedConfigurationProperty.initialData;
    childStore.addConfigurationProperty(newProperty);
  }

  private propagatePropertiesChangesToChildScopes(changedConfigurationProperty: ConfigurationPropertyStore) {
    const changedChildStores = Object.entries(this.environmentImportConfigurationPropertiesStores)
      .filter(([scopeKey]) => {
        const changedId = changedConfigurationProperty.scopeId;
        return changedId && this.configurationPropertiesHierarchy[changedId]?.includes(scopeKey);
      })
      .map(([, childStore]) => childStore);
    changedChildStores.forEach(childStore => {
      const existingProperty = this.findPropertyFromChildStore(childStore, changedConfigurationProperty);

      const variableWasOverride = existingProperty && existingProperty.scope === ConfigurationScope.ENVIRONMENT;

      if (variableWasOverride) return;

      const variableWasCreated = !existingProperty && changedConfigurationProperty.isShow;
      const variableWasDeleted = existingProperty && !changedConfigurationProperty.isShow;
      const variableWasUpdated =
        existingProperty &&
        changedConfigurationProperty.isShow &&
        !isEqual(
          omit(changedConfigurationProperty.data, ['id', 'scopeId', 'schema']),
          omit(existingProperty.data, ['id', 'scopeId', 'schema'])
        );

      if (variableWasCreated) {
        this.addCreatedVariableToChildStore(childStore, changedConfigurationProperty);
      } else if (variableWasDeleted) {
        existingProperty?.delete();
      } else if (variableWasUpdated) {
        existingProperty?.propagatePropertyValue({
          ...changedConfigurationProperty.data,
          scopeId: childStore.configurationsScope.scopeId
        });
      }
    });
  }

  public async load(
    scopes: ConfigurationVariableScopes,
    discoveredEnvironments: EnvironmentImportApi.DiscoveredUnimportedEnvironment[]
  ) {
    await Promise.allSettled(
      Object.entries(scopes).map(([storeKey, variableScope]) => {
        const loadedStore: ConfigurationPropertiesStore = this.environmentImportConfigurationPropertiesStores[storeKey];
        loadedStore.resetConfigurationSetChanges();
        return loadedStore.loadProperties(variableScope, true);
      })
    );

    (discoveredEnvironments as EnvironmentImportApi.DiscoveredEnvironment[])
      .filter(env => env.status === 'discovered')
      .forEach(discoveredEnvironment => {
        const loadedStore: ConfigurationPropertiesStore =
          this.environmentImportConfigurationPropertiesStores[discoveredEnvironment.id!];
        discoveredEnvironment.discoveredVariables?.map(variable =>
          loadedStore.createConfigurationPropertyIfNotExists(variable)
        );
      });
  }

  @action
  public cleanup() {
    this.environmentImportConfigurationPropertiesStores = {};
    this.configurationPropertiesHierarchy = {};
    this.disposeChanges?.();
  }

  public getConfigurationPropertiesStoreById(storeKey: string) {
    return this.environmentImportConfigurationPropertiesStores[storeKey];
  }

  public getConfigurationChanges(storeKey: string) {
    return this.getConfigurationPropertiesStoreById(storeKey).getConfigurationPropertiesChangesForDeployment();
  }

  /** Get the ids of the projects / the discovered envs which have errors */
  @computed get failedStoreKeys() {
    return map(
      pickBy(this.environmentImportConfigurationPropertiesStores, store => store.hasError),
      (_, alias) => alias
    );
  }

  @computed get hasError() {
    return some(this.environmentImportConfigurationPropertiesStores, store => store.hasError);
  }

  @computed get allImportStoresProjectVariables() {
    const allStores = Object.values(this.environmentImportConfigurationPropertiesStores);
    return allStores
      .flatMap(store => store.allVariablesIncludingDeleted)
      .filter(variable => variable.scope === ConfigurationScope.PROJECT);
  }
}
