import React from 'react';
import type { TableRowSelection } from 'antd/lib/table/interface';
import styled from '@emotion/styled';
import type { TableProps } from 'components/common/table';
import Table, { defaultPagination } from 'components/common/table';
import { FormattedMessage } from 'react-intl';
import type { EnvironmentImportApi } from '@env0/environment-import-service/api';

interface Props {
  titleId: string;
  actionButton: React.ReactNode;
  columns: TableProps['columns'];
  dataSource: TableProps['dataSource'];
  selectedRowKeys: React.Key[];
  setSelectedRowKeys: (keys: React.Key[]) => void;
  disableRow?: (record: EnvironmentImportApi.DiscoveredEnvironment) => boolean;
}

const EnvironmentImportTable: React.FunctionComponent<Props> = ({
  titleId,
  actionButton,
  columns,
  dataSource,
  selectedRowKeys,
  setSelectedRowKeys,
  disableRow
}) => {
  const rowSelection: TableRowSelection<any> = {
    getCheckboxProps: record => ({ id: `checkbox-${record.id}`, disabled: disableRow?.(record) }),
    onChange: setSelectedRowKeys,
    selectedRowKeys
  };
  return (
    <TableWrapper>
      <Table
        title={() => (
          <TableTitleWrapper>
            <FormattedMessage id={titleId} tagName="h3" />
            {actionButton}
          </TableTitleWrapper>
        )}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          ...defaultPagination,
          hideOnSinglePage: false,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} environments`,
          showSizeChanger: true
        }}
        rowSelection={rowSelection}
        rowKey="id"
      />
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  border: 1px solid rgb(240, 240, 240);
  border-radius: 8px;
`;

const TableTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default EnvironmentImportTable;
