import type { ComponentType, ReactNode } from 'react';
import type { BreadcrumbSelector } from 'components/breadcrumbs/use-breadcrumbs';
import { selectors } from 'components/routes/breadcrumbs-selectors';
import InvitationLayout from 'components/layout/invitation-layout';
import OnboardingLayout from 'components/layout/onboarding-layout';
import type { RolesApi } from '@env0/role-service/api';
import type { PermissionOperator } from 'hooks/use-has-permission';
import { FullSizeContentContainer } from 'components/layout/inner-layout';

type Route = {
  path: string;
  page: string;
  title?: string;
  breadcrumbs?: BreadcrumbSelector;
  container?: ComponentType<{ children?: ReactNode }>;
};

type RouteWithPermissions = {
  requiredPermissions?: RolesApi.RBACPermission[];
  permissionOperator?: PermissionOperator;
} & Route;

type LayoutRoute = { layout: ComponentType<{ children?: ReactNode }> } & Route;

export const ONBOARDING_ROUTE_PREFIX = '/onboarding';

export const routes: RouteWithPermissions[] = [
  {
    path: '/p/:projectId/environments/:environmentId/deployments/:deploymentLogId',
    page: 'environment-deployment',
    title: 'navigation.environment.deployment.log',
    breadcrumbs: selectors.deploymentLogIdSelector,
    requiredPermissions: ['VIEW_ENVIRONMENT']
  },
  {
    path: '/p/:projectId/environments/:environmentId/redeploy',
    page: 'before-run-settings-page',
    title: 'environment.redeploy',
    breadcrumbs: selectors.beforeRunEnvironment,
    requiredPermissions: ['RUN_PLAN']
  },
  {
    path: '/p/:projectId/environments/:environmentId',
    page: 'environment',
    title: 'navigation.environment',
    breadcrumbs: selectors.environmentIdSelector,
    requiredPermissions: ['VIEW_ENVIRONMENT']
  },
  {
    path: '/p/:projectId/environments',
    page: 'environments',
    title: 'navigation.environments',
    breadcrumbs: selectors.environmentsSelector,
    requiredPermissions: ['VIEW_ENVIRONMENT']
  },
  {
    path: '/p/:projectId/sub-projects',
    page: 'projects',
    title: 'projects',
    breadcrumbs: selectors.projectSubProjectsSelector,
    requiredPermissions: ['VIEW_PROJECT']
  },
  {
    path: '/p/:projectId/add-new-environment/vcs',
    page: 'single-use-wizard-page',
    title: 'navigation.project.environments.add-new',
    breadcrumbs: selectors.environmentsSelector,
    requiredPermissions: ['RUN_PLAN']
  },
  {
    path: '/p/:projectId/add-new-environment',
    page: 'template-wizard-page',
    title: 'navigation.project.environments.add-new',
    breadcrumbs: selectors.environmentsSelector,
    requiredPermissions: ['RUN_PLAN']
  },
  {
    path: '/p/:projectId/environments/:environmentId/templates/:templateId/settings/vcs',
    page: 'single-use-wizard-page',
    title: 'templates.singleUse.edit',
    breadcrumbs: selectors.editVCSEnvironment
  },
  {
    path: '/p/:projectId/templates/:templateId/run-now',
    page: 'before-run-settings-page',
    title: 'environment.before.deploy.run.environment',
    breadcrumbs: selectors.beforeRunTemplate,
    requiredPermissions: ['RUN_PLAN']
  },
  {
    path: '/p/:projectId/templates/manage',
    page: 'project-templates-manage',
    title: 'navigation.project.templates',
    breadcrumbs: selectors.templateManageSelector,
    requiredPermissions: ['MANAGE_PROJECT_TEMPLATES']
  },
  {
    path: '/p/:projectId/templates',
    page: 'project-templates',
    title: 'navigation.project.templates',
    breadcrumbs: selectors.projectTemplatesSelector,
    requiredPermissions: ['VIEW_PROJECT']
  },
  {
    path: '/p/:projectId/variables',
    page: 'configurations',
    title: 'variables',
    breadcrumbs: selectors.variablesSelector,
    requiredPermissions: ['VIEW_PROJECT']
  },
  {
    path: '/p/:projectId/variables/add-new-set',
    page: 'configuration-set-wizard-page',
    title: 'navigation.organization.variables.add-new-configuration-set',
    requiredPermissions: ['EDIT_PROJECT_SETTINGS'],
    breadcrumbs: selectors.addNewVariableSetSelector
  },
  {
    path: '/p/:projectId/variables/set/:setId/settings',
    page: 'configuration-set-wizard-page',
    title: 'navigation.organization.variables.edit-configuration-set',
    requiredPermissions: ['EDIT_PROJECT_SETTINGS'],
    breadcrumbs: selectors.variablesSelector
  },
  {
    path: '/p/:projectId/costs',
    page: 'project-costs-page',
    title: 'navigation.project.costs',
    breadcrumbs: selectors.projectCostsSelector,
    requiredPermissions: ['EDIT_PROJECT_SETTINGS']
  },
  {
    path: '/p/:projectId/settings/:activeMenu?',
    page: 'project-settings',
    title: 'navigation.project.settings',
    breadcrumbs: selectors.projectSettingsActiveMenuSelector,
    requiredPermissions: ['EDIT_PROJECT_SETTINGS']
  },
  {
    path: '/organizations/:organizationId/teams/:teamId',
    page: 'manage-team',
    title: 'teams',
    requiredPermissions: ['EDIT_ORGANIZATION_SETTINGS'],
    breadcrumbs: selectors.teamSelector
  },
  {
    path: '/organizations/:organizationId/roles/create-new-role',
    page: 'create-role-page',
    title: 'organization.settings.create-role-page.title',
    requiredPermissions: ['CREATE_CUSTOM_ROLES'],
    breadcrumbs: selectors.createRoleSelector
  },
  {
    path: '/organizations/:organizationId/roles/:roleId',
    page: 'update-role-page',
    title: 'organization.settings.update-role-page.title',
    requiredPermissions: ['CREATE_CUSTOM_ROLES'],
    breadcrumbs: selectors.updateRoleSelector
  },
  {
    path: '/organizations/:organizationId/:activeMenu?',
    page: 'organization-settings',
    title: 'organization.settings.title',
    breadcrumbs: selectors.organizationActiveMenuSelector,
    requiredPermissions: ['EDIT_ORGANIZATION_SETTINGS'],
    container: FullSizeContentContainer
  },
  {
    path: '/personal-settings/:activeMenu?',
    page: 'personal-settings',
    title: 'personal.settings.menu.personal-settings',
    breadcrumbs: selectors.userPersonalSettingsSelector
  },
  {
    path: '/templates/add-new-template',
    page: 'template-wizard-page',
    title: 'navigation.organization.templates.add-new',
    breadcrumbs: selectors.addNewTemplateSelector,
    requiredPermissions: ['CREATE_AND_EDIT_TEMPLATES']
  },
  {
    path: '/templates/:templateId/settings',
    page: 'template-wizard-page',
    title: 'navigation.organization.templates.settings',
    requiredPermissions: ['CREATE_AND_EDIT_TEMPLATES'],
    breadcrumbs: selectors.templatesTemplateIdSelector
  },
  {
    path: '/templates',
    page: 'templates',
    title: 'navigation.organization.templates',
    requiredPermissions: ['VIEW_ORGANIZATION'],
    breadcrumbs: selectors.templatesSelector
  },
  {
    path: '/variables',
    page: 'configurations',
    title: 'navigation.organization.variables',
    requiredPermissions: ['VIEW_ORGANIZATION'],
    breadcrumbs: selectors.variablesActiveMenuSelector
  },
  {
    path: '/variables/add-new-set',
    page: 'configuration-set-wizard-page',
    title: 'navigation.organization.variables.add-new-configuration-set',
    requiredPermissions: ['EDIT_ORGANIZATION_SETTINGS'],
    breadcrumbs: selectors.addNewVariableSetOrganizationSelector
  },
  {
    path: '/variables/set/:setId/settings',
    page: 'configuration-set-wizard-page',
    title: 'navigation.organization.variables.edit-configuration-set',
    requiredPermissions: ['EDIT_ORGANIZATION_SETTINGS'],
    breadcrumbs: selectors.variablesActiveMenuSelector
  },
  {
    path: '/registry/modules/add-new-module',
    page: 'module-wizard-page',
    title: 'navigation.organization.modules.add-new',
    breadcrumbs: selectors.registrySelector,
    requiredPermissions: ['CREATE_AND_EDIT_MODULES']
  },
  {
    path: '/registry/modules/:moduleId/settings',
    page: 'module-wizard-page',
    title: 'navigation.organization.modules.settings',
    breadcrumbs: selectors.registrySelector,
    requiredPermissions: ['CREATE_AND_EDIT_MODULES']
  },
  {
    path: '/registry/modules/:moduleId',
    page: 'module',
    title: 'navigation.organization.modules.module',
    requiredPermissions: ['VIEW_MODULES'],
    breadcrumbs: selectors.moduleSelector
  },
  {
    path: '/registry/modules/:moduleId/tests/:moduleTestId',
    page: 'module-test-page',
    title: 'navigation.organization.modules.module.test',
    requiredPermissions: ['VIEW_MODULES'],
    breadcrumbs: selectors.moduleTestSelector
  },
  {
    path: '/registry/providers/:providerId',
    page: 'provider',
    title: 'navigation.organization.providers.provider',
    requiredPermissions: ['VIEW_PROVIDERS'],
    breadcrumbs: selectors.providerSelector
  },
  {
    path: '/registry/:activeMenu?',
    page: 'registry',
    title: 'registry',
    requiredPermissions: ['VIEW_PROVIDERS', 'VIEW_MODULES'],
    breadcrumbs: selectors.registrySelector,
    permissionOperator: 'OR'
  },
  {
    path: '/dashboards',
    page: 'dashboards-page',
    title: 'navigation.organization.dashboards',
    breadcrumbs: selectors.dashboardsSelector,
    requiredPermissions: ['VIEW_DASHBOARD']
  },
  {
    path: '/',
    page: 'projects',
    title: 'projects',
    breadcrumbs: selectors.projectsSelector
  },
  {
    path: '/cloud/:activeMenu?',
    page: 'cloud-page',
    title: 'cloud.beta',
    breadcrumbs: selectors.cloudSelector,
    requiredPermissions: ['VIEW_DASHBOARD']
  },
  {
    path: '/cloud/settings/:cloudConfigurationParam',
    page: 'cloud-configuration-wizard-page',
    title: 'cloud.beta',
    requiredPermissions: ['EDIT_ORGANIZATION_SETTINGS'],
    breadcrumbs: selectors.cloudConfigurationSelector
  },
  {
    path: '/p/:projectId/environment-discovery/import',
    page: 'import-environments',
    title: 'navigation.import.environments',
    requiredPermissions: ['EDIT_PROJECT_SETTINGS'],
    breadcrumbs: selectors.environmentImportSelector
  },
  {
    path: '/p/:projectId/environment-discovery/import/rerun',
    page: 'import-environments',
    title: 'navigation.import.environments.rerun',
    requiredPermissions: ['EDIT_PROJECT_SETTINGS'],
    breadcrumbs: selectors.environmentImportRerunSelector
  }
];

export const invitationRoutes: LayoutRoute[] = [
  {
    path: '/accept-invite/valid',
    page: 'organization-invitation-valid',
    layout: ({ children }) => <>{children}</>
  },
  {
    path: '/accept-invite/invalid',
    page: 'organization-invitation-invalid',
    layout: InvitationLayout
  },
  {
    path: '/accept-invite',
    page: 'organization-invitation-validation',
    layout: InvitationLayout
  }
];

// TODO: add titles
export const onboardingRoutes: LayoutRoute[] = [
  {
    path: `${ONBOARDING_ROUTE_PREFIX}/welcome`,
    page: 'onboarding/onboarding-welcome-page',
    layout: OnboardingLayout
  },
  {
    path: `${ONBOARDING_ROUTE_PREFIX}/create-new-organization`,
    page: 'onboarding/onboarding-new-organization-page',
    layout: OnboardingLayout
  },
  {
    path: `${ONBOARDING_ROUTE_PREFIX}/environment-wizard`,
    page: 'onboarding/onboarding-environment-wizard-page',
    layout: OnboardingLayout
  },
  {
    path: `${ONBOARDING_ROUTE_PREFIX}/congratulations`,
    page: 'onboarding/onboarding-congratulations-page',
    layout: OnboardingLayout
  }
];
