import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'components/common/button';
import { SelectOption } from 'components/common/input-components/select';
import AddNewTokenModal from 'components/organizations/credentials/tokens/add-new-token-modal';
import { useModal } from 'hooks/modal.hooks';
import useStores from 'hooks/use-stores.hooks';
import map from 'lodash/fp/map';
import isUndefined from 'lodash/fp/isUndefined';
import isEmpty from 'lodash/fp/isEmpty';
import has from 'lodash/fp/has';
import get from 'lodash/fp/get';
import type { GitProviderTypes, Token, Tokens } from 'types/api.types';
import { TokenTypes } from 'types/api.types';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import { observer } from 'mobx-react';
import type { SubFormProps } from 'components/common/form-controlled/with-controller.hoc';
import type { WithLabelProps } from 'components/common/form-controlled/with-label.hoc';
import type { VCSForm } from 'components/templates/templates-wizard/common/template-wizard.types';
import { isGitProviderUsingToken } from 'utils/vcs.utils';

interface Props extends WithLabelProps<SubFormProps<VCSForm>> {
  gitProvider: GitProviderTypes;
  allowClear?: boolean;
}

const getTokensSelectOptions = (tokens: Tokens, selectedTokenId?: string, selectedTokenName?: string) => {
  if (isEmpty(tokens)) {
    return (
      <SelectOption key="no-tokens" disabled value="">
        <FormattedMessage id="templates.add.new.field.token.empty" />
      </SelectOption>
    );
  }

  const tokensTemplate = map(
    (token: Token) => (
      <SelectOption key={`token-option-${token.id}`} value={token.id}>
        {token.name}
      </SelectOption>
    ),
    tokens
  );

  if (!selectedTokenId || has(selectedTokenId, tokens)) return tokensTemplate;

  return [
    ...tokensTemplate,
    <SelectOption key={`token-option-${selectedTokenId}`} value={selectedTokenId}>
      {selectedTokenName}
    </SelectOption>
  ];
};

const TokenSelectField: React.FunctionComponent<Props> = ({ form, gitProvider, allowClear, ...labelProps }) => {
  const { configurationStore, networkStore } = useStores();
  const { tokens } = configurationStore;
  const isLoadingTokens = networkStore.isLoading('tokens');
  const showAddModal = gitProvider === 'Other';

  const [tokenId, setTokenId] = form.useManualField<string | undefined>('tokenId');
  const [tokenName, setTokenName] = form.useManualField<string | undefined>('tokenName');

  const tokenTypeResolver = (gitProvider: GitProviderTypes) => {
    switch (gitProvider) {
      case 'AzureDevOps':
        return TokenTypes.AzureDevOps;
      case 'GitLab':
        return TokenTypes.GitLab;
      default:
        return TokenTypes.GIT;
    }
  };

  useEffect(() => {
    configurationStore.getTokens(tokenTypeResolver(gitProvider));
  }, [configurationStore, gitProvider]);

  const { hideModal, showModal, isModalOpen } = useModal();

  const onTokenChanged = (id: string, name: string) => {
    setTokenId(id);
    setTokenName(name);
    if (isGitProviderUsingToken(gitProvider)) {
      form.setValue('repository', undefined, { shouldValidate: false });
    }
  };

  const onTokenSelectChange = (value: string) => {
    // onChange is being called with undefined by onClear
    if (!isUndefined(value)) {
      onTokenChanged(value, get([value, 'name'], tokens));
    }
  };

  const onSelectClear = () => {
    onTokenChanged('', '');
  };

  return (
    <>
      <ControlledSelect
        form={form}
        name="tokenId"
        data-e2e="template-token-select"
        loading={isLoadingTokens}
        allowClear={allowClear}
        placeholder={
          <FormattedMessage id={`templates.add.new.field.token.placeholder${showAddModal ? '.allow.add' : ''}`} />
        }
        onChange={onTokenSelectChange}
        onClear={onSelectClear}
        value={tokenId}
        customActions={
          showAddModal && (
            <Button onClick={showModal} type="primary" size="small">
              <FormattedMessage id="settings.tokens.add.new.title" />
            </Button>
          )
        }
        {...labelProps}>
        {getTokensSelectOptions(tokens, tokenId, tokenName)}
      </ControlledSelect>
      {showAddModal && (
        <AddNewTokenModal
          data-e2e="add-new-token-modal"
          open={isModalOpen}
          hideModal={hideModal}
          onTokenCreated={onTokenChanged}
        />
      )}
    </>
  );
};

export default observer(TokenSelectField);
