import { useEffect, useMemo } from 'react';
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import Button from 'components/common/button';
import Checkbox from 'components/common/input-components/checkbox';
import { SelectOption } from 'components/common/input-components/select';
import CredentialModal from 'components/organizations/credentials/credential-modal';
import { useModal } from 'hooks/modal.hooks';
import filter from 'lodash/filter';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import type { CredentialUsageType } from 'types/api.types';
import ControlledSelect from 'components/common/form-controlled/controlled-select';
import { observer } from 'mobx-react';
import { useGetCredentials } from 'stores/rq/credentials';
import { getCredentialTypesByUsageTypeAndCredentialProvider } from 'utils/credential.utils';
import { useIntl } from 'react-intl';
import type { ProjectCredentialsForm } from 'components/projects/settings/credentials/project-settings-credentials-form.hook';
import { useHasPermission } from 'hooks/use-has-permission';
import type { CredentialProviderType } from 'types/api.types';

export interface ProjectSettingsCostsCredentialsSelectorProps {
  usageType: CredentialUsageType;
  form: ProjectCredentialsForm;
  credentialProvider: CredentialProviderType;
  disabled?: boolean;
}

export const ProjectSettingsCredentialsSelector: React.FunctionComponent<ProjectSettingsCostsCredentialsSelectorProps> =
  observer(function ProjectSettingsCredentialsSelector({
    form,
    credentialProvider,
    disabled,
    usageType
  }: ProjectSettingsCostsCredentialsSelectorProps) {
    const intl = useIntl();
    const { data: allCredentials } = useGetCredentials();
    const { isAuthorized: hasPermission } = useHasPermission('EDIT_PROJECT_SETTINGS');
    const cloudRelatedCredentialTypes = useMemo(
      () => getCredentialTypesByUsageTypeAndCredentialProvider(usageType, credentialProvider),
      [usageType, credentialProvider]
    );
    const credentialsForCloud = useMemo(
      () => filter(allCredentials, cred => cloudRelatedCredentialTypes.includes(cred.type)),
      [allCredentials, cloudRelatedCredentialTypes]
    );
    const credentialId = form.watch(credentialProvider);

    const [checked, setChecked] = useState(!!credentialId);
    const { showModal, hideModal, isModalOpen } = useModal();

    useEffect(() => {
      setChecked(!!credentialId);
    }, [credentialId]);

    const onCheckboxChanged = (evt: CheckboxChangeEvent) => {
      const checked = evt.target.checked;
      if (!checked) form.setValue(credentialProvider, '');
      setChecked(checked);
    };

    const e2ePrefix = `project-settings-${usageType.toLowerCase()}-credentials-${credentialProvider}`;
    return (
      <Row align="middle">
        <Col span={4}>
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={onCheckboxChanged}
            data-e2e={`${e2ePrefix}-checkbox`}>
            <FormattedMessage id={`projects.settings.menu.credentials.provider.${credentialProvider}`} />
          </Checkbox>
        </Col>
        <Col span={8}>
          <ControlledSelect
            data-e2e={`${e2ePrefix}-select`}
            name={credentialProvider}
            form={form}
            disabled={disabled || !checked}
            customActions={
              hasPermission && (
                <Button onClick={showModal} type="primary" id="add-new-credential">
                  <FormattedMessage id="settings.credentials.add.new.button" />
                </Button>
              )
            }>
            {credentialsForCloud.map(credential => (
              <SelectOption key={credential.id} value={credential.id} data-e2e={`${e2ePrefix}-select-option`}>
                {cloudRelatedCredentialTypes.length > 1
                  ? `${credential.name} (${intl.formatMessage({
                      id: `projects.settings.menu.credentials.provider.${credential.type}`
                    })})`
                  : credential.name}
              </SelectOption>
            ))}
          </ControlledSelect>
        </Col>
        <CredentialModal
          credentialProvider={credentialProvider}
          usageType={usageType}
          open={isModalOpen}
          hideModal={hideModal}
        />
      </Row>
    );
  });
