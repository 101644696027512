import styled from '@emotion/styled';
import { PurpleText } from 'components/projects/settings/environment-discovery/environment-discovery-typography';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as UnstyledPrOpenedStep } from 'assets/images/pr-opened-step.svg';
import { ReactComponent as UnstyledEnvironmentDiscoveredStep } from 'assets/images/environment-discovered-step.svg';
import { ReactComponent as UnstyledPrApprovedStep } from 'assets/images/pr-approved-step.svg';
import { ReactComponent as UnstyledEnvironmentDeployedStep } from 'assets/images/environment-deployed-step.svg';
import { ReactComponent as UnstyledCurvedArrow } from 'assets/images/curved-arrow.svg';

const WelcomeSteps: React.FC<{ stepPosition: 'up' | 'down'; hideArrow?: boolean; children: any }> = ({
  stepPosition,
  hideArrow,
  children
}) => {
  return (
    <StepContainer stepPosition={stepPosition}>
      <StepImageAndText>{children}</StepImageAndText>
      {!hideArrow && (
        <ArrowContainer stepPosition={stepPosition}>
          <CurvedArrow stepPosition={stepPosition} />
        </ArrowContainer>
      )}
    </StepContainer>
  );
};

const stepSvgStyles = `
width: 200px;
height: 70px;
transition: transform 0.2s ease-in;
&:hover {
  transform: scale(1.05);
}
`;

const PrOpenedStep = styled(UnstyledPrOpenedStep)`
  ${stepSvgStyles}
`;

const EnvironmentDiscoveredStep = styled(UnstyledEnvironmentDiscoveredStep)`
  ${stepSvgStyles}
`;

const PrApprovedStep = styled(UnstyledPrApprovedStep)`
  ${stepSvgStyles}
`;

const EnvironmentDeployedStep = styled(UnstyledEnvironmentDeployedStep)`
  ${stepSvgStyles}
`;

const steps: Array<{
  name: string;
  image: JSX.Element;
  stepPosition: 'up' | 'down';
}> = [
  {
    name: 'pr-opened',
    image: <PrOpenedStep />,
    stepPosition: 'up'
  },
  {
    name: 'environment-discovered',
    image: <EnvironmentDiscoveredStep />,
    stepPosition: 'down'
  },
  {
    name: 'pr-approved',
    image: <PrApprovedStep />,
    stepPosition: 'up'
  },
  {
    name: 'environment-deployed',
    image: <EnvironmentDeployedStep />,
    stepPosition: 'down'
  }
];

const EnvironmentDiscoveryWelcomeChartOld: React.FC = () => {
  return (
    <ChartContainer>
      {steps.map((step, index) => (
        <WelcomeSteps key={index} stepPosition={step.stepPosition} hideArrow={index === steps.length - 1}>
          {step.image}
          <TextContainer>
            <PurpleText>
              <FormattedMessage id={`environment.discovery.welcome.${step.name}.step`} />
            </PurpleText>
          </TextContainer>
        </WelcomeSteps>
      ))}
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 4em 0;
  height: 210px;
  justify-content: space-between;
  max-width: 1100px;
  width: 100%;
`;

const ArrowContainer = styled.div<{ stepPosition: 'up' | 'down' }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: stretch;
  transform: ${({ stepPosition }) => stepPosition === 'down' && 'scaleX(-1) rotate(180deg)'};
  margin-bottom: ${({ stepPosition }) => stepPosition === 'up' && '20px'};
`;

const StepImageAndText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const StepContainer = styled.div<{ stepPosition: 'up' | 'down' }>`
  display: flex;
  flex-direction: ${({ stepPosition }) => (stepPosition === 'down' ? 'column-reverse' : 'column')};
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  height: 100%;
  width: 244px;
`;

const CurvedArrow = styled(UnstyledCurvedArrow)<{ stepPosition: 'up' | 'down' }>`
  width: 150px;
  height: 60px;
`;

const TextContainer = styled.div`
  width: 200px;
`;

export default EnvironmentDiscoveryWelcomeChartOld;
