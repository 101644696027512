import React, { type HTMLAttributes } from 'react';
import { getOrganizationAvatar, getOrganizationName } from 'utils/organizations.utils';
import { HoverItem, type ItemProps } from 'components/layout/navbar/navbar-panel.utils';
import type { Organization } from 'types/api.types';
import styled from 'types/theme.types';

type Props = ItemProps & {
  organization: Organization;
  nameProps?: HTMLAttributes<HTMLDivElement> & Record<`data-${string}`, string>;
};

const OrganizationItem = ({ organization, nameProps, ...props }: Props) => {
  return (
    <HoverItem {...props}>
      {hovered => (
        <>
          <Image src={getOrganizationAvatar(organization, hovered)} />
          <Name {...nameProps}>{getOrganizationName(organization)}</Name>
        </>
      )}
    </HoverItem>
  );
};

export default OrganizationItem;

const Image = styled.img`
  height: 26px;
  width: 26px;
  border-radius: 50%;
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: -0.08px;
  line-height: 22px;
`;
