import React from 'react';
import { links } from 'constants/external-links';
import { setLink } from 'components/common/link';
import ControlledTextArea from 'components/common/form-controlled/controlled-text-area';
import type { UseFormReturnType } from 'hooks/use-form.hook';
import { Text } from 'components/common/form-components';
import { Col, Row } from 'components/common/grid';
import styled from 'types/theme.types';

type RadioCustomGlobProps = {
  showTextInput: boolean;
  form: UseFormReturnType;
  value?: string;
};

const RadioCustomGlob: React.FunctionComponent<RadioCustomGlobProps> = ({ showTextInput, form, value }) => {
  const customValue = value ? { value } : {};

  return (
    <Row gutter={[8, 0]}>
      <Col>
        <Text
          id={'environment.auto-deploy.settings.redeploy-by-custom-glob'}
          info={{
            id: 'environment.auto-deploy.settings.redeploy-by-custom-glob.info',
            values: setLink(links.docs.CONTINUOUS_DEPLOYMENT.CUSTOM_GLOB)
          }}
        />
      </Col>
      <Col span={24}>
        <InvisibleTextArea
          errorSide={'bottom'}
          visible={showTextInput}
          form={form}
          name={'autoDeployByCustomGlob'}
          autoSize={{ minRows: 2, maxRows: 6 }}
          data-e2e={'glob-filter-text-area'}
          {...customValue}
        />
      </Col>
    </Row>
  );
};

// If a form component isn't mounted, it affects the form by always marking it dirty. Therefore, we're keeping the component invisible but mounted
const InvisibleTextArea = styled(ControlledTextArea, { shouldForwardProp: prop => prop !== 'visible' })<{
  visible: boolean;
}>`
  display: ${({ visible }) => !visible && 'none'};
`;

export default RadioCustomGlob;
