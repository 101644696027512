import React from 'react';
import styled from 'types/theme.types';
import { ReactComponent as BitbucketIcon } from 'assets/images/bitbucket.svg';
import { ReactComponent as GitlabIcon } from 'assets/images/gitlab.svg';
import { ReactComponent as GithubIcon } from 'assets/images/github.svg';
import { ReactComponent as AzureDevOpsIcon } from 'assets/images/azure-devops.svg';
import { ReactComponent as HelmIcon } from 'assets/images/helm-logo-on-white.svg';
import type { RadioButtonOpts } from 'components/common/radio-buttons';
import type { GitProviderTypes } from 'types/api.types';
import { BranchesOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import compact from 'lodash/compact';
import type { BlueprintApi } from '@env0/blueprint-service/api';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  *:first-child {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
`;

export type GitProviderRadioButtonOps = RadioButtonOpts<GitProviderTypes> & {
  isForSelfHostedOrg?: boolean;
};

export const githubButton: GitProviderRadioButtonOps = {
  value: 'GitHub',
  content: (
    <ButtonContainer>
      <GithubIcon />
      <div>Github.com</div>
    </ButtonContainer>
  )
};

export const githubEnterpriseButton: GitProviderRadioButtonOps = {
  value: 'GitHubEnterprise',
  isForSelfHostedOrg: true,
  content: (
    <ButtonContainer>
      <GithubIcon />
      <div>Github Enterprise</div>
    </ButtonContainer>
  )
};

export const gitlabButton: GitProviderRadioButtonOps = {
  value: 'GitLab',
  content: (
    <ButtonContainer>
      <GitlabIcon />
      <div>Gitlab.com</div>
    </ButtonContainer>
  )
};

export const azureDevOpsButton: GitProviderRadioButtonOps = {
  value: 'AzureDevOps',
  content: (
    <ButtonContainer>
      <AzureDevOpsIcon />
      <div>Azure DevOps</div>
    </ButtonContainer>
  )
};

const helmRepositoryButton: GitProviderRadioButtonOps = {
  value: 'HelmRepository',
  content: (
    <ButtonContainer>
      <HelmIcon />
      <div>Helm Repo</div>
    </ButtonContainer>
  )
};

export const gitlabEnterpriseButton: GitProviderRadioButtonOps = {
  value: 'GitLabEnterprise',
  isForSelfHostedOrg: true,
  content: (
    <ButtonContainer>
      <GitlabIcon />
      <div>Gitlab EE</div>
    </ButtonContainer>
  )
};

export const bitbucketButton: GitProviderRadioButtonOps = {
  value: 'BitBucket',
  content: (
    <ButtonContainer>
      <BitbucketIcon />
      <div>Bitbucket.org</div>
    </ButtonContainer>
  )
};

export const bitbucketServerButton: GitProviderRadioButtonOps = {
  value: 'BitBucketServer',
  isForSelfHostedOrg: true,
  content: (
    <ButtonContainer>
      <BitbucketIcon />
      <div>Bitbucket Server</div>
    </ButtonContainer>
  )
};

export const otherVcsButton: GitProviderRadioButtonOps = {
  value: 'Other',
  content: (
    <ButtonContainer>
      <BranchesOutlined />
      <FormattedMessage id="templates.add.new.integration.other.vcs" />
    </ButtonContainer>
  )
};

const mapProviderToButton: Record<GitProviderTypes, GitProviderRadioButtonOps> = {
  GitHub: githubButton,
  GitHubEnterprise: githubEnterpriseButton,
  GitLab: gitlabButton,
  AzureDevOps: azureDevOpsButton,
  HelmRepository: helmRepositoryButton,
  GitLabEnterprise: gitlabEnterpriseButton,
  BitBucket: bitbucketButton,
  BitBucketServer: bitbucketServerButton,
  Other: otherVcsButton
};

// Order matters! it also the order we will show it in the UI
export const getAllProviderButtons = (
  templateType: BlueprintApi.BlueprintType,
  excludedProviders: GitProviderTypes[]
): GitProviderRadioButtonOps[] => {
  const excludedButton = excludedProviders.map(provider => mapProviderToButton[provider]);
  return compact([
    githubButton,
    gitlabButton,
    bitbucketButton,
    azureDevOpsButton,
    templateType === 'helm' ? helmRepositoryButton : null,
    githubEnterpriseButton,
    gitlabEnterpriseButton,
    bitbucketServerButton,
    otherVcsButton
  ]).filter(button => !excludedButton.includes(button));
};
